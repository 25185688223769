import { ColorSchemes } from './themes/colorSchemes.enum';
import { Injectable } from '@angular/core';
import { environment as configs } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { UserService } from '@vanguard/shared/services/user.service';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { DeviceTypes } from '@vanguard/shared/enums/deviceTypes.enum';
import { TinyColor } from '@ctrl/tinycolor';
import { ColorShadesScale } from './themes/colorShadesScale';
import { ColorShades } from './themes/colorShades.enum';
import { ColorSchemesList } from './themes/colorSchemesList';
import { Meta } from '@angular/platform-browser';
import { BackendService } from './backend.service';
import { Router } from '@angular/router';
import { config } from 'process';
import { Apollo, gql } from 'apollo-angular';

@Injectable()
export class ThemeService {
  private static stylesMap: Map<string, string> = new Map();
  private static configMap: Map<string, string> = new Map();
  baseURL: string = configs.clientUrl;
  testURL: String = configs.testUrl;
  adminURL: string = configs.adminUrl;
  headingFontFamily: string = configs.headingFontFamily;
  contentFontFamily:string = configs.contentFontFamily;
  globalFontSizeContent:string = configs.globalFontSizeContent;
  public defaultEmbeddedLinkoptions = {
    hideBackground: false,
    hideFooter: false,
    hideHeader: false,
    innerPadding: false,
    innerPaddings: {
      left: '',
      right: ''
    },
  };
  public configList:any;
  isOmniEmbedChannel: boolean = false;
  constructor(private commonApiService: CommonhttpService,
    private _userService: UserService,
    public router: Router,
    private backendService: BackendService,
    private meta: Meta,
    private _apollo?: Apollo) { }


  public setStyleMap(argMap: Map<string, string>): void {
    ThemeService.stylesMap = argMap;
    this.setCSSVariables();
    this.applyConfigurataion();
  }//  public setStyleMap(argMap: Map<string, string>): void


  public setConfigMap(argMap: Map<string, string>): void {
    ThemeService.configMap = argMap;
  }// public setConfigMap(argMap: Map<string, string>): void

  public getConfigValueBykey(argKeyValue: string): string {
    return ThemeService.configMap.get(argKeyValue);
  }// public getConfigValueBykey(argKeyValue: string): string

   /**
  * @description Assign response from api to key value
  * @param null
  */
    applyConfigurataion() {
      this.configList = {
        "--Modren-heading":this.headingFontFamily,
        "--Modren-content":this.contentFontFamily,
        "--globalFontSizeContent":this.globalFontSizeContent
      };
      Object.keys(this.configList).forEach(property => {
        document.documentElement.style.setProperty(
          property,
          this.configList[property]
        );
      });
    }

  public setCSSVariables(): void {
    ThemeService.stylesMap.forEach((value, key) => {
      document.documentElement.style.setProperty(key, value);
    });
  }// public setCSSThemeService.stylesMap(): void

  public getStyleVariables(argCategory: string, argSubCategory: string, argPrefixKey: string): Observable<any> {
    return this.commonApiService.get(`${this.adminURL}/setup/configs/${argCategory}/${argSubCategory}?isenabled=true`)
      .pipe(map((themeResponse: any) => {
        this.setStyleVariables(themeResponse);
        return true;
      }, catchError(this.handleError))
      );
  }// public getuserInfoKeys(): Observable<any>

  public setStyleVariables(themeResponse: any): void {
    const styleMap = new Map();
    const textMap = new Map();
    const queryParams = new URLSearchParams(window.location.search);
    const selectedProductCode = queryParams.get('utm_medium');
    try {
      if (themeResponse.length > 0) {
        const activeTheme = themeResponse.filter(theme => {
          return (theme.isenabled && !theme.deleted && (theme?.productCode == selectedProductCode))
        });
        const responseObject = activeTheme[0];
        if (responseObject) {
          Object.keys(responseObject).forEach(key => {
            // Set color scheme
            styleMap.set(`--${key}`, responseObject[key]);

            // Generate color shade for each color scheme
            const colorScheme: ColorSchemes = key as ColorSchemes;
            if (ColorSchemesList.includes(colorScheme) && responseObject[key]) {
              this.generateColorShades(colorScheme, responseObject[key]);
            }

            // Set title bar color to primary color
            if (key === ColorSchemes.primaryColor) {
              this.meta.updateTag({ name: 'theme-color', content: responseObject[key] });
            }

          });
        }
        this.setConfigMap(textMap);
        this.setStyleMap(styleMap);
      }
    } catch (error) {
      throw error;
    }
  }// public setStyleVariables(): Observable<any>

  public generateColorShades(variable: ColorSchemes, color) {
    const colorPalate: Map<string, string> = new Map();

    const baseColor = new TinyColor(color);
    // Brightness value range from 0 to 255, 0-> darkest 255 ->lightest
    // which need to be mapped between a scale of 100 to 900 , 100-> lightest 900-> darkest
    const brightness = baseColor.getBrightness();
    const brightnessPercentage = (brightness / 255) * 100;

    // Total level between 100-900 is 800 levels
    const colorShadeLength = ColorShadesScale[ColorShadesScale.length - 1] - ColorShadesScale[0];
    const colorShade = ColorShadesScale[ColorShadesScale.length - 1] - ((colorShadeLength * brightnessPercentage) / 100);
    const parentColorShade: ColorShades = this.findClosestShade(ColorShadesScale, colorShade);

    for (const shade of ColorShadesScale) {
      let newColor;
      if (shade > parentColorShade) { // If shade is greater than color shade we have, darken the color
        const darkenPercentage = (((((shade - parentColorShade) / colorShadeLength) * 100) * 50) / 100);
        newColor = new TinyColor(color).darken(darkenPercentage).toHexString();
      } else if (shade < parentColorShade) { // If shade is less than color shade we have, lighten the color
        const lightenPercentage = (((((parentColorShade - shade) / colorShadeLength) * 100) * 50) / 100);
        newColor = new TinyColor(color).lighten(lightenPercentage).toHexString();
      } else { // For parent color shade
        newColor = color;
      }
      colorPalate.set(`--${variable}${shade}`, newColor);
    }
    this.setStyleMap(colorPalate);
  }

  getThemeConfig(type:any,typeId:any,version:any){
    const variables = {
      status: 'Published',
      version: version,
      themeId: typeId,
    }
    if(type == 'MODERN' || type == 'Modern'){
      return this._apollo.use('orgAPIClient')
      .query<any>({
        query: gql`
          query (
            $version: String
            $themeId: String
            $status: String
          ) {
            campaignThemesDetails(
              version: $version
              themeId: $themeId
              status: $status
            ) {
              data {
                version
                status
                createdBy
                themeId
                modifiedBy
                createdTS
                modifiedTS
                entityId
                name
                themeType
                modernBackground
                modernLayoutPadding
                modernHeaderLogo
                modernExitButtonUrl
                modernEnableFixedButton
                modernButtonSpaceFromRight
                modernButtonSpaceFromBottom
              }
              count
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
      }).toPromise();
    }else if(type == 'MATERIAL' || type == 'Material'){
      return this._apollo.use('orgAPIClient')
      .query<any>({
        query: gql`
          query (
            $version: String
            $themeId: String
            $status: String
          ) {
            campaignThemesDetails(
              version: $version
              themeId: $themeId
              status: $status
            ) {
              data {
                version
                status
                createdBy
                modifiedBy
                createdTS
                modifiedTS
                themeId
                entityId
                name
                themeType
                materialPrimaryFontSize
                materialSecondaryFontsize
                materialWidth
                materialMaxWidth
                materialHeaderColor
                materialLogoUrl
                materialFooterImageUrl
                materialBackgroundColor
                materialPrimaryColor
                materialSecondaryColor
                materialDefaultColor
                materialPrimaryActiveColor
                materialSecondaryActiveColor
                materialPrimaryDisabledColor
                materialSecondaryDisabledColor
                workspaceButtonActiveBackgroundColor
              }
              count
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
      }).toPromise();
    }else if(type == 'CLASSIC' || type == 'Classic'){
      return this._apollo.use('orgAPIClient')
      .query<any>({
        query: gql`
          query (
            $version: String
            $themeId: String
            $status: String
          ) {
            campaignThemesDetails(
              version: $version
              themeId: $themeId
              status: $status
            ) {
              data {
                version
                status
                themeId
                entityId
                name
                themeType
                classicSpaceBetween
                classicPrimaryfontSize
                classicWidth
                classicMaxWidth
                classicLogoUrl
                classicFooterUrl
                classicPrimaryErrorColor
                classicBodyBackgroundImageUrl
                classicPrimaryColor
                classicSecondaryErrorColor
                classicPrimaryBorderColor
                classicSecondaryBorderColor
                classicheaderbackground
                classicHeaderPrimaryColor
                classicPrimaryButtonColor
                classicWhite
                classicPrimaryGrey
                classicSecondaryGrey
                classicDefaultGrey
                classicpriceSignColor
                classicPriceSignFocus
                classicPriceSignError
                classicFormMaxWidth
              }
              count
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
      }).toPromise();
    }else if(type == 'GRID'|| type == 'Grid'){
      return this._apollo.use('orgAPIClient')
      .query<any>({
        query: gql`
          query (
            $version: String
            $themeId: String
            $status: String
          ) {
            campaignThemesDetails(
              version: $version
              themeId: $themeId
              status: $status
            ) {
              data {
                version
                status
                createdBy
                modifiedBy
                createdTS
                themeId
                modifiedTS
                entityId
                name
                themeType
                gridprimaryfontsize
                gridsecondaryfontsize
                gridWidth
                gridMaxWidth
                gridlogourl
                gridfooterurl
                gridbackgroundcolor
                gridheadercolor
                gridprimarycolor
                gridsecondarycolor
                gridprimaryactivecolor
                gridsecondaryactivecolor
                gridprimarydisabledcolor
                gridsecondarydisabledcolor
                griddefaultcolor
              }
              count
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
      }).toPromise();
    }
  }

  public getStyleVariablesByConfigId(argCategory: string, argSubCategory: string, argConfigId: string): Observable<any>{
    return this.commonApiService.get(`${this.adminURL}/setup/configs/${argCategory}/${argSubCategory}?configId=${argConfigId}`)
      .pipe(map((themeResponse: any) => {
        return themeResponse;
      }));
  }// public getStyleVariablesByConfigId(argCategory: string, argSubCategory: string, argConfigId: string): Observable<any>

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }// public handleError(error: HttpErrorResponse)

  public getDeviceType(): DeviceTypes {
    let deviceType = DeviceTypes.DESKTOP;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      deviceType = DeviceTypes.MOBILE;
    }
    return deviceType;
  }

  private findClosestShade(arr, target) {
    const arrSize = arr.length;

    // Corner cases
    if (target <= arr[0]) {
      return arr[0];
    }
    if (target >= arr[arrSize - 1]) {
      return arr[arrSize - 1];
    }

    // Doing binary search
    let i = 0; let j = arrSize; let mid = 0;
    while (i < j) {
      mid = Math.floor((i + j) / 2);

      if (arr[mid] === target) {
        return arr[mid];
      }
      /* If target is less than array element,
          then search in left */
      if (target < arr[mid]) {
        // If target is greater than previous
        // to mid, return closest of two
        if (mid > 0 && target > arr[mid - 1]) {
          return this.getClosest(arr[mid - 1],
            arr[mid], target);
        }

        /* Repeat for left half */
        j = mid;
      } else {
        // If target is greater than mid
        if (mid < arrSize - 1 && target < arr[mid + 1]) {
          return this.getClosest(arr[mid],
            arr[mid + 1], target);
        }

        // update i
        i = mid + 1;
      }
    }

    // Only single element left after search
    return arr[mid];
  }

  private getClosest(val1, val2, target) {
    if (target - val1 >= val2 - target) {
      return val2;
    } else {
      return val1;
    }
  }

  public getEmbeddedConfigDetails() {
    let DeviceSize = this.getDeviceType();
    let flowThemeDetails = this.backendService.personalDetailsObject.flowTheme;
    // don't know why this omni check has been enabled
    //if (this.isOmniEmbedChannel) {
      if (DeviceSize && DeviceSize == 'DESKTOP' && flowThemeDetails) {
        if (flowThemeDetails['web']['embeddedDisplayOptions']) {
          return flowThemeDetails['web']['embeddedDisplayOptions'];
        } else {
          return this.defaultEmbeddedLinkoptions;
        }
      } else if (DeviceSize && DeviceSize == 'MOBILE' && flowThemeDetails) {
        if (flowThemeDetails['mobile']['embeddedDisplayOptions']) {
          return flowThemeDetails['mobile']['embeddedDisplayOptions'];
        } else {
          return this.defaultEmbeddedLinkoptions;
        }
      }
    //} else {
    //   return this.defaultEmbeddedLinkoptions;
    // }
  }

  public updateOmniEmbedRouteFlag(flag) {
    this.isOmniEmbedChannel = flag;
  }
}
