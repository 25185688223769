import { CustomPersonalDetailsObject } from '@vanguard/custom/shared/interfaces/personalDetails.interface';

export class PersonalDetailsObject extends CustomPersonalDetailsObject {
    constructor(
        public product_type_code?: string,
        public singleORjoint?: string,
        public applicant?: string,
        public aadhar_image?: any,
        public campaign_id?: any,
        public source_id?: any,
        public application_id?: string,
        public product_code?: string,
        public product_name?: string,
        public application_status?: string,
        public jointEmailOrComp?: boolean,
        public routeConfig?: Array<any>,
        public section_id?: number,
        public sub_section_id?: number,
        public lastSection_id?: number,
        public section_size?: number,
        public startRoute?: string,
        public static_fields?: string[],
        public ver_num?: number,
        public mod_time?: Date,
        public flowTheme?: string,
        public verNum?: number,
        public flowName?: string,
        public flowId?: string,
        public appLifeCycle?: Object,
        public initiatedBy?: object,
        public custom_route?: string,
        public userDetails?: Object,
        public middle?: any,
        public left?: any,
        public filesUpload?: Array<any>,
        public customFields?: Object,
        public existing_or_new_customer?: boolean,
        public product?: any,
        public maximumJointApplicants?: any,
        public showJointApplicantSectionId?: any,
        public showJointApplicantSubSectionId?: any,
        public applicationType?: string,
        public enableMultipleCoApplicant?: any,
        public applicantHeaderName?: any,
        public enableForApplicantHeaderName?: any,
        public applicantHeaderLabelName?: any,
        public currentComponentConfig?: any,
        public isV2Flow?:any,
        // RequestForInfo
        public requestInfo?: string,

        // For e-commerce - external integration
        public _payloadReceivedTime?: string,
        public _payloadExpiryTime?: string,
        public _externalIntegrationCallbackURL?: string,
        public _timeLeftInSeconds?: string,
        public _isExternalIntegration?: boolean,

        // For creating coapplicants from new coapplicant-screen
        public primaryApplicantId?: string,

        public resumeUrlSettings?:any
    ) {
        super();
    }
}
