export const environment = {
  production: true,
  //_ep: false,
  //_er: true,
  //_ear: false,
  encryptPassword: false,
  encryptRequest: false,
  encryptAdminApiRequest: false,
  clientUrl : 'https://fqa1-user-portal.epikindifi.com/consumerAPI',
  adminUrl : 'https://fq1.epikindifi.com/adminAPI',
  domain : 'https://fqa1-user-portal.epikindifi.com',  // Domain on which the application will be deployed
  testUrl: 'http://fqa1-user-portal.epikindifi.com/consumerAPI',
  notificationUrl: 'https://fqa1-user-portal.epikindifi.com/',
  uamRedirectUrl: 'https://fqa1-user-portal.epikindifi.com',
  loginUrl: 'https://auth-fqav20.ezee.studio/auth/realms/ezee/protocol/openid-connect/auth?client_id=ezee-client&response_type=code&state=fj8o3n7bdy1op5&redirect_uri=https://fqa1-user-portal.epikindifi.com',
  decisionEngineUrl: 'https://fqa1-user-portal.epikindifi.com/decisionEngine',
  videoConferenceUrl: 'wss://identify.sandboxforbank.com/janus/',
  lookupUrl:'https://fq1-admin.epikindifi.com/lookupAPI',
  //_vcu: 'wss://identify.sandboxforbank.com/janus/',
  postVideoConferenceUrl: 'https://identify.sandboxforbank.com/api',
  videoRecordingPath: '/home/ubuntu/recording',
  googleApiKey: 'AIzaSyD9Kg4Cq7_Ml3rbhxEmSXIm19aMPQJArOE',
  //_gak: 'AIzaSyBzwS19hAwa6qsSWb0BtMhcmUuovg8m6_o',
  videoConferenceICEServers: [{urls: "turn:docker-turn.sandboxforbank.com:3478",username: 'testturn',credential: 'testturn'},{urls: "stun:stun.l.google.com:19302"}],
  customerUserPassword: "13232e0442360b3bca1033a3b3c5cc18:1291db1dc2832049f2330408a3a079fa",
  //_cup: "13232e0442360b3bca1033a3b3c5cc18:1291db1dc2832049f2330408a3a079fa"
   headingFontFamily:'Open Sans',
  contentFontFamily:'SourceSansPro-Regular',
  globalFontSizeContent:'16px', 
  immediatePreview: false,
  //vishwamSdkUrl: 'https://apis-jpb-stag.vishwamcorp.com',
  eid: "ezee",
  repaymentApiKey:
    "",
  repaymentUrl: "",
  mid: "",
  adminV2Url:'https://fq1.epikindifi.com/adminAPI',
  entityId:"clix",
  docHubAPIBaseUrl: "https://fq1-admin.epikindifi.com/docHubAPI",
  orgAPIBaseUrl: 'https://fqa1-organization-management.epikindifi.com/orgManageAPI',
  vishwam_app_id: 'jpb',
  finvuWebSocketUrl: "wss://webvwdev.finvu.in/consentapi",
  enableCompressedWidth: false,
  repaymentCheckoutUrl: '',
  vishwamSdkUrl: "https://apis-jpb-stag.vishwamcorp.com/v1"
};
