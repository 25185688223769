import { Injectable } from '@angular/core';
import { ROLES } from '@vanguard/config/roles';
import SimpleCrypto from "simple-crypto-js"
import { AppConstants } from '@vanguard/config/appConstants';
import * as forge from 'node-forge';

const simpleCrypto = new SimpleCrypto(AppConstants.SECRET_KEY.USER_DETAILS_SECRET_KEY);

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public _authenticated: boolean;
  // public userDetails: any;
  public refresh_token: any;
  public access_token: any;
  public pem_data: any;
  public session_id: any;
  public session_expiry_time: any;
  public isDeveloper: boolean;
  public isDebugger: boolean;
  public appRefId: any;
  public isOpenedFromIFrame: boolean = false;
  public features = {
    multiLingual: false
  };
  currentUser: any;
  private _applicationOrigination: string = 'CAMPAIGNURL';
  constructor() {
    //check if user loggedin and load current user details
    if (this.isLoggedIn()) {
      this.currentUser = this.userDetails;
    }
    this.refresh_token = '';
    this.access_token = '';
  }

  /**
   * returns login status.
   */
  public isLoggedIn(): boolean {
    console.log("isLoggedIn", this._authenticated);
    // if (this.accessToken && this.refreshToken && this.sessionId) {
    //   return true;
    // }
    // return false;   //Reverted persistant login
    return this._authenticated;
  }

  /**
   * set login status.
   */
  public setLoginStatus(value) {
    this._authenticated = value;
    console.log("setLoginStatus", value, this._authenticated);
  }

  /**
   * set token data to local storage
   * @param data
   */
  setTokenData(data: any) {
    this.accessToken = data.access_token;
    this.pemData = data["x-sk"];
    this.refreshToken = data.refresh_token;
    this.sessionId = data.sessionid;
    this.sessionExpiryTime = data.expiry_time;
     localStorage.setItem('_rc', data.refresh_token);
    // localStorage.setItem('_ac', data.access_token);
    // localStorage.setItem('_rc', data.refresh_token);
    // localStorage.setItem('_sc', data.sessionid);
    // localStorage.setItem('_set', data.expiry_time);   //Revert persistant login
  }

  /**
   * clear token data from local storage
   */
  clearTokenData() {
    this.accessToken = null;
    this.refreshToken = null;
    this.sessionId = null;
    this.sessionExpiryTime = null;
    localStorage.removeItem('_rc');
    this.pemData = null;
    // localStorage.removeItem('_ac');
    // localStorage.removeItem('_rc');
    // localStorage.removeItem('_sc');
    // localStorage.removeItem('_set'); revert persistant login
  }

  /**
   * accessToken setter
   */
  set accessToken(access_token) {
    this.access_token = access_token;
    localStorage.setItem('_ac', access_token);
  }

  /** 
   * pemData setter
   */
  set pemData(pem_data) {
    this.pem_data = pem_data;
    localStorage.setItem('_rk', pem_data);
    const secretKey = forge.util.bytesToHex(forge.random.getBytesSync(16)); // generating secret key while login
    pem_data ? localStorage.setItem('_sk',secretKey) : localStorage.removeItem('_sk');  //storing generated secret key
  }

  /**
   * refreshToken setter
   */
  set refreshToken(refresh_token) {
    this.refresh_token = refresh_token;
  }

  /**
   * sessionId setter
   */
  set sessionId(sessionid) {
    this.session_id = sessionid;
  }

  /**
     * sessionExpiryTime setter
     */
  set sessionExpiryTime(expiry_time) {
    this.session_expiry_time = expiry_time;
  }

  /**
   * accessToken getter
   */
  get accessToken() {
    try {
      return this.access_token;
      // return this.access_token || localStorage.getItem('_ac'); revert persistant login
    } catch (err) {
      console.log('error in get accessToken', err)
    }
  }

  /** 
   * pemData getter
   */
  get pemData() {
    try {
      return this.pem_data;
    } catch (err) {
      console.log('error in get accessToken', err)
    }
  }

  /**
   * refresh token getter
   */
  get refreshToken() {
    try {
      return this.refresh_token || localStorage.getItem('_rc');
    } catch (err) {
      console.log('error in get refreshToken', err)
    }
  }

  /**
   * session id getter
   */
  get sessionId() {
    try {
      return this.session_id;
      // return this.session_id || localStorage.getItem('_sc'); //revert persistant login
    } catch (err) {
      console.log('error in get sessionId', err)
    }
  }

  /**
   * session expiry time getter
   */
  get sessionExpiryTime() {
    try {
      // return this.session_expiry_time || parseInt(localStorage.getItem('_set')); //revert persistant login
      return this.session_expiry_time;
    } catch (err) {
      console.log('error in get sessionExpiryTime', err)
    }
  }

  /**
   * set user details to local storage
   * @param userDetails
   */
  setUserDetails(userDetails) {
    try {
      this.currentUser = userDetails;
      this.currentUser.displayName = this.getUserDisplayName();
      const { access_token, expires_in, expiry_time, refresh_expires_in,
        refresh_token, scope, session_state, sessionid,
        token_type, ...userDetailsObj } = this.currentUser;
      this.currentUser = userDetailsObj;
      localStorage.setItem('entityId', this.currentUser.attributes?.entityId?.[0]);
      // localStorage.setItem('_ud', this.encryptUserData(JSON.stringify(userDetailsObj)));
      this.isDeveloperUser();
    } catch (err) {
      console.log('error in setUserDetails', err);
    }
  }

  /**
   * user details getter
   */
  get userDetails() {
    try {
      // let userDetail: any = this.currentUser;
      // userDetail = this.decryptUserData(userDetail);
      // this.currentUser = userDetail;
      return this.currentUser;
    } catch (err) {
      console.log('error in get userDetails', err);
    }
  }

  /**
   * encrypt user details
   * @param data
   * @returns encrypted string
   */
  encryptUserData(data) {
    try {
      return simpleCrypto.encrypt(data);
    } catch (err) {
      console.log('error in encryptUserData', err);
    }
  }

  /**
   * decrypt user details
   * @param data
   * @returns decrypted data
   */
  decryptUserData(data) {
    try {
      return simpleCrypto.decrypt(data, true);
    } catch (err) {
      console.log('error in decryptUserData', err);
    }
  }

  /**
   * userId getter
   */
  get userId() {
    try {
      return this.currentUser.userId;
    } catch (err) {
      console.log('error in get userId', err);
    }
  }

  /**
   * userName getter
   */
  get userName() {
    try {
      return this.currentUser.userName;
    } catch (err) {
      console.log('error in get userName', err);
    }
  }

  /**
   * user attributes getter
   */
  get userAttributes() {
    try {
      return this.currentUser.attributes;
    } catch (err) {
      console.log('error in get userAttributes', err);
    }
  }

  /**
   * user roles getter
   */
  get userRoles() {
    try {
      return this.currentUser.roles;
    } catch (err) {
      console.log('error in get userRoles', err);
    }
  }

  /**
   * user groups getter
   */
  get userGroups() {
    try {
      return this.currentUser.groups;
    } catch (err) {
      console.log('error in get userGroups', err);
    }
  }

  /**
   * user display name getter
   */
  get displayName() {
    try {
      return this.currentUser.displayName;
    } catch (err) {
      console.log('error in get displayName', err);
    }
  }

  /**
   * user teams data getter
   */
  get teamData() {
    try {
      return this.currentUser.teamData;
    } catch (err) {
      console.log('error in get teamData', err);
    }
  }

  /**
   * user type getter
   */
  get userType() {
    return this.currentUser.userType;
  }

  /**
   * check if user has developer permission
   */
  private isDeveloperUser() {
    if (this.currentUser && this.currentUser.roles && this.currentUser.roles.includes(ROLES.DEVELOPER)) {
      this.isDeveloper = true;
      this.isDebugger = false;
    } else if (this.currentUser && this.currentUser.roles && this.currentUser.roles.includes(ROLES.DEBUGGER)) {
      this.isDebugger = true;
      this.isDeveloper = false;
    } else {
      this.isDebugger = false;
      this.isDeveloper = false;
    }
  }

  /**
   * clear user data from local storage
   */
  clearUserData() {
    this.currentUser = undefined;
    // localStorage.removeItem('_ud'); revert persistant login
  }

  /**
   * get user display name
   * @returns get user display name
   */
  public getUserDisplayName(): string {
    if (this.currentUser) {
      if (this.currentUser.displayName) {
        return `${this.currentUser.displayName}`;
      } else if (this.currentUser.firstName && this.currentUser.lastName) {
        return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
      } else if (this.currentUser.firstName) {
        return this.currentUser.firstName;
      } else if (this.currentUser.lastName) {
        return `${this.currentUser.lastName}`;
      } else {
        return this.currentUser.userName;
      }
    } else {
      return "User";
    }
  }

  /**
   *
   * @returns application origination value
   */
  getApplicationOrigination(){
    return this._applicationOrigination;
  }

  /**
   * @description set application origination value
   * @param type
   */
  setApplicationOrigination(type){
    this._applicationOrigination = type;
  }
}
