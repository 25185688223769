<div class="component-renderer">
    <div class="component-renderer__form vg-grid" #componentForm>
      <div class="vg-grid__rows" style="height: unset;" #componentFormContent>
        <div class="vg-grid__row" *ngFor="let rowField of backendService.componentDetailsForBrowserRestriction.fields" [ngStyle]="{
              'background-color': rowField.config?.backgroundColorEnabled ? rowField.config?.background : '',
              'background-image': !rowField.config?.backgroundColorEnabled ? rowField.config?.background : '',
              'margin-top': rowField.config?.margin?.top,
              'margin-right': rowField.config?.margin?.right,
              'margin-bottom': rowField.config?.margin?.bottom,
              'margin-left': rowField.config?.margin?.left,
              'box-shadow':rowField.config?.inset? ('inset'+ ' ' + (!rowField.config?.horizontalOffset ? '0':rowField.config?.horizontalOffset)+' '+ (!rowField.config?.verticalOffset? '0':rowField.config?.verticalOffset)+' '+ (!rowField.config?.blur? '0':rowField.config?.blur)+' '+ (!rowField.config?.spread? '0':rowField.config?.spread)+' '+ rowField.config?.color):((!rowField.config?.horizontalOffset? '0':rowField.config?.horizontalOffset)+' '+ (!rowField.config?.verticalOffset? '0':rowField.config?.verticalOffset)+' '+ (!rowField.config?.blur? '0':rowField.config?.blur)+' '+ (!rowField.config?.spread? '0':rowField.config?.spread) +' '+ rowField.config?.color),
              'padding-top': rowField.config?.padding?.top,
              'padding-right': rowField.config?.padding?.right,
              'padding-bottom': rowField.config?.padding?.bottom,
              'padding-left': rowField.config?.padding?.left
            }" [ngClass]="rowField.config?.height">
          <div class="vg-grid__col" *ngFor="let columnFields of rowField.fields" [ngStyle]="{
                'background-color': columnFields.config?.backgroundColorEnabled ? columnFields.config?.background : '',
                'background-image': !columnFields.config?.backgroundColorEnabled ? columnFields.config?.background : '',
                'margin-top': columnFields.config?.margin?.top,
                'margin-right': columnFields.config?.margin?.right,
                'margin-bottom': columnFields.config?.margin?.bottom,
                'margin-left': columnFields.config?.margin?.left,
                'padding-top': columnFields.config?.padding?.top,
                'padding-right': columnFields.config?.padding?.right,
                'padding-bottom': columnFields.config?.padding?.bottom,
                'padding-left': columnFields.config?.padding?.left,
                'box-shadow':columnFields.config?.inset?('inset' +' ' +(!columnFields.config?.horizontalOffset? '0px':columnFields.config?.horizontalOffset)+' '+(!columnFields.config?.verticalOffset? '0px':columnFields.config?.verticalOffset)+' '+ (!columnFields.config?.blur? '0px':columnFields.config?.blur)+' '+ (!columnFields.config?.spread? '0px':columnFields.config?.spread)+' '+ columnFields.config?.color): ((!columnFields.config?.horizontalOffset? '0px':columnFields.config?.horizontalOffset)+' '+(!columnFields.config?.verticalOffset? '0px':columnFields.config?.verticalOffset)+' '+  (!columnFields.config?.blur? '0px':columnFields.config?.blur)+' '+ (!columnFields.config?.spread? '0px':columnFields.config?.spread)+' '+ columnFields.config?.color)
              }" [ngClass]="columnFields.config?.width">
            <div class="vg-grid__content"
              [ngClass]="[columnFields.config?.alignment?.horizontal || '' , columnFields.config?.alignment?.vertical || '']">
              <ng-container *ngFor="let field of columnFields.fields; let i = index;">
                <ng-container>
                  <div>
                    <ui-elements [field]="field" [form]="form" [fieldService]="fieldSetupService" [jointModel]="backendService?.jointModel" [currentApplicant]="backendService?.currentApplicant"
                      ></ui-elements>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  