import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RuleEngineApi } from '@vanguard/core/modules/rule-engine/rule-engine.api';
import { BackstageServiceComponent, CommunicationService, FieldSetupService, FormFieldSetupService } from '@vanguard/shared/services';
import { BackendService } from '@vanguard/shared/services/backend.service';


@Component({
  selector: 'app-browser-restriction',
  templateUrl: './browser-restriction.component.html',
  styleUrls: ['./browser-restriction.component.scss']
})
export class BrowserRestrictionComponent implements OnInit {
  @ViewChild('componentForm') private componentForm: ElementRef;
    @ViewChild('componentFormDisableMask') private componentFormDisableMask: ElementRef;
    @ViewChild('componentFormContent') private componentFormContent: ElementRef;
  public form: UntypedFormGroup;
  private ruleEngine: any;

    private derivedFields: any;

    private fieldLookup: any;

    private fieldSetupService: any;

    public rowFields = [];

    public componentFields;
  constructor(public backendService:BackendService,public _fb: UntypedFormBuilder,
    public backstageService:BackstageServiceComponent){
    
  }

  ngOnInit() {
    this.backstageService.loading = false;
    // Initialize the component
    this.intializeComponent();
}

intializeComponent() {
  // Create field service
  this.fieldSetupService = new FieldSetupService(this._fb);
  // Set component id in field service
  this.fieldSetupService.componentId = '';
  // Create form
  this.form = this._fb.group({});
  // Scroll to top of page
  setTimeout(() => {
      if (this.componentForm) {
          const componentForm: HTMLElement = this.componentForm.nativeElement as HTMLElement;
          componentForm.scrollTo(0, 0);
      }
  }, 50);
}

}
