export class AppConstants {
  static JOINT = 'Joint';
  static SINGLE = 'Single';
  static APP_NAME = 'USER_PORTAL';
  static PRIMARY_APPLICANT = 'primary';
  static SECONDARY_APPLICANT = 'secondary';
  static GUARANTOR = 'guarantor';
  static MODE = 'Backstage';
  static GUARANTOR_APPLICANT = "guarantor";
  static PAGE_BREAK = 'page_break';
  static USER_STEP_NAVIGATION = 'USER_STEP_NAVIGATION';
  static USER_STEP_SAVE = 'USER_STEP_SAVE';
  static ROLES = {
    "CPA": "CPA",
    "BCM": "BCM",
    "BH": "BH"
  };
  static APP_DETAILS_OTHER_TABS = [
    {
      type: 'taskreviewdate',
      class: 'icon-snooze',
      title: 'Snooze',
      screenSize: "ms",
      role: "TASK_REVIEW_DATE",
      style: "",
      subStatusList: ["QUERIES", "APPROVAL"]
    },
    {
      type: 'worklogs',
      class: 'icon-Worklog',
      title: 'Worklogs',
      screenSize: "small",
      role: "",
      style: ""
    },
    {
      type: 'queries',
      class: 'icon-COMMENTS',
      title: 'Queries',
      screenSize: "ms",
      role: "",
      style: "",
      subStatusList: ["QUERIES"]
    },
    {
      type: 'documents',
      class: 'icon-Documents',
      title: 'Docs',
      screenSize: "large",
      role: "",
      style: ""
    },
    {
      type: 'notes',
      class: 'icon-note',
      title: 'Notes',
      screenSize: "small",
      role: "",
      style: ""
    },
    {
      type: 'approvals',
      class: 'icon-checklist1',
      title: 'Approvals',
      screenSize: "small",
      role: "",
      style: "",
      subStatusList: ["APPROVAL"]
    },
    {
      type: 'releated_app',
      class: 'icon-Related-app-1',
      title: 'Tasks',
      screenSize: "medium",
      role: "",
      style: "",
    },
    {
      type: 'save',
      class: 'icon-savetick',
      title: 'Save',
      screenSize: "small",
      role: "",
      style: "save-action",
      hideOnEditDisabled: true,
      hideOnUnassignedApplication: true
    },
    {
      type: 'action',
      class: 'icon-appfillaction',
      title: 'Actions',
      screenSize: "xs",
      role: "",
      style: "save-action",
      hideOnApplicationAccess: true,
      hideOnUnassignedApplication: true
    },
    {
      type: 'close',
      class: 'icon-Close',
      title: 'Close',
      screenSize: "xs",
      role: "",
      style: 'close-icon',
      subStatusList: ["QUERIES", "APPROVAL"]
    }];

  static FILTER_TABS_BY_SUBSTATUS = ["QUERIES", "APPROVAL"];

  static RESTRICT_SECTION_ACCESS_BY_SUBSTATUS = ["QUERIES"];

  static readonly REJECTED = 'Rejected';
  static readonly CANCEL = 'CANCEL';
  static readonly COMPLETE_WITH_VALIDATIONS = 'COMPLETE_WITH_VALIDATIONS';
  static readonly RAISE_EXCEPTIONS = 'RAISE_EXCEPTIONS';
  static readonly COMPLETE = 'COMPLETE';
  static readonly CUSTOMER_FLOW = 'CUST_FLOW';
  static readonly USER_FLOW = 'USER';
  static readonly FORMULA_NA = 'FORMULA_NA';
  static readonly FIELDS = 'FIELDS';
  static readonly FILTERS = 'FILTERS';
  static readonly AMOUNT_FIELD_OPERATORS = ["EQUAL", "NOT_EQUAL", "GREATER_THAN", "LESS_THAN", "GREATER_THAN_INCLUSIVE", "LESS_THAN_INCLUSIVE"];
  static readonly TEXT_FIELD_OPERATORS = ["EQUAL", "NOT_EQUAL", "CONTAINS", "ENDS_WITH"];
  static readonly APPLICATION_SUMMARY = 'APPLICATION_SUMMARY';
  static readonly DATE_FIELD_OPERATORS = ["EQUAL", "NOT_EQUAL", "GREATER_THAN", "LESS_THAN"];
  static readonly DROPDOWN_FIELD_OPERATORS = ["EQUAL", "NOT_EQUAL"];
  static readonly ADD_CO_APPLICANT = 'ADD_CO_APPLICANT';
  static readonly REMOVE_CO_APPLICANT = 'REMOVE_CO_APPLICANT';
  static readonly ACTION = 'Action';
  static readonly ACTIONS = 'Actions';
  static readonly UNASSIGNED = 'Unassigned';
  static readonly TASK_VALIDATION_ERRORS = {
    "EXCEPTIONS_RAISED_PENDING_FOR_APPROVAL": "EXCEPTIONS_RAISED_PENDING_FOR_APPROVAL",
    "APPROVALS_PENDING": "APPROVALS_PENDING",
    "QUERIES_PENDING": "QUERIES_PENDING"
  };
  static readonly APPLICATION_STATUS_MAP = {
    ACTIVE: 'ACTIVE',
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
    DELETED: 'DELETED',
    COMPLETED: 'COMPLETED',
    INACTIVE: 'INACTIVE'
  };
  static readonly APPLICATION_STATUS_FLAG = '_applicationStatus';

  static readonly GLOBAL_SEARCH = {
    DEFAULT_QUERY_FIELDS: ["application_id", "_applicationStatus", "initiatedBy", "cre_time"],
    DEFAULT_PAGE_SIZE: 10
  };

  static readonly LOADER_TEMPLATE = `<img src="./assets/images/loginloader.gif" alt="login">`;

  static readonly MY_APPLICATION_QUERY_TYPE = {
    FOLLOWED: 'Followed',
    ASSINGED: 'Assigned',
    QUEUED: 'Queued'
  };

  static readonly APPLICATION_PAGE_TYPE = {
    TEAM_APPLICATIONS: 'TEAM_APPLICATIONS',
    TEAM_LEADS: 'TEAM_LEADS',
    MY_APPLICATIONS: 'MY_APPLICATIONS',
    MY_LEADS: 'MY_LEADS',
    MY_QUERIES: 'MY_QUERIES',
    MY_APPROVALS: 'MY_APPROVALS',
    ALL_APPLICATIONS: 'ALL_APPLICATIONS',
    REJECTED_APPLICATIONS: 'REJECTED_APPLICATIONS',
    CANCELLED_APPLICATIONS: 'CANCELLED_APPLICATIONS'
  };

  static readonly APPLICATION_STAGE = {
    COMPLETED: 'Closed'
  };

  static readonly TEAM_TYPES = {
    HIERARCHY: "_HIERARCHY",
    DEPT: "_DEPT"
  };

  static readonly APPLICATION_TASK = {
    TASK_ID_FLAG: 'taskId',
    TASK_NAME_FLAG: 'name',
    STAGE_NAME_FLAG: 'stepName',
    ASSIGNED_TO_FLAG: 'assignedTo',
    ASSIGNED_TEAM_FLAG: 'assignedTeam',
    START_TIME: 'start_time',
    END_TIME: 'end_time',
    DUE_TIME: 'due_time',
    ACTIVE_ON: 'activeOn',
    PROGRESS_FLAG: 'progress',
    TASK_PROGRESS: {
      IN_PROGRESS: 'In Progress',
      PENDING: 'Pending',
      COMPLETED: 'Completed',
      REJECTED: 'Rejected',
      CANCELLED: 'Cancelled'
    }
  };

  static readonly TASK_REVIEWDATE_BUCKETS = {
    TODAY: 'Today',
    FUTURE: 'Future',
    ALL: 'All'
  };

  static readonly APPLICATION_ID_FIELD_KEY = 'application_id';
  static readonly APPLICATION_INITIATEDBY_FIELD_KEY = 'initiatedBy';

  static readonly APPLICATION_TASK_ACTIONS = {
    NONE: 'None',
    ASSIGN_TO_SELF: 'AssignToSelf',
    DROP: 'Drop',
    REBOOK: 'Rebook',
    RE_APPRAISE: 'Reappraise',
    LEAVE: 'Leave',
    OPEN_TASK: 'OpenTask',
    REVERT_STAGE: 'RevertStage',
    RE_ASSIGN: 'Reassign',
    REJECT: 'Reject',
    CANCEL: 'Cancel',
    COMPLETE_CALLBACK: 'CompleteCallback',
    ACTIVATE: 'Activate',
    COPY: 'Copy',
    CANCEL_REJECT: 'CancelReject',
    ASSIGN: 'Assign'
  };

  static readonly PAGINATION_CONSTANTS = {
    DEFAULT_PAGE_SIZE: 10,
    PAGE_SIZE_OPTIONS: [5, 10, 25, 50, 100],
    PAGE_SIZE_OPTIONS_GRID_VIEW: [6, 9, 27, 51, 99]
  };

  static readonly MANAGE_DOCUMENT_ROLE = "MANAGE_DOCUMENT";

  static readonly RANGE_CONSTANTS = {
    MIN_VALUE: 0,
    MAX_VALUE: 100
  }

  static readonly APPLICATION_TASK_NAME_FLAG = {
    APPROVAL: "APPROVAL",
    QUERIES: "QUERIES"
  };
  static readonly OTP_ACTIONS = {
    SEND_OTP: "SEND_OTP",
    RESEND_OTP: "RESEND_OTP",
    VERIFY_OTP: "VERIFY_OTP"
  };

  static readonly APPLICATION_ACTIONS = {
    role: 'APPLICATIONS_ACTIONS',
    TEAM_APPLICATIONS: [{
        eventType: "ASSIGN",
        action: AppConstants.APPLICATION_TASK_ACTIONS.ASSIGN,
        role: 'TEAM_APPLICATIONS_ASSIGN'
      }, {
        eventType: "RE_ASSIGN",
        action: AppConstants.APPLICATION_TASK_ACTIONS.RE_ASSIGN,
        role: 'TEAM_APPLICATIONS_REASSIGN'
      }, {
        eventType: "ASSIGN_TO_SELF",
        action: AppConstants.APPLICATION_TASK_ACTIONS.ASSIGN_TO_SELF,
        role: 'TEAM_APPLICATIONS_ASSIGN_SELF'
      }],
    ALL_APPLICATIONS: [
    {
      eventType: "ASSIGN",
      action: AppConstants.APPLICATION_TASK_ACTIONS.ASSIGN,
      role: 'ALL_APPLICATIONS_ASSIGN'
    }, {
      eventType: "RE_ASSIGN",
      action: AppConstants.APPLICATION_TASK_ACTIONS.RE_ASSIGN,
      role: 'ALL_APPLICATIONS_REASSIGN'
    }, {
      eventType: "ASSIGN_TO_SELF",
      action: AppConstants.APPLICATION_TASK_ACTIONS.ASSIGN_TO_SELF,
      role: 'ALL_APPLICATIONS_ASSIGN_SELF'
    }],
    TEAM_LEADS: [{
      eventType: "ASSIGN",
      action: AppConstants.APPLICATION_TASK_ACTIONS.ASSIGN,
      role: 'TEAM_APPLICATIONS_ASSIGN'
    }, {
      eventType: "RE_ASSIGN",
      action: AppConstants.APPLICATION_TASK_ACTIONS.RE_ASSIGN,
      role: 'TEAM_APPLICATIONS_REASSIGN'
    }, {
      eventType: "ASSIGN_TO_SELF",
      action: AppConstants.APPLICATION_TASK_ACTIONS.ASSIGN_TO_SELF,
      role: 'TEAM_APPLICATIONS_ASSIGN_SELF'
    }]
  };
  static readonly notificationStatus = ["AssignedToApprovalUser","AssignedToApprovalTeam", "AssignedQueriesToUser", "AssignedQueriesToTeam", "AssignedToUser", "AssignedToTeam", "TeamApplication"];
  static readonly SECRET_KEY = {
    DYNAMIC_URL_SECRET_KEY: "d59ab890c0eba1dcdb915c95ba1716ac",
    USER_DETAILS_SECRET_KEY:"0859915e391472af7c0f36ae43c6a393"
  };
  static readonly MAPSAPIKEY = {
    MAPS_API_KEY: ""
  };

  static readonly LANDING_PAGE_DEFAULT_VALUES = {
    LANDING_ASIDE_IMAGE: 'assets/images/landing/banner.svg',
    LANDING_BACKGROUND_COLOR: '#f5f6f6',
    LANDING_BACKGROUND_IMAGE: 'url("/assets/images/landing/bg.png")',
    LANDING_LOGIN_TEXT: 'Click here to login',
    LANDING_LOGO: 'assets/images/landing/logo.svg',
    LANDING_REGISTER_TEXT: 'New customer? Register',
    LANDING_SUBTITLE: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    LANDING_TITLE: 'Lorem ipsum dolor sit amet',
  };

  static readonly DECRYPT_EXCLUDE_ROUTES = [
    'assets/*',
    "/v3/auth/login",
    "/v3/auth/logout",
    "/v3/auth/refresh-token",
    "/v3/auth/forget-password",
    "/setup/configs/Admin/Features",
    "/v4/documents/upload/*",
    "/v4/documents/download/*",
    '/setup/configs/GLOBAL*', // GLOBAL/COLOR%20SCHEMES
    '/setup/configs/User*', // User%20Experience/Static%20Text
    '/setup/configs/Analytics*', // Analytics/Google%20Places
    '/setup/configs/Chat*', // Chat%20Bot/Chat%20Bot
    'v3/component/componentConfigs', // Added for temporary
    '/setup/configs/Environment/Domain',
    // '/v3/template/templateListAllVersion' // Requires api refactoring
    '/v1/repayment/getLoanAccount/*',
    '/v1/repayment/jiopay/intent'
  ];

  static DEFAULT_MICROFLOW_ERROR = 'Connector returned Error';

  static readonly API_STITCHER = {
    SUCCESS_MESSAGE: 'Yeh! we have got your eligibility',
    ERROR_MESSAGE: 'We are experiencing issues, please try after some time',
    POLLING_MESSAGE: 'Fetching Polling Microflow'
  }

  static readonly ACTION_BUTTON = [
    {
      type: "back",
      value: "GO BACK",
      hide: false,
      disable: false
    },
    {
      type: "next",
      value: "CONTINUE",
      hide: false,
      disable: false
    },
    {
      type: "skip",
      value: "SKIP",
      hide: true,
      disable: false
    }
  ]

  static readonly HEADERS_EXCLUDE_ROUTES = [
    "https://apis-demo-stag.jukshio.com/v1",
    "https://apis-jpb-stag.vishwamcorp.com",
    "https://apis-rrfl-prod.vishwamcorp.com",
    "https://www.rocksetta.com"
  ];
}
