<div [ngStyle]="{
  'margin-top': field.field_type_details?.displayConfigs?.margin?.top,
  'margin-right': field.field_type_details?.displayConfigs?.margin?.right,
  'margin-bottom': field.field_type_details?.displayConfigs?.margin?.bottom,
  'margin-left': field.field_type_details?.displayConfigs?.margin?.left,
  'padding-top': field.field_type_details?.displayConfigs?.padding?.top,
  'padding-right': field.field_type_details?.displayConfigs?.padding?.right,
  'padding-bottom': field.field_type_details?.displayConfigs?.padding?.bottom,
  'padding-left': field.field_type_details?.displayConfigs?.padding?.left,
  'display': field.field_type_details?.displayConfigs?.align ? 'flex' : '',
  'justify-content': field.field_type_details?.displayConfigs?.align == 'center' ? 'center' : field.field_type_details?.displayConfigs?.align == 'right' ? 'flex-end' : 'flex-start'
}">
  <emphasise-field [theme]="theme" [mode]="mode" [model]="model" [jointModel]="jointModel" [field]="field" [form]="form"
    [fieldService]="fieldService" [configs]="configs">
    <field-verification [model]="model" [field]="field">
      <div [formGroup]="form" [ngClass]="{'starling-theme': elementThemes.STARLING === theme && (!mode || mode === modeTypes.DARK),
                                                'classic-theme': elementThemes.CLASSIC === theme}">
        <ng-container [ngSwitch]="field.field_type_details.type">
          <ng-container *ngSwitchCase="'heading_1'">
            <div class="heading-1" [ngStyle]="{
                      'font-size':field.field_type_details?.displayConfigs?.fontSize,
                      'font-weight':field.field_type_details?.displayConfigs?.fontWeight,
                      'color':field.field_type_details?.displayConfigs?.color,
                      'text-align':field.field_type_details?.displayConfigs?.textAlign,
                      'margin-top': field.field_type_details?.displayConfigs?.margin?.top,
                      'margin-right': field.field_type_details?.displayConfigs?.margin?.right,
                      'margin-bottom': field.field_type_details?.displayConfigs?.margin?.bottom,
                      'margin-left': field.field_type_details?.displayConfigs?.margin?.left,
                      'padding-top': field.field_type_details?.displayConfigs?.padding?.top,
                      'padding-right': field.field_type_details?.displayConfigs?.padding?.right,
                      'padding-bottom': field.field_type_details?.displayConfigs?.padding?.bottom,
                      'padding-left': field.field_type_details?.displayConfigs?.padding?.left
                    }" [ngClass]="{ 'heading-1--light': mode === modeTypes.LIGHT && !field.field_type_details?.displayConfigs?.color,
                    'heading-1--dark': theme == 'STARLING' && (!mode || mode === modeTypes.DARK)}">
              <dynamic-text [form]="form" [model]="model" [labelValue]="field.value" [heading_1_element]="true"></dynamic-text>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'heading_2'">
            <div class="heading-2" [ngStyle]="{
                      'font-size':field.field_type_details?.displayConfigs?.fontSize,
                      'font-weight':field.field_type_details?.displayConfigs?.fontWeight,
                      'color':field.field_type_details?.displayConfigs?.color,
                      'text-align':field.field_type_details?.displayConfigs?.textAlign,
                      'margin-top': field.field_type_details?.displayConfigs?.margin?.top,
                      'margin-right': field.field_type_details?.displayConfigs?.margin?.right,
                      'margin-bottom': field.field_type_details?.displayConfigs?.margin?.bottom,
                      'margin-left': field.field_type_details?.displayConfigs?.margin?.left,
                      'padding-top': field.field_type_details?.displayConfigs?.padding?.top,
                      'padding-right': field.field_type_details?.displayConfigs?.padding?.right,
                      'padding-bottom': field.field_type_details?.displayConfigs?.padding?.bottom,
                      'padding-left': field.field_type_details?.displayConfigs?.padding?.left
                    }" [ngClass]="{ 'heading-2--light': mode === modeTypes.LIGHT && !field.field_type_details?.displayConfigs?.color,
                    'heading-2--dark': theme == 'STARLING' && (!mode || mode === modeTypes.DARK) }">
              <dynamic-text [form]="form" [model]="model" [labelValue]="field.value"></dynamic-text>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'heading_3'">
            <div class="heading-3" [ngStyle]="{
                      'font-size':field.field_type_details?.displayConfigs?.fontSize,
                      'font-weight':field.field_type_details?.displayConfigs?.fontWeight,
                      'color':field.field_type_details?.displayConfigs?.color,
                      'text-align':field.field_type_details?.displayConfigs?.textAlign,
                      'margin-top': field.field_type_details?.displayConfigs?.margin?.top,
                      'margin-right': field.field_type_details?.displayConfigs?.margin?.right,
                      'margin-bottom': field.field_type_details?.displayConfigs?.margin?.bottom,
                      'margin-left': field.field_type_details?.displayConfigs?.margin?.left,
                      'padding-top': field.field_type_details?.displayConfigs?.padding?.top,
                      'padding-right': field.field_type_details?.displayConfigs?.padding?.right,
                      'padding-bottom': field.field_type_details?.displayConfigs?.padding?.bottom,
                      'padding-left': field.field_type_details?.displayConfigs?.padding?.left
                    }" [ngClass]="{ 'heading-3--light': mode === modeTypes.LIGHT && !field.field_type_details?.displayConfigs?.color,
                    'heading-3--dark': !mode || mode === modeTypes.DARK}">
                      <dynamic-text [form]="form" [model]="model" [labelValue]="field.value"></dynamic-text>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'headingstyle'">
                    <app-ui-headingstyle [field]="field" [modeRead]="modeRead" [form]="form" [model]="model">
                    </app-ui-headingstyle>
                  </ng-container>
                  <ng-container *ngSwitchCase="['text', 'amount'].includes(field.field_type_details.type) ? field.field_type_details.type : ''">
                    <ui-text [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-text>
                  </ng-container>
                  <ng-container *ngSwitchCase="'checkbox'">
                    <ui-checkbox [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-checkbox>
                  </ng-container>
                  <ng-container *ngSwitchCase="'date'">
                    <ui-datepicker [theme]="theme" [mode]="mode" [field]="field" [form]="form" [model]="model" [fieldService]="fieldService">
                    </ui-datepicker>
                  </ng-container>
                  <ng-container *ngSwitchCase="'dropdown'">
                    <ui-dropdown [theme]="theme" [mode]="mode" [form]="form" [model]="model" [jointModel]="jointModel" [currentApplicant]="currentApplicant" [field]="field" [fieldService]="fieldService" [enableChildLinking]="enableChildLinking">
                    </ui-dropdown>
                  </ng-container>
                  <ng-container *ngSwitchCase="'range'">
                    <ui-range [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService"></ui-range>
                  </ng-container>
                  <ng-container *ngSwitchCase="'radio'">
                    <ui-radio [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService" [hidden]="ruleEngineService.isFieldSectionHidden(field.key)">
                    </ui-radio>
                  </ng-container>
                  <ng-container *ngSwitchCase="'textarea'">
                    <ui-textarea [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService" [isFieldSectionHidden]="ruleEngineService?.isFieldSectionHidden(field.key)">
                    </ui-textarea>
                  </ng-container>
                  <ng-container *ngSwitchCase="'multi_purpose_card'">
                    <ui-card [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService"></ui-card>
                  </ng-container>
                  <ng-container *ngSwitchCase="'review_edit'">
                    <ui-review-edit [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService" (click)="handleClick($event)"></ui-review-edit>
                  </ng-container>
                  <ng-container *ngSwitchCase="'amount_card'">
                    <ui-amount-card [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService"></ui-amount-card>
                  </ng-container>
                  <ng-container *ngSwitchCase="'_prospectDedupe'">
                    <app-ui-customer-dedupe [form]="form" [isCustomer]="false" [field]="field" [form]="form" [model]="model">
                    </app-ui-customer-dedupe>
                  </ng-container>
                  <ng-container *ngSwitchCase="'_customerDedupe'">
                    <app-ui-customer-dedupe [form]="form" [isCustomer]="true" [field]="field"  [model]="model">
                    </app-ui-customer-dedupe>
                  </ng-container>
                  <ng-container *ngSwitchCase="'fileUploadHub'">
                    <ui-fileupload-hub [theme]="theme" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService">
                    </ui-fileupload-hub>
                  </ng-container>
                  <ng-container *ngSwitchCase="'userid'">
                    <app-ui-userid [theme]="theme" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService">
                  </app-ui-userid>
                  </ng-container>
                  <ng-container *ngSwitchCase="'unassistedLiveliness'">
                    <app-ui-unassisted-liveliness-check [theme]="theme" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService">
                  </app-ui-unassisted-liveliness-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'slider'">
                    <ui-slider [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService" [hidden]="ruleEngineService.isFieldSectionHidden(field.key)">
                    </ui-slider>
                  </ng-container>
                  <ng-container *ngSwitchCase="'toggle'">
                    <ui-toggle [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService"></ui-toggle>
                  </ng-container>
                  <ng-container *ngSwitchCase="'link'">
                    <ui-link [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-link>
                  </ng-container>
                  <ng-container *ngSwitchCase="'unary'">
                    <ui-unary [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-unary>
                  </ng-container>
                  <ng-container *ngSwitchCase="'password'">
                    <ui-password [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-password>
                  </ng-container>
                  <ng-container *ngSwitchCase="'ui_modal'">
                    <ui-common-modal [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-common-modal>
                  </ng-container>
                  <ng-container *ngSwitchCase="'button'">
                    <ui-button [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService"></ui-button>
                  </ng-container>
                  <ng-container *ngSwitchCase="'pdfEditor'">
                    <app-pdfeditor-document [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </app-pdfeditor-document>
                  </ng-container>
                  <ng-container *ngSwitchCase="'signaturePad'">
                    <ui-signature-pad-v4 [form]="form" [model]="model" [field]="field">
                    </ui-signature-pad-v4>
                  </ng-container>
                  <ng-container *ngSwitchCase="'documentUpload_v4'">
                    <app-ui-document-upload-v4 [field]="field" [form]="form" [model]="model" [primaryModel]="primaryModel" [isFieldSectionHidden]="ruleEngineService.isFieldSectionHidden(field.key)"></app-ui-document-upload-v4>
                  </ng-container>
                  <ng-container *ngSwitchCase="'multi_data'">
                    <app-ui-multi-data [readonly]="readonly" [field]="field" [modeRead]="modeRead" [form]="form" [model]="model"></app-ui-multi-data>
                  </ng-container>
                  <ng-container *ngSwitchCase="'pdfEditor_v4'">
                    <app-ui-pdf-editor-v4 [field]="field" [form]="form" [model]="model"></app-ui-pdf-editor-v4>
                  </ng-container>
                  <ng-container *ngSwitchCase="'imagePreview_v4'">
                    <app-ui-document-preview-v4 [field]="field" [form]="form" [model]="model" [isFieldSectionHidden]="ruleEngine?.isFieldSectionHidden(field.key)"></app-ui-document-preview-v4>
                  </ng-container>
                  <ng-container *ngSwitchCase="'bulkupload'">
                    <app-ui-bulkupload [field]="field" [form]="form" [model]="model"></app-ui-bulkupload>
                  </ng-container>
                  <ng-container *ngSwitchCase="'verifyandapprove'">
                    <app-ui-verify-and-approve [field]="field" [form]="form" [model]="model"></app-ui-verify-and-approve>
                  </ng-container>
                  <ng-container *ngSwitchCase="'uploadfile'">
                    <app-ui-upload-file [field]="field" [form]="form" [model]="model"></app-ui-upload-file>
                  </ng-container>
                  <ng-container *ngSwitchCase="'categorize'">
                    <app-ui-categorize [field]="field" [form]="form" [model]="model"></app-ui-categorize>
                  </ng-container>
                  <ng-container *ngSwitchCase="'policycompliance'">
                    <app-ui-policy-compliance [field]="field" [form]="form" [model]="model"></app-ui-policy-compliance>
                  </ng-container>
                  <ng-container *ngSwitchCase="'previewanddetailscheck'">
                    <app-ui-preview-and-details-check [field]="field" [form]="form" [model]="model"></app-ui-preview-and-details-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'financialTableV2'">
                    <ui-financial-table-v2 [field]="field" [form]="form" [model]="model">
                    </ui-financial-table-v2>
                  </ng-container>
                  <ng-container *ngSwitchCase="'gauge_chart'">
                    <ui-gauge-chart  [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService"></ui-gauge-chart>
                  </ng-container>
                  <ng-container *ngSwitchCase="'value_indicator'">
                    <ui-value-indicator [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-value-indicator>
                  </ng-container>
                  <ng-container *ngSwitchCase="'otp'">
                    <ui-otp [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService"></ui-otp>
                  </ng-container>
                  <ng-container *ngSwitchCase="'financial_table'">
                    <ui-financial-table [form]="form" [model]="model" [field]="field" [view]='false'
                      [fieldService]="fieldService"></ui-financial-table>
                  </ng-container>
                  <ng-container *ngSwitchCase="'timer'">
                    <ui-timer [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService" [readonly]="editDisabled"></ui-timer>
                  </ng-container>
                  <ng-container *ngSwitchCase="'vkycStatusCheck'">
                    <app-ui-vkyc-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-vkyc-status-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'reCaptcha'">
                    <ui-google-captcha [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService"></ui-google-captcha>
                  </ng-container>
                  <ng-container *ngSwitchCase="'accordion'">
                    <accordion [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService"></accordion>
                  </ng-container>
                  <ng-container *ngSwitchCase="'card'">
                    <card [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                      [fieldService]="fieldService"></card>
                  </ng-container>
                <ng-container *ngSwitchCase="'multipleInputs'">
                  <multiple-inputs [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                  [fieldService]="fieldService"></multiple-inputs>
                </ng-container>
                  <ng-container *ngSwitchCase="'listFieldsQueries'">
                    <list-fields [form]="form" [model]="model" [jointModel]="jointModel" [field]="field"
                      [fieldService]="fieldService"></list-fields>
                  </ng-container>
                  <ng-container *ngSwitchCase="'listDocuments'">
                    <list-documents [form]="form" [model]="model" [jointModel]="jointModel" [field]="field" [fieldService]="fieldService">
                    </list-documents>
                  </ng-container>
                  <ng-container *ngSwitchCase="'listUsers'">
                    <list-users [form]="form" [model]="model" [jointModel]="jointModel" [field]="field" [fieldService]="fieldService">
                    </list-users>
                  </ng-container>
                  <ng-container *ngSwitchCase="'image'">
                    <ui-image  [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService">
                    </ui-image>
                  </ng-container>
                  <ng-container *ngSwitchCase="'imagePreview'">
                    <ui-image-preview  [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService" style="width:100%">
                    </ui-image-preview>
                  </ng-container>
                  <ng-container *ngSwitchCase="'counter'">
                    <ui-counter [theme]="theme" [form]="form" [model]="model" [field]="field" [fieldService]="fieldSetupService"></ui-counter>
                  </ng-container>
                  <ng-container *ngSwitchCase="'ui_dynamic_radio'">
                    <ui-dynamic-radio [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" [fieldService]="fieldService"></ui-dynamic-radio>
                  </ng-container>
                  <ng-container *ngSwitchCase="'progresshorizontal'">
                    <ui-progress [theme]="theme" [form]="form" [model]="model" [field]="field" [fieldService]="fieldSetupService"></ui-progress>
                  </ng-container>
                  <ng-container *ngSwitchCase="'kyc_info_basic'">
                    <app-ui-kyc-info [field]="field" [modeRead]="modeRead" [form]="form" [model]="model"></app-ui-kyc-info>
                  </ng-container>
                  <ng-container *ngSwitchCase="'loan_details'">
                    <app-ui-loan-details [field]="field" [modeRead]="modeRead" [form]="form" [model]="model"></app-ui-loan-details>
                  </ng-container>
                  <ng-container *ngSwitchCase="'loanSummary'">
                    <app-ui-loan-summary [field]="field" [modeRead]="modeRead" [form]="form" [model]="model"></app-ui-loan-summary>
                  </ng-container>
                  <ng-container *ngSwitchCase="'loanSuccess'">
                    <app-ui-loan-success [field]="field" [modeRead]="modeRead" [form]="form" [model]="model"></app-ui-loan-success>
                  </ng-container>
                  <ng-container *ngSwitchCase="'finvu'">
                    <ui-finvu [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-finvu>
                  </ng-container>
                  <ng-container *ngSwitchCase="'finvuV2'">
                    <ui-finvu-v2 [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-finvu-v2>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digilocker'">
                    <ui-digilocker [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-digilocker>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digilockerV2'">
                    <ui-digilocker-v2 [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-digilocker-v2>
                  </ng-container>
                  <ng-container *ngSwitchCase="'perfios_status_check'">
                    <app-ui-perfios-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-perfios-status-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'vkycStatusCheck'">
                    <app-ui-vkyc-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-vkyc-status-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digilocker_status_check'">
                    <app-ui-digilocker-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-digilocker-status-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digio_status_check'">
                    <app-ui-digio-mandate-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-digio-mandate-status-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digilocker_v2_status_check'">
                    <app-ui-digilocker-v2-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-digilocker-v2-status-check>
                  </ng-container>
                  <ng-container *ngSwitchCase="'apiStitcher'">
                    <app-ui-api-stitcher [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-api-stitcher>
                  </ng-container>
                  <ng-container *ngSwitchCase="'review_sign_document'">
                    <app-ui-review-sign-doc [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-review-sign-doc>
                  </ng-container>
                  <ng-container *ngSwitchCase="'successFailureimage'">
                    <app-ui-success-failure-image [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-success-failure-image>
                  </ng-container>
                  <ng-container *ngSwitchCase="'bigAmountui'">
                    <app-ui-big-amount [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-big-amount>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digio_mandate'">
                    <ui-digio-mandate [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-digio-mandate>
                  </ng-container>
                  <ng-container *ngSwitchCase="'digioV2'">
                    <ui-digio-v2 [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-digio-v2>
                  </ng-container>
                  <ng-container *ngSwitchCase="'geoLocation'">
                    <app-ui-geolocation [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-geolocation>
                  </ng-container>
                  <ng-container *ngSwitchCase="'verificationByUrl'">
                    <app-ui-verification-by-url [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field" 
                    [fieldService]="formService"></app-ui-verification-by-url>
                  </ng-container>
                  <ng-container *ngSwitchCase="'morpho_biometric'">
                    <ui-morphobiometric [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></ui-morphobiometric>
                  </ng-container>
                  <ng-container *ngSwitchCase="'repayment_status_check'">
                    <app-ui-repayment-status-check [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
                    [fieldService]="fieldService"></app-ui-repayment-status-check>
                  </ng-container>
                </ng-container>
              </div>
        </field-verification>
    </emphasise-field>
</div>
<app-user-authentication *ngIf="backendService?.otpVerification === 'true'" [theme]="theme" [mode]="mode" [form]="form" [model]="model" [field]="field"
[fieldService]="fieldService"></app-user-authentication>

