import { ApplicationTasksConfigs } from '@vanguard/shared/interfaces/applicationTaskConfigs.interface';
import { AppConstants } from '@vanguard/config/appConstants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { tap } from 'rxjs/operators';
import { environment as configs } from 'environments/environment';
import { Filter } from '../models/filter';
import { map } from 'rxjs/operators';
import { UserService } from '@vanguard/shared/services/user.service';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { PersonalDetailsObject } from '@vanguard/shared/interfaces/personalDetails.interface';
import { Router } from '@angular/router';
import { BackstageServiceComponent } from '@vanguard/shared/services/backstage.service';
import * as _ from 'lodash';
import { MultilingualService } from '@vanguard/shared/services/multilingual.service';

interface ApplicantRecordsQuery {
  teams?: string[];
  stepName?: string[];
  userIds?: string[];
  progress?: string[];
  allowedUsers?: string[];
  applicant?: string;
  product_code?: string;
  isStatistics?: boolean;
  taskName?: string | string[];
  filter?: [];
  queryType?: string;
  _applicationStatus?: string;
}

interface Pagination {
  pageNumber?: number;
  limit?: number;
}

@Injectable()
export class ApplicationsService {
  adminUrl: string = configs.adminUrl
  constructor(
    private userService: UserService,
    private apollo: Apollo,
    private commonApiService: CommonhttpService,
    public backendService: BackendService,
    public router: Router,
    public backstageService: BackstageServiceComponent,
    private multilingualService: MultilingualService
    ) {
  }

  public getTableConfig(): Observable<any> {
    const backend_url = configs.clientUrl;

    return this.commonApiService.get(`${backend_url}/backstage/getTableConfig/ALL_APPLICATIONS`)
      .pipe(map(response => response));
  }

  public getApplications(body): Observable<any> {
    const backend_url = configs.clientUrl;

    let encRequestInfo = body.query;
    // if (configs.encryptRequest) {
    //   encRequestInfo = { data: this.commonApiService.encryptData(JSON.stringify(body.query)) };
    // }
    return this.commonApiService.post(`${backend_url}/${body.url}`, encRequestInfo)
      .pipe(
        tap(response => {
          // if (configs.encryptRequest) {
          //   response.data = this.commonApiService.decryptData(response.data);
          // }
          return response;
        })
      );
  }// public getApplications(body): Observable<any>

  public getProductsAndStages(): Observable<any> {
    return this.commonApiService.get(`${this.adminUrl}/master_configs/products`)
      .pipe(
        map(response => response)
      );
  }


  public enumerateFilters(): Observable<any> {
    return this.commonApiService.get(`${this.adminUrl}/setup/configs/FILTERS/PRODUCTS`)
      .pipe(
        tap(response => {
          return response;
        })
      );
  }// public enumerateFilters(): Observable<Filter>

  public updateOrSaveFilter(argFilter: Filter): Observable<any> {
    const body = JSON.stringify(argFilter);
    return this.commonApiService.post(`${configs.clientUrl}/setup/save_or_update`, body)
      .pipe(map((response) => response));
  }// public updateOrSaveFilter(argFilter: Filter): Observable<any>



  public queryApplicantRecords(
    queryDetails: ApplicantRecordsQuery,
    queryFields: string[],
    applicationFilter,
    pagination?: Pagination,
    selectedVersion?: number,
    completedBucketCountRequired?: boolean,
    isExport?:boolean
  ): Observable<any> {
    // Include APPLICATION_STATUS_FLAG in query fields
    if (queryFields && queryFields.indexOf(AppConstants.APPLICATION_STATUS_FLAG) === -1) {
      queryFields.push(AppConstants.APPLICATION_STATUS_FLAG);
    }
    const customFilter = [...applicationFilter];
    // Include queryFields filter to customFilter, if any
    if (queryDetails.filter && queryDetails.filter.length > 0) {
      customFilter.push(...queryDetails.filter);
    }

    const variables = {
      product_code: queryDetails.product_code,
      filter: customFilter,
      applicant: queryDetails.applicant,
      userIds: queryDetails.userIds || [],
      progress: queryDetails.progress || [],
      allowedUsers: queryDetails.allowedUsers || [],
      teams: queryDetails.teams || [],
      stepName: queryDetails.stepName || [],
      taskName: queryDetails.taskName || '',
      queryType: queryDetails.queryType || '',
      _applicationStatus: queryDetails._applicationStatus ? queryDetails._applicationStatus : [AppConstants.APPLICATION_STATUS_MAP.ACTIVE]
    };
    if (pagination) {
      variables['pagination'] = pagination;
    }

    if (selectedVersion || selectedVersion === 0) {
      variables['_verNum'] = selectedVersion;
    }
    if(isExport){
      variables['isExport'] = true;
    }

    return this.apollo.query<any>({
      query: gql`query(
            $filter:[filterType]!,
            $product_code: String!,
            $applicant: String!,
            $userIds: [String]!,
            $allowedUsers: [String]!,
            $teams: [JSONObject]!,
            $progress: [String]!,
            $stepName: [String]!,
            $_applicationStatus: JSON!,
            $queryType: String!,
            $taskName: JSON!,
            ${pagination ? '$pagination: paginationType!,' : ''}
            ${selectedVersion || selectedVersion === 0 ? '$_verNum: JSON!,' : ''}
            ){
          applications(
            teams: $teams
            progress: $progress,
            stepName: $stepName,
            userIds: $userIds,
            allowedUsers: $allowedUsers,
            applicant: $applicant,
            filter: $filter,
            product_code: $product_code,
            _applicationStatus: $_applicationStatus,
            queryType: $queryType,
            taskName: $taskName,
            ${pagination ? 'pagination: $pagination,' : ''}
            ${selectedVersion || selectedVersion === 0 ? '_verNum: $_verNum,' : ''}
            ) {
              data {
                ${queryFields},
                tasks {
                  taskId,
                  name,
                  application_id,
                  progress,
                  assignedTo,
                  assignedTeam,
                  allowedUsers,
                  blocking,
                  type,
                  flowInfo {
                    product_code,
                    flowName,
                    verNum
                  },
                  stepInfo {
                    stepType,
                    stepName,
                    stepNumber
                  },
                  actions {
                    type
                    value
                  },
                  activeOn,
                  operationalLevelAgreement {
                    overall {
                      status,
                      startTS,
                      timeLimit {
                        enabled,
                        valueInMins,
                        expectedEndTS
                      },
                      endTS
                    },
                    queued {
                      status,
                      startTS,
                      timeLimit {
                        enabled,
                        valueInMins,
                        expectedEndTS
                      },
                      endTS
                    },
                    assigned {
                      status,
                      startTS,
                      timeLimit {
                        enabled,
                        valueInMins,
                        expectedEndTS
                      },
                      endTS
                    }
                  }
                }
              }
              count,
              stageWise {
                stepName,
                count
              },
              taskNameWise {
                taskName,
                count
              },
              versionNumberWise {
                versionNumber,
                count
              },
              ${completedBucketCountRequired ? 'closedCount' : ''}
            }
        }`,
      variables
    }).pipe(map((response: any) => {
      // if (configs.encryptRequest) {
      //   response.data = this.commonApiService.decryptData(response.data);
      // }
      return response;
    }));
  }

  public getApplicationDetails(variables) {
    return this.apollo.query<any>({
      query: gql`query application(
        $application_id: String!)
        {
          application(
            application_id: $application_id)
          {
            appLifeCycle
            {
              stage,
              steps
              {
                stepName,
                type,
                assignmentInfo
                {
                  assignedTo,
                  assignedTeam,
                  allowedUsers,
                  progress,
                  taskName
                  taskId
                }
              }
            }
          }
        }`,
      variables
    }).pipe(map((response: any) => {
      // if (configs.encryptRequest) {
      //   response.data = this.commonApiService.decryptData(response.data);
      // }
      return response;
    }));
  }

  public getAuditList(argApplicationId: string, argFieldKey: string[]): Observable<any> {

    return this.commonApiService.get(`${configs.clientUrl}/v3/audits/getAudits?application_id=${argApplicationId}&fieldKey=${argFieldKey}`)
      .pipe(map(response => response));
  }
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< OaoService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  public applicationApprovals(application_id: string): Observable<any> {
    return this.commonApiService.get(`${configs.clientUrl}/v3/application/approvals/getApprovals?application_id=${application_id}`)
      .pipe(map((response) => response));
  }// public applicationApprovals(application_id: string): Observable<any>

  public enumerateApplicationSla(argapplication_id: string, argTaskId: string[]) {

    return this.commonApiService.get(`${configs.clientUrl}/v3/taskconfigs/getConfigs?application_id=${argapplication_id}&taskId=${argTaskId}`)
      .pipe(map((response) => response));
  }// public enumerateApplicationSla(argapplication_id: string, argStepNumber: number)

  public enumerateApplicationSlaByStepIndex(argapplication_id: string, stepNumber: number) {

    return this.commonApiService.get(`${configs.clientUrl}/v3/taskconfigs/getConfigs?application_id=${argapplication_id}&stepNumber=${stepNumber}`)
      .pipe(map((response) => response));
  }// public enumerateApplicationSlaByStepIndex(argapplication_id: string, stepNumber: number)

  public enumerateApplicationTasks(application_id) {
    return this.commonApiService.get(`${configs.clientUrl}/v3/taskconfigs/getConfigs?application_id=${application_id}`)
      .pipe(map((response) => response));
  }

  public updateApplicationStatusAndSendMail(statusInfo) {

    return this.commonApiService.post(`${configs.clientUrl}/app/status_update`, JSON.stringify(statusInfo))
      .pipe(map((response: Response) => response));
  }// public updateApplicationStatusAndSendMail(statusInfo)

  public createOrUpdateApplication(appData,fetchMicroflowStatusAndContinue?, product_code?: string) {
    // Get selected language for translation
    let headers = {};
    const lang = this.multilingualService.selectedLanguage;
    headers['entityid'] = localStorage.getItem('entityId')|| "";
    let params;
    if (lang) {
      params = { lang };
    }
    if(fetchMicroflowStatusAndContinue){
      params.fetchMicroflowStatusAndContinue = true;
    }
    let encRequestInfo = appData;
    if(product_code == 'GNL_1h5m9sfsu') {
      return this.commonApiService.post(`${configs.clientUrl}/v4/application/consumer/save_or_update/GNL_1h5m9sfsu`, encRequestInfo, params, headers)
        .pipe(map((response: any) => {return this.backendService.setModTimeAndReturn(response);}));
    } else {
      return this.commonApiService.post(`${configs.clientUrl}/v3/application/consumer/save_or_update?isV2Flow=${this.backendService.isV2Flow}`, encRequestInfo, params, headers)
        .pipe(map((response: any) => {
          return this.backendService.setModTimeAndReturn(response);
        }));
    }
  }

  /**
   * Enumerate Applications/getApplications
   * @param applicationInfo :any
   */
  public enumerateApplications(applicationInfo) {

    return this.commonApiService.post(`${configs.clientUrl}/app/fetch_applications`, JSON.stringify(applicationInfo))
      .pipe(map((response: Response) => response));
  }// public getApplications(applicationInfo)

  public requestCollectDocumentThroughEmail(data) {
    this.commonApiService.post(`/v3/application/queries/requestInfo`, data)
      .pipe(map(response => response));
  }

  public findTaskInAppLifeCycle(appLifeCycle, taskId): ApplicationTasksConfigs {
    let result;
    const steps = appLifeCycle["steps"];
    steps.forEach((step, stepIndex) => {
      if (step.assignmentInfo) {
        let matchingTask = step.assignmentInfo.filter(assignment => assignment.taskId === taskId);
        if (matchingTask && Array.isArray(matchingTask) && matchingTask.length > 0) {
          matchingTask = matchingTask[0];
          result = { taskId, taskDetails: matchingTask, stepIndex: stepIndex, stepDetails: step };
        }
      }
    });
    return result;
  }

  public saveApplication(appData) {
    return this.commonApiService.post(`${configs.clientUrl}/v3/application/save`, appData)
      .pipe(map((response: Response) => this.backendService.setModTimeAndReturn(response)));
  }// public saveApplication(appData)

  public applicationAssigned(taskInfo, applicationStatus) {
    // one of the assingment info team, need to be there in current user logged in team
    const userTeams = [this.userService.userDetails.teamData];
    let isOwnTeam = false;
    if (taskInfo.assignedTeam) {
      if (taskInfo.assignedTeam.some(e => userTeams.find(aTeam =>
        (e.hierarchy && e.hierarchy.allTeams && e.hierarchy.allTeams.indexOf(aTeam.hierarchy.id) > -1 || e.hierarchy.id === aTeam.hierarchy.id)
        && (e.department && e.department.allTeams && e.department.allTeams.indexOf(aTeam.department.id) > -1 || e.department.id === aTeam.department.id)))) {
        isOwnTeam = true;
      }
    }
    // There are three parameters
    // 1) application_status should not be rejected or cancelled
    // 2) assgined to need to be current user
    // 3) Progress need to be in progress
    // 4) One of the assingment info team, need to be there in current user logged in team
    if (taskInfo.assignedTo.id === this.userService.userId &&
      applicationStatus === AppConstants.APPLICATION_STATUS_MAP.ACTIVE &&
      taskInfo.progress === "In Progress" && isOwnTeam) {
      return true;
    }
    return false;
  }

  viewApplicationDetails(applicationSelected, currentTaskDetail: ApplicationTasksConfigs) {
    this.backendService.personalDetailsObject = new PersonalDetailsObject('', '', '', '', '', '', '');
    this.backendService.externalDetailsPrimary = new PersonalDetailsObject('', '', '', '', '', '', '');
    this.backendService.updatedDetailsPrimary = new PersonalDetailsObject('', '', '', '', '', '', '');
    this.backendService.jointModel = [];
    this.backendService.externalDetailsJoint = [];
    this.backendService.updatedDetailsJoint = [];

    const isApplicationAssigned = this.applicationAssigned(currentTaskDetail, applicationSelected._applicationStatus);

    this.backstageService.currentTaskConfigs = currentTaskDetail;
    this.backstageService.isApplicationAssigned = isApplicationAssigned;

    if (currentTaskDetail.stepInfo.stepType === 'CUST_FLOW') {
      if (this.backstageService.isApplicationAssigned) {
        this.backendService.resumeApplicationId = applicationSelected.application_id;
        this.router.navigateByUrl(`/backstage/newapplication?resume`);
      } else {
        this.backstageService.primaryApplicantID = applicationSelected.application_id;
        this.backstageService.currentSubStatus = this.backstageService.currentTaskConfigs.name;
        this.router.navigate(['/backstage/application']);
      }
    } else {
      this.backstageService.primaryApplicantID = applicationSelected.application_id;
      this.backstageService.currentSubStatus = this.backstageService.currentTaskConfigs.name;
      this.router.navigate(['/backstage/application']);
    }
  }

  public fetchFlowInfoWithVersion(flowInfo) {
    return this.commonApiService.post(`${configs.adminUrl}/v3/flow/fetch_flowDetails`, JSON.stringify(flowInfo))
      .pipe(map((response: Response) => response));
  }// public fetchFlowInfoWithVersion(statusInfo)

  findTaskInfoAndApplicationAssigned(application, stageIndex = null) {
    let currentTaskInfo, isApplicationAssigned;
    const stageIdx = (stageIndex) ? stageIndex : application.appLifeCycle.stage;
    const step = application.appLifeCycle.steps[stageIdx];
    const stepTasks = application['applicationTaskConfigs'].filter(task => task.stepInfo && task.stepInfo.stepNumber === stageIdx);

    if (stepTasks.length > 0) {
      for (const taskInfo of stepTasks) {
        currentTaskInfo = {
          taskId: taskInfo.taskId,
          taskDetails: taskInfo,
          stepIndex: stageIdx,
          stepDetails: step,
        };
        const applicationAssigned = this.applicationAssigned(taskInfo, application._applicationStatus);
        isApplicationAssigned = (applicationAssigned) ? true : false;
        if (applicationAssigned) {
          isApplicationAssigned = true;
          break;
        } else {
          isApplicationAssigned = false;
        }
      }
    }
    return { currentTaskInfo, isApplicationAssigned };
  }

  checkIsSummaryEnabled(model) {
    // check if the application is assigned to user and summary is enabled
    const appLifeCycle = model['appLifeCycle'];
    const stage = appLifeCycle['stage'];
    const summaryEnabled = appLifeCycle['steps'][stage].summaryEnabled;
    const { currentTaskInfo, isApplicationAssigned } = this.findTaskInfoAndApplicationAssigned(model, stage);
    if ((summaryEnabled && currentTaskInfo && isApplicationAssigned) || !isApplicationAssigned) {
      return true;
    } else {
      return false;
    }
  }

  addStepLevelTaskToApplication(applications) {
    for (const application of applications) {
      try {
        const stepNumberTaskMap: Map<number, Array<any>> = new Map<number, Array<any>>();
        if (application && application.tasks && application.tasks.length > 0) {
          for (const task of application.tasks) {
            const stepNumber = task.stepInfo.stepNumber;
            if (task.operationalLevelAgreement && task.operationalLevelAgreement.overall && task.operationalLevelAgreement.overall.startTS) {
              task['start_time'] = task.operationalLevelAgreement.overall.startTS;
            }
            if (task.operationalLevelAgreement && task.operationalLevelAgreement.overall && task.operationalLevelAgreement.overall.endTS) {
              task['end_time'] = task.operationalLevelAgreement.overall.endTS;
            }
            const currentTasks = stepNumberTaskMap.get(stepNumber) || [];
            currentTasks.push(task);
            stepNumberTaskMap.set(stepNumber, currentTasks);
          }
        }
        if (!application.steps) {
          application.steps = [];
        }
        for (const stepNumber of Array.from(stepNumberTaskMap.keys())) {
          application.steps[stepNumber] = {
            tasks: stepNumberTaskMap.get(stepNumber)
          };
        }
      } catch (error) {
        console.log("Error because data in application is not correct" + application.application_id);
      }
    }
    return applications;
  }

  getTaskOperationalLevelAgreement(task: ApplicationTasksConfigs) {
    if (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS) {
      if (task.operationalLevelAgreement &&
         task.operationalLevelAgreement.assigned &&
         task.operationalLevelAgreement.assigned.timeLimit &&
         task.operationalLevelAgreement.assigned.timeLimit.enabled) {
           return {
             dueTime: task.operationalLevelAgreement.assigned.timeLimit.expectedEndTS,
             status: task.operationalLevelAgreement.assigned.status
           };
         }
    } else if (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) {
      if (task.operationalLevelAgreement &&
        task.operationalLevelAgreement.queued &&
        task.operationalLevelAgreement.queued.timeLimit &&
        task.operationalLevelAgreement.queued.timeLimit.enabled) {
          return {
            dueTime: task.operationalLevelAgreement.queued.timeLimit.expectedEndTS,
            status: task.operationalLevelAgreement.queued.status
          };
        }
    }

    if (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS ||
      task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) {
      if (task.operationalLevelAgreement &&
        task.operationalLevelAgreement.overall &&
        task.operationalLevelAgreement.overall.timeLimit &&
        task.operationalLevelAgreement.overall.timeLimit.enabled) {
          return {
            dueTime: task.operationalLevelAgreement.overall.timeLimit.expectedEndTS,
            status: task.operationalLevelAgreement.overall.status
          };
        }
    }

    return;
  }

  public updateSnoozeTimeForTask(appData) {
    return this.commonApiService.post(`${configs.clientUrl}/v3/taskconfigs/updateSnoozeTime`, appData)
    .pipe(map(response => response));
  }

  public removeSnoozeTimeForTask(appData) {
    return this.commonApiService.post(`${configs.clientUrl}/v3/taskconfigs/removeSnoozeTime`, appData)
    .pipe(map(response => response));
  }
  public linkVerification(body): Observable<any> {
    const backend_url = configs.clientUrl;

    let encRequestInfo = body;
    if (configs.encryptRequest) {
      encRequestInfo = { data: this.commonApiService.encryptDataV2(JSON.stringify(body)) };
    }
    return this.commonApiService.post(`${backend_url}/v4/application/verify`, encRequestInfo)
      .pipe(
        tap(response => {
          if (configs.encryptRequest) {
            response.data = this.commonApiService.decryptDataV2(response.data);
          }
          return response;
        })
      );
  }
}
