import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { environment as configs } from 'environments/environment';
const uri = `${configs.clientUrl}/v4/graphql/`;
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    return {
        link: httpLink.create({
            uri,
            headers: new HttpHeaders({
                access_token: localStorage.getItem('access_token') || '',
                sessionId: localStorage.getItem('sessionId') || ''
            })
        }),
        cache: new InMemoryCache(
           {addTypename: false}
        ),
    };
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
              return {
                adminAPIClient: {
                    link: httpLink.create({ uri: `${configs.adminV2Url}/v2/graphql` }),
                    cache: new InMemoryCache()                   
                },
                lookupAPIClient: {
                    link: httpLink.create({ uri: `${configs.lookupUrl}/v2/graphql` }),
                    cache: new InMemoryCache()
                },
                orgAPIClient: {
                    link: httpLink.create({ uri: `${configs.orgAPIBaseUrl}/v2/graphql` }),
                    cache: new InMemoryCache()
                },
                docHubAPIClient: {
                    link: httpLink.create({ uri: `${configs.docHubAPIBaseUrl}/v2/graphql` }),
                    cache: new InMemoryCache()
                }
              };
            },
            deps: [HttpLink]
        }
    ],
})
export class GraphQLModule { }
