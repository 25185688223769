import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppConstants } from '@vanguard/config/appConstants';
import { session } from '@vanguard/config/keycloakSessionConfig';
import { environment as configs } from 'environments/environment';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject, throwError,of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ApplicantDetails } from "../interfaces/applicantDetails.interface";
import { ConfigDetails } from "../interfaces/configinterface";
import { CustomeStyleInterface } from "../interfaces/customeStyle.interface";
import { PersonalDetailsObject } from "../interfaces/personalDetails.interface";
import { UserDetailsObject } from "../interfaces/userDetails.interface";
import { TaskStatus, UserEnrolmentTask } from '@vanguard/shared/models/userEnrolmentTask';
import { BackstageServiceComponent } from "../services/backstage.service";
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from '@vanguard/shared/services/user.service';
import { ALMFlowTypes } from '@vanguard/core/models/alm-flow-types';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { TranslateService } from "@node_modules/@ngx-translate/core";
import { ALM_MODES } from '@vanguard/core/models/alm-modes';
import { FieldConfigs } from '@vanguard/core/components/fieldConfigs';
import { FileUploader } from 'ng2-file-upload';
import { MultilingualService } from './multilingual.service';
import { MultiDataService } from '@vanguard/core/ui-elements/v4/ui-multi-data/ui-multi-data.service';
import { HTTPService } from '@vanguard/core/ui-elements/v4/services/http.service';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare var $;

@Injectable()
export class BackendService {
  public stepper = new BehaviorSubject<any>([]);
  currentStepper = this.stepper.asObservable();
  public messageSource = new BehaviorSubject<any>([]);
  currentMessage = this.messageSource.asObservable();
  public scrollSource = new BehaviorSubject<any>([]);
  scrollPage = this.scrollSource.asObservable();
  public setMessage = new BehaviorSubject<any>([]);
  public changeTab = this.setMessage.asObservable();
  public max_amount_length = 10;
  public formStatus = false;
  public updatesSubject = new EventEmitter();
  public initializeApplication = new EventEmitter();
  public resumeApplicationId = '';
  public customerPortalProductCode = '';
  public customerPortalLastSectionId;
  public customerPortalLastSubSectionId;
  public customerPortalDetails;
  baseURL: string = configs.clientUrl;
  decisionEngineUrl: string = configs.decisionEngineUrl;
  testURL: String = configs.testUrl;
  adminUrl: string = configs.adminUrl;
  adminV2Url:string = configs.adminV2Url;
  data: PersonalDetailsObject;
  officeAccountLoggedIn: boolean;
  userExistingFlag: boolean; // chandan
  loginFlag = false; // chandan
  configMsg: ConfigDetails;
  personalDetailsObject = new PersonalDetailsObject('', '', '', '', '', '', '');
  userDetailsObject = new UserDetailsObject('', '');  // chandan
  externalDetailsPrimary = new PersonalDetailsObject('', '', '', '', '', '', '');
  updatedDetailsPrimary = new PersonalDetailsObject('', '', '', '', '', '', '');
  jointModel: Array<PersonalDetailsObject> = [];
  externalDetailsJoint: Array<PersonalDetailsObject> = [];
  updatedDetailsJoint: Array<PersonalDetailsObject> = [];
  masterSecondaryModel: PersonalDetailsObject;
  guarantorModel: Array<PersonalDetailsObject> = [];
  // public routerChanges:any = null;
  // public dedupeDetails:any;

  public customeStyleInterface = new CustomeStyleInterface();
  public dataToolsReqKey: string;
  public mastersoftusername: string;
  public mastersoftpassword: string;
  callMatchingCustomerFlag = false;
  public token: string;
  public uuid: string;
  progressBardata: string[] = ['', '', '', '', ''];
  public existingUserUrl: string;
  public isResume = false;
  public campaignId = '';
  public init;
  public fields = {};
  public fieldsArray = [];
  public browser = '';
  public currentApplicant: ApplicantDetails = new ApplicantDetails();
  public chequeComp: any;
  public temp_flowDetails = { verNum: 0, flowId: "" };
  public slidePage = new BehaviorSubject<any>([]);
  currentPage = this.slidePage.asObservable();

  public nextAnimation;
  public AdminFormControl = false;
  public adminFormControlObservable = new BehaviorSubject<any>([]);
  public adminSideOption = this.adminFormControlObservable.asObservable();
  public adminSaveData = new BehaviorSubject<any>([]);
  public adminsaveDataoption = this.adminSaveData.asObservable();
  public OpenFromAdmin = false;
  public saveDataLadda = false;
  public resumeModelOrNot = false;
  public totalApplicants;
  public backStatus = [];
  public calcStatus;
  private validationRequestQueue = [];
  public jumptoSectionisallowed = true;
  public otpVerification:any = '';
  public componentDetailsForBrowserRestriction:any;

  public timer1: any;
  public timer2: any;
  public currentForm: UntypedFormGroup;
  public ruleList = [];
  public compActionButtons: any;
  public sectionsCompleted = []; // New array created for going back instead of old one
  private isLoggedIn: boolean;
  public expandRequestForCallback = false;
  public refinanceFlowRedirection = false;
  public changeSection = new EventEmitter();
  public productParameters = [];
  public uploadQueue: Array<FileUploader> = [];
  domainConfig: any;
  public listOfAllTemplates = [];
  public allProductList = [];
  public isV2Flow:boolean
  constructor(private userService: UserService, private multilingualService: MultilingualService,public httpService:HTTPService,public router?: Router,
    public backStageService?: BackstageServiceComponent, private commonApiService?: CommonhttpService,
    public translate?: TranslateService,private _multiDataService?: MultiDataService, private _apollo?: Apollo) {
  }

  tabindex(i, status) {
    if (status) {
      return 1;
    } else {
      return -1;
    }
  }// public tabindex(i, status)

  public setBackStatus(section, subSection) {
    console.log('set back section', section, subSection);
    this.backStatus['section'] = section;
    this.backStatus['subSection'] = subSection;
  }// public setBackStatus(section, subSection)

  public getBackStatus() {
    return this.backStatus;
  }// public getBackStatus()

  public setCalcStatus(val) {
    this.calcStatus = val;
  }// public setCalcStatus(val)

  public getCalcStatus() {
    return this.calcStatus;
  }// public getCalcStatus()

  public getValidationSubSection(mandatoryChecks, currentStageName, currentSectionName, currentSubSectionName) {
    for (const stage of mandatoryChecks) {
      if (stage.stage === currentStageName) {
        for (const section of stage.sections) {
          if (section.name === currentSectionName) {
            for (const subSection of section.subSections) {
              if (subSection.name === currentSubSectionName) {
                return subSection;
              }
            }
          }
        }
      }
    }
  }// public getValidationSubSection(mandatoryChecks, currentStageName, currentSectionName, currentSubSectionName)


  public startNextValidationRequest() {
    if (this.validationRequestQueue.length > 0) {
      this.executeValidation(this.validationRequestQueue[0]);
    }
  }// public startNextValidationRequest()


  public navigate(event, var2) {
    //    console.log("event",event.target.id, event.keyCode);
    if (event.keyCode === 37) {
      let elemId = event.target.id.substring(var2.length);
      console.log("id", parseInt(elemId) - 1);
      elemId = var2 + (parseInt(elemId) - 1);
      console.log("id", elemId);
      $("#" + elemId).focus();
    }
    if (event.keyCode === 39) {
      let elemId = event.target.id.substring(var2.length);
      console.log("id", parseInt(elemId) + 1);
      elemId = var2 + (parseInt(elemId) + 1);
      console.log("id", elemId);
      $("#" + elemId).focus();
    }
  }// public  navigate(event, var2)


  public setToken(token: string) {
    this.token = token;
    this.setmasterSoftConfig();
  }// public setToken(token: string)


  public changeAdminFormControl() {
    this.adminFormControlObservable.next(this.AdminFormControl);
  }// public changeAdminFormControl()

  /**
   * Change Admin Data
   */
  public changeAdminSaveData(details) {
    this.adminSaveData.next(details);
  }// public changeAdminSaveData(details)

  /**
   * Method to Get Token
   */
  public getToken() {
    return (this.token);
  }// public getToken()

  /**
   * Method to Get UUID
   */
  public getUUID() {
    return (this.uuid);
  }// public getUUID()

  public setCustomeStyleSetter(customeStyleInterface: CustomeStyleInterface) {
    this.customeStyleInterface = customeStyleInterface;
    // console.log(customeStyleInterface);
  }// public setCustomeStyleSetter(customeStyleInterface: CustomeStyleInterface)


  public getCustomeStyleGetter() {
    return this.customeStyleInterface;
  }// public getCustomeStyleGetter()

  // SIDEBAR  changes done for UI end
  public changeMessage(sidebar) {
    this.messageSource.next(sidebar);
  }


  public changeTabFun(applicant) {
    this.setMessage.next(applicant);
  }// public changeTabFun(applicant)


  public changePagePos(val: any) {
    this.messageSource.next(val);
  }// public changePagePos(val: any)

  public setUrl(reqUrl: string) {
    this.existingUserUrl = reqUrl;
  }// public setUrl(reqUrl: string)


  public getUrl() {
    return this.existingUserUrl;
  }// public getUrl()

  public setConfig(configMsg: ConfigDetails) {
    this.configMsg = configMsg;
  }// public setConfig(configMsg: ConfigDetails)


  public getConfigMsg() {
    return this.configMsg;
  }// public getConfigMsg()


  public setPersonalDetailsObject(personalDetailsObject: PersonalDetailsObject) {
    this.personalDetailsObject = personalDetailsObject;
  }// public setPersonalDetailsObject(personalDetailsObject: PersonalDetailsObject)


  public getPersonalDetailsObject() {
    return this.personalDetailsObject;
  }// public getPersonalDetailsObject()


  public setJointPersonalDetailsObject(jointModel: Array<PersonalDetailsObject>) {
    this.jointModel = jointModel;
  }// public  setJointPersonalDetailsObject(jointModel: Array<PersonalDetailsObject>)


  public getJointPersonalDetailsObject() {
    return this.jointModel;
  }// public getJointPersonalDetailsObject()

  public setUserDetailsObject(userDetailsObject: UserDetailsObject) {
    // console.log("service setUserDeatils()")
    this.userDetailsObject = userDetailsObject;
  }// public setUserDetailsObject(userDetailsObject: UserDetailsObject)


  public getUserDetailsObject() {
    // console.log("service getUserDetailsObject()");
    return this.userDetailsObject;
  }// public getUserDetailsObject()

  public setUserExistingFlag(userExistingFlag: boolean) {
    this.userExistingFlag = userExistingFlag;
  }// public setUserExistingFlag(userExistingFlag: boolean)


  public getUserExistingFlag() {
    return this.userExistingFlag;
  }// public getUserExistingFlag()

  public setCampaignId(data: string) {
    this.campaignId = data;
  }// public setCampaignId(data: string)


  public getCampaignId() {
    return this.campaignId;
  }// public getCampaignId()

  public setData(data: PersonalDetailsObject) {
    this.data = data;
  }// public setData(data: PersonalDetailsObject)


  public getData() {
    return this.data;
  }// public getData()


  public setFields(fields) {
    this.fields = fields;
  }// public setFields(fields)


  public getFields() {
    return this.fields;
  }// public getFields()

  public setGuarantorPersonalDetailsObject(guarantorModel: Array<PersonalDetailsObject>) {
    this.guarantorModel = guarantorModel;
  }// public  setGuarantorPersonalDetailsObject(guarantorModel: Array<PersonalDetailsObject>)


  public getGuarantorPersonalDetailsObject() {
    return this.guarantorModel;
  }// public getGuarantorPersonalDetailsObject()

  /**
   * @author Vivek Sinha
   * @param model:any
   */
  public getFieldsToBeRendered(fields, applicantType) {
    const fieldsArray = [];
    const filteredFields = {};
    const dependencyConfigs = [];
    const templates = [];
    try {
      for (const actualField of fields) {
        const field = _.cloneDeep(FieldConfigs.getApplicantField(actualField, applicantType));
        if (FieldConfigs.isFieldApplicable(field, applicantType)) {
          // Add valid field to the fieldsArray, whataver feilds added here
          // will be shown in the component
          fieldsArray.push(field);
          // Find field dependencies
          // If the feild type is an CONTAINER
          if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
            filteredFields[field.groupKey] = field;
            for (let fld of field.fields) {
              if (FieldConfigs.isFieldApplicable(fld, applicantType)) {
                const indexOfFld = field.fields.indexOf(fld);
                fld = FieldConfigs.getApplicantField(fld, applicantType);
                field.fields[indexOfFld] = fld;
                if (fld.field_type_details.property_type === 'DROPDOWN') {
                    // fetch the list of products (flow detail - name and code) if the show all product option is selected and update the internal list
                  if (field.field_type_details && field.field_type_details.internal && field.field_type_details.property === "Products" && field.field_type_details.property_type === "DROPDOWN" && field.field_type_details.internal.showAllType) {
                      field.field_type_details.internal_list = this.allProductList;
                  } else {
                    dependencyConfigs.push({
                      property_type: fld['field_type_details'].property_type,
                      property: fld['field_type_details'].property,
                    });
                  }
                  // fetch the list of templates (template details - template_id, template_name, template_type, verNum)
                  // if the showAllPdfType, showAllSmsType, showAllHtmlType and showAllEmailType option is selected
                  // update the internal list with alltemplates else based on option
                  if (field.field_type_details && field.field_type_details.internal && field.field_type_details.property === "Templates"
                    && field.field_type_details.property_type === "DROPDOWN") {
                    if (field.field_type_details.internal.showAllPdfType && field.field_type_details.internal.showAllSmsType
                      && field.field_type_details.internal.showAllHtmlType && field.field_type_details.internal.showAllEmailType) {
                      templates.push(this.listOfAllTemplates);
                    } else {
                      if (field.field_type_details.internal.showAllPdfType) {
                        templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'PDF'));
                      }
                      if (field.field_type_details.internal.showAllSmsType) {
                        templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'SMS'));
                      }
                      if (field.field_type_details.internal.showAllHtmlType) {
                        templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'HTML'));
                      }
                      if (field.field_type_details.internal.showAllEmailType) {
                        templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'EMAIL'));
                      }
                    }
                    field.field_type_details.internal_list.push(...templates);
                    dependencyConfigs.push({
                      property_type: field['field_type_details'].property_type,
                      property: field['field_type_details'].property,
                    });
                  }
                }
              }
            }
          } else {
            // If the its an product or group field
            filteredFields[field.key] = field;
            if (field.field_type_details.property_type === 'DROPDOWN') {
                // fetch the list of products (flow detail - name and code) if the show all product option is selected and update the internal list
                if (field.field_type_details && field.field_type_details.internal && field.field_type_details.property === "Products" && field.field_type_details.property_type === "DROPDOWN" && field.field_type_details.internal.showAllType) {
                    field.field_type_details.internal_list = this.allProductList;
                } else {
                  dependencyConfigs.push({
                    property_type: field['field_type_details'].property_type,
                    property: field['field_type_details'].property,
                  });
                }
                // fetch the list of templates (template details - template_id, template_name, template_type, verNum)
                // if the showAllPdfType, showAllSmsType, showAllHtmlType and showAllEmailType option is selected
                // update the internal list with alltemplates else based on option
              if (field.field_type_details && field.field_type_details.internal && field.field_type_details.property === "Templates"
                && field.field_type_details.property_type === "DROPDOWN") {
                if (field.field_type_details.internal.showAllPdfType && field.field_type_details.internal.showAllSmsType
                  && field.field_type_details.internal.showAllHtmlType && field.field_type_details.internal.showAllEmailType) {
                  templates.push(...this.listOfAllTemplates);
                } else {
                  if (field.field_type_details.internal.showAllPdfType) {
                    templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'PDF'));
                  }
                  if (field.field_type_details.internal.showAllSmsType) {
                    templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'SMS'));
                  }
                  if (field.field_type_details.internal.showAllHtmlType) {
                    templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'HTML'));
                  }
                  if (field.field_type_details.internal.showAllEmailType) {
                    templates.push(...this.listOfAllTemplates.filter(data => data.key.type === 'EMAIL'));
                  }
                }
                field.field_type_details.internal_list.push(...templates);
                dependencyConfigs.push({
                  property_type: field['field_type_details'].property_type,
                  property: field['field_type_details'].property,
                });
              }
            }
          }
        }
      }
    } catch (e) {
      console.log('error', e);
    }
    return {
      filteredFields,
      dependencyConfigs,
      fieldsArray
    };
  }// public getFieldsToBeRendered(model: PersonalDetailsObject)

  fetchProductList() {
    return this.backStageService.getProductList()
    .pipe(map((res:any) => {
      if (res.error) {
        console.log(res.error);
        return [];
      } else {
        return (res.data as any).map(rd => {
              return { key: rd.product_code, value: rd.product_name };
            });
      }
    }));
  } // fetchProductList()

  // fetch all the templates type of PDF, SMS, HTML and EMAIL
  fetchAllTemplatesList() {
    return this.backStageService.getAllTemplatesAllVersionsByTypeV3(['PDF', 'SMS', 'HTML', 'EMAIL'])
    .pipe(map((res:any) => {
      if (res.error) {
        console.log(res.error);
        return [];
      } else {
        return (res.data as any).map(template => {
          return { key: { id: template.template_id, name: template.template_name, type: template.template_type, verNum: template.verNum },
            value: template.template_name };
        });
      }
    }));
  }

  public setFieldsArray(fields) {
    this.fieldsArray = fields;
  }// public setFieldsArray(fields)


  public getFieldsArray() {
    return this.fieldsArray;
  }// public getFieldsArray()

  public validateInterestAmountOnKeyUp(event) {

    console.log("validateInterestAmountOnKeyUp");
    const formControl = event.target;
    formControl.value = (formControl.value).replace(/[^0-9.]/g, '');
    const amount = formControl.value;
    if (parseFloat(amount) > 99.99) {
      return false;
    }
    return true;
  }// public validateInterestAmountOnKeyUp(event)


  public getRouteName(reqUrl) {
    const urlParts = reqUrl.split("/");
    let buttonName;
    // console.log(urlParts.length);
    if (urlParts.length === 3 || (urlParts.length > 3 && urlParts[2] !== 'jointApplicants')) {
      if (urlParts.length > 3 && urlParts[2] === 'loanDetailswithoutDebt') {
        buttonName = urlParts[2] + "-" + urlParts[3];
        return buttonName;
      }
      if (urlParts.length > 3 && urlParts[2] === 'onlineIdCheck') {
        buttonName = urlParts[2] + "-" + urlParts[3];
        return buttonName;
      }
      buttonName = urlParts[2];
      return buttonName;
    }
  }// public getRouteName(reqUrl)


  public returnFunction(event) {
    if (!(event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode)) || event.charCode === 48 || event.charCode === 110)) {
      return false;
    } else {
      return event.key !== 0;
    }
  }// public returnFunction(event)

  public initializeTransition(router) {
    this.init = router.events.subscribe(event => {
      console.log("event: ", event);

      if (event instanceof NavigationEnd) {
        // var pageWidth = jQuery(window).width();
        // if (pageWidth > 1024) {
        console.log("sdas");
        // jQuery('html, body').animate({
        //     scrollTop: 0
        // }, 1000);
        // }
      }
    });
  }// public initializeTransition(router)


  public unsubTransition() {
    if (this.init) {
      this.init.unsubscribe();
    }
  }// public unsubTransition()

  public setLoginStatus(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn;
  }// public setLoginStatus(isLoggedIn: boolean): void

  public loggedIn(): boolean {
    return this.isLoggedIn;
  }// public loggedIn(): boolean

  public addRequestToValidationQueue(application_id, stageName, sectionName, subSectionName, sectionValidations, subStatusValidations?) {
    const { groupKey, ...validations } = sectionValidations;
    const request = {
      application_id,
      stageName,
      sectionName,
      subSectionName,
      validations
    };
    if (groupKey) {
      request['groupKey'] = groupKey;
    }
    if (subStatusValidations && !_.isEmpty(subStatusValidations)) {
      request['subStatusValidations'] = subStatusValidations;
    }
    const subscription = new Subject<any>();
    this.validationRequestQueue.push({ request, subscription });
    if (this.validationRequestQueue.length === 1) {
      this.startNextValidationRequest();
    }
    return subscription;
  }// public addRequestToValidationQueue(application_id, stageName, sectionName, subSectionName, sectionValidations, subStatusValidations?)

  public setCurrentForm(form) {

    console.log('setting the form..', form);
    this.currentForm = form;
    console.log('current form value', this.currentForm);
  }// public setCurrentForm(form)

  public getCurrentForm() {
    console.log('get current form..', this.currentForm);
    return this.currentForm;
  }// public getCurrentForm()

  /**
   * Method to check if user is authorized
   * @param argRoleName :string
   */
  public isUserAuthorized(argRoleName: string): boolean {
    let returnValue: boolean;
    if (argRoleName !== '') {
      if (this.userService.userDetails && this.userService.userRoles) {
        returnValue = this.userService.userRoles.includes(argRoleName);
        return returnValue;
      } else {
        return returnValue = false;
      }
    } else {
      return returnValue = true;
    }
  }// public isUserAuthorized(argRoleName: string): boolean


  public getMasterSecondaryModel() {
    return Object.assign({}, this.masterSecondaryModel);
  }// public getMasterSecondaryModel()


  /**
   * @author Ranjith
   */
  public updateStep(model) {
    const userEnrolementTaskList = [];
    model.routeConfig.forEach((value, index) => {
      let task;
      const numberOfSections = value.pages ? value.pages.length : value.sub_sections.length;
      let currentpercent = 0;
      if (index === model.section_id) {
        task = TaskStatus.INPROGRESS;
        currentpercent = Math.round((Number(model.sub_section_id) / (numberOfSections)) * 100);
      } else if (index < model.section_id) {
        currentpercent = 100;
        task = TaskStatus.COMPLETE;
      } else {
        task = TaskStatus.PENDING;
      }
      // For success page
      if (index === model.section_id && model.section_id === model.routeConfig.length - 1) {
        currentpercent = 100;
        task = TaskStatus.COMPLETE;
      }
      const data: UserEnrolmentTask = new UserEnrolmentTask(value.name, task, index + 1, numberOfSections, currentpercent);
      userEnrolementTaskList.push(data);
    });
    this.stepper.next(userEnrolementTaskList);
  }// public updateStep(model)

  public sessionControl() {
    if (this.userService.accessToken) {
      this.getRefreshToken().subscribe(newToken => {
        this.userService.accessToken = newToken['access_token'];
        this.userService.refreshToken = newToken['refresh_token'];
        this.userService.sessionId = newToken['sessionid'];
        this.userService.sessionExpiryTime = newToken['expiry_time'];
        if (newToken['expires_in'] >= session.valid_session_time) {
          clearTimeout(this.timer2);
          clearTimeout(this.timer1);

          this.timer1 = setTimeout(() => {
            $('#session_expire_modal').modal('show');
          }, (newToken['expires_in'] - session.session_expire_warning) * 1000);

          this.timer2 = setTimeout(() => {
            $('#session_expire_modal').modal('hide');
            $('#session_expired_modal').modal('show');
          }, newToken['expires_in'] * 1000);
        }
        if (newToken['error'] === "Invalid user") {
          location.href = './login';
        }
      });
    }
  }// public sessionControl()

  public changePage(newPage) {
    this.slidePage.next(newPage);
  }// public changePage(newPage)


  public setResumeStatus(status: boolean) {
    this.isResume = status;
  }// public setResumeStatus(status: boolean)


  public getResumeStatus() {
    return this.isResume;
  }// public getResumeStatus()


  /**
   * @author Vivek Sinha
   * @param event:any
   */
  public validateAmountOnKeyUp(event) {
    const formControl = event.target;
    formControl.value = (formControl.value).replace(/[^0-9.]/g, '');
    const amount = formControl.value;
    console.log(amount, "ammmoutn");
    if (!(amount >= 1)) {
      console.log(amount, "test", typeof (amount));
      return false;
    }
    if (amount && amount.includes('.')) {
      if (amount.length === 1) {
        formControl.value = '';
        return false;
      }
      const value = amount.split('.');
      formControl.value = value[0] + '.' + value[1];
      if (value[0].length > this.max_amount_length) {
        formControl.value = value[0].substring(0, this.max_amount_length) + value[1];
        return false;
      }
    } else if (amount && amount.length > this.max_amount_length) {
      formControl.value = amount.substring(0, this.max_amount_length);
      return false;
    }
    return true;
  }// public validateAmountOnKeyUp(event)

  public setModTimeAndReturn(response: Response) {
    console.log("resppppp:", response);
    const res = response;
    console.log("res.error::", res['error']);
    if (!res['error']) {
      if (res['data'].applicant === AppConstants.SECONDARY_APPLICANT) {
        console.log("in secondary", res['data'].mod_time);

        if (!this.currentApplicant.selectedCOApplicantIndex) {
          this.currentApplicant.selectedCOApplicantIndex = 0;
        }
        this.jointModel[this.currentApplicant.selectedCOApplicantIndex].mod_time = res['data'].mod_time;
      } else if (res['data'].applicant === AppConstants.GUARANTOR_APPLICANT) {

        if (!this.currentApplicant.selectedCOApplicantIndex) {
          this.currentApplicant.selectedCOApplicantIndex = 0;
        }
        this.guarantorModel[this.currentApplicant.selectedCOApplicantIndex].mod_time = res['data'].mod_time;
      } else {
        console.log("in primary", res['data'].mod_time);
        if (res['data'].mod_time) {
          this.personalDetailsObject.mod_time = res['data'].mod_time;
        }
      }
    } else if (res['error'] === "resubmission") {
      // this.router.navigate(['/notfound']);
      return;
    }
    return response;
  }// public setModTimeAndReturn(response: Response)

  /**
   * @author Vivek Sinha
   * @param event:any
   */
  public validateAmount(event) {

    if (!(event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode)) || (event.charCode === 46 && (event.target.value.indexOf('.') === -1)))) {
      return false;
    }
    // if(event.target.value.length == 0 && event.which === 46){
    //     return false;
    // }
    var amount = event.target.value;
    console.log(amount, "amount from service");

    if (event.key === '.' && amount.length < 1) {
      return false;
    }
    if (event.key === 0 && amount.length < 1) {
      console.log("Inside");
      return false;
    }
    if (amount && amount.includes('.')) {
      amount = amount.split('.')[0];
      if (amount.length >= this.max_amount_length) {
        return false;
      }
    } else if (amount && amount.length >= this.max_amount_length) {
      return false;
    }
    return true;

  }// public validateAmount(event)


  public validateInterestAmount(event) {
    console.log("validateInterestAmount");
    if (!(event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode)) || (event.charCode === 46 && (event.target.value.indexOf('.') === -1)))) {
      return false;
    }
    const amount = event.target.value;
    if (event.key === '.' && amount.length < 1) {
      return false;
    }
    // if(amount && amount.includes('.')){
    //     amount = amount.split('.')[0];
    //     if(amount.length >= 2){
    //         return false;
    //     }
    // }
    // else if(amount && amount.length >= 2){
    //     return false;
    // }
    return parseFloat(amount) <= 99.99;
  }// public validateInterestAmount(event)

  public getBaseUrl() {
    return this.baseURL;
  }// public getBaseUrl()


  public setterchequeComp(chequeComp) {
    this.chequeComp = chequeComp;
  }// public setterchequeComp(chequeComp)

  public getterchequeComp() {
    return this.chequeComp;
  }// public getterchequeComp()

  public setFormStatus(status) {
    this.formStatus = status;
  }// public setFormStatus(status)

  public getFormStatus() {
    console.log('form verified...');
    return this.formStatus;
  }// public getFormStatus()
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< HTTP METHODS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * Method to get user group baed on the username and the accesstoken
   * @param userName :string
   * @param access_token :string
   */
  public getUserGroup(userName, access_token) {
    const reqUrl = `${this.baseURL}/v3/users/groupsByUserId`;

    return this.commonApiService.get(reqUrl)
      .pipe(map((response: Response) => response));
  }// public getUserGroup(userName, access_token)


  /**
   * Get Group based on the LogedIn user Accesstoken
   */
  public getGroup() {
    const reqUrl = `${this.baseURL}/v3/users/getGroup`;
    const data = {
      token: this.userService.accessToken
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(reqUrl, body)
      .pipe(map((response: Response) => response));
  }// public getGroup()


  public getGroupMember(groupId) {
    const reqUrl = `${this.baseURL}/v3/users/getGroupMembers`;
    const data = {
      groupid: groupId,
      token: this.userService.accessToken
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(reqUrl, body)
      .pipe(map((response: any) => response));
  }// public getGroupMember(groupId)

  public getUserRole(userName, access_token) {
    const reqUrl = `${this.baseURL}/v3/users/roles`;
    const data = {
      userName: userName,
      token: access_token
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(reqUrl, body).pipe(map((response: Response) => response));


  }// public  getUserRole(userName, access_token)


  public getRefreshToken() {
    const data = {
      refresh_token: this.userService.refreshToken,
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/auth/refresh-token`, body)
      .pipe(map((response: Response) => response));
  }


  public logout() {
    return this.commonApiService.post(this.baseURL + '/v3/auth/logout')
      .pipe(map((response: Response) => response));
  }

  /**
   * uuid:string
   */
  public getTOKEN(uuid: string) {
    this.uuid = uuid;
    const body = JSON.stringify({});
    return this.commonApiService.post(`${this.baseURL}/tokenApi/getToken`, body)
      .pipe(map((response: Response) => response));
  }// public getTOKEN(uuid: string)

  // STYLE
  public getCustomeStyle() {
    return this.commonApiService.get(`${this.baseURL}/api/getCustomeStyle`)
      .pipe(map((response: Response) => response));
  }// public getCustomeStyle()


  public GetPropertyDetails(PropertyType: String, Property: String) {
    return this.commonApiService.get(`${this.adminUrl}/api/PropertyDetails/` + PropertyType + '/' + Property)
      .pipe(map((response: Response) => response));
  }// public GetPropertyDetails(PropertyType: String, Property: String)

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }// public handleError(error: HttpErrorResponse)


  public getProductDetails(prod_id: string) {
    return this.commonApiService.get(`${this.adminUrl}/api/ProductDetails/` + prod_id)
      .pipe(map((response: Response) => response));
  }// public getProductDetails(prod_id: string)


  public getDecryptedKey(key: String) {
    const body = JSON.stringify({ "decryptMsg": key });
    return this.commonApiService.post(`${this.baseURL}/api/decryption`, body)
      .pipe(map((response: Response) => response));

  }// public getDecryptedKey(key: String)

  public getProductList() {
    const additionalHeaders = { 'x-uuid': this.uuid, 'x-access-token': this.token };
    return this.commonApiService.get(`${this.baseURL}/api/product_type_details`, null, additionalHeaders)
      .pipe(map((response: Response) => response));
  }// public getProductList()

  // added by shubham
  public getAdminConfigurations() {
    console.log("admin conf", this.token);
    const additionalHeaders = { 'x-uuid': this.uuid, 'x-access-token': this.token };
    return this.commonApiService.get(`${this.baseURL}/api/getAdminConfigurations`, null, additionalHeaders)
      .pipe(map((response: Response) => response));
  }// public getAdminConfigurations()


  public getmastersoftConfigurations() {
    const additionalHeaders = { 'x-uuid': this.uuid, 'x-access-token': this.token };
    return this.commonApiService.get(`${this.baseURL}/api/getmasterSoftConfigurations`, null, additionalHeaders)
      .pipe(map((response: Response) => response));
  }// public getAdminConfigurations()


  public setDataToolConfig() {
    this.getAdminConfigurations().subscribe((response) => {
      console.log("getAdminConfigurations");
      this.dataToolsReqKey = response[0].Data_Tool[0].dataToolsRequestKey;
    });
  }// public setDataToolConfig()


  public setmasterSoftConfig() {
    this.getmastersoftConfigurations().subscribe((response) => {
      console.log("getmastersoftConfigurations");
      this.mastersoftusername = response['result'][0].mastersoft_username;
      this.getDecryptedKey(response['result'][0].mastersoft_password).subscribe((data: any) => {
        this.mastersoftpassword = data.key;
        console.log(this.mastersoftpassword);
      });
    });
  }// public setmasterSoftConfig()


  public setMaxAmountLength(length) {
    this.max_amount_length = length;
  }// public setMaxAmountLength(length)

  public getMaxAmountLength() {
    return this.max_amount_length;
  }// public getMaxAmountLength()

  /**
   * Method for fieldsconfig
   */
  public getFieldsConfig() {
    // Get selected language for translation
    const lang = this.multilingualService.selectedLanguage;
    let params;
    if (lang) {
      params = { lang };
    }

    const model: PersonalDetailsObject = this.personalDetailsObject;
    const modelFields = model.routeConfig[model.section_id].sub_sections[model.sub_section_id].fields || [];
    let fields
    if(this.isV2Flow){
      fields = this.getFieldsToBeRendered(this.formatFields(modelFields), this.currentApplicant.applicantType);
    }else{
      fields = this.getFieldsToBeRendered(modelFields, this.currentApplicant.applicantType);
    }
    this.setFields(fields['filteredFields']);
    this.setFieldsArray(fields['fieldsArray']);
    // const currentlang = this.translate.currentLang;
    // if (fields['dependencyConfigs'].length > 0) {
    //   fields['dependencyConfigs'].map(val => {
    //     val.language = currentlang;
    //     return val;
    //   });
    // }
    let requestBody: string[] = fields['dependencyConfigs'].map((property: any) => property.property);
    let body: string = JSON.stringify(requestBody);
    if(fields['dependencyConfigs'].length){
      return this.commonApiService.get(`${this.adminV2Url}/v2/propertyList/properties?propertyList=${body}`,{},{entityid:localStorage.getItem('entityId')})
        .pipe(map((response: Response) => response));
    } else {
      return of(true);
    }
  }
  
  // public getFieldsConfig()


  /**
   * Method for Request callback
   * @param appData :any
   */
  public requestForcallback(appData) {
    const body = JSON.stringify(appData);
    return this.commonApiService.post(`${this.baseURL}/api/requestforcallback`, body)
      .pipe(map((response: Response) => response));
  }// public requestForcallback(appData)


  public getFlowConfigs(verNum: number, flowName: String, flowType?: ALMFlowTypes, mode?: string, deviceWidth?: number) {
    flowType = flowType || ALMFlowTypes.NONE;
    mode = mode || ALM_MODES.SAVED;
    return this.commonApiService.get(`${this.adminUrl}/config/route_config/${verNum}/${flowName}?flow_type=${flowType}&mode=${mode}${deviceWidth ? `&device_width=${deviceWidth}` : ''}`)
      .pipe(map((response: Response) => response));
  }// public getFlowConfigs(verNum: number, flowName: String)


  public getLatestFlowConfigs(flowName: String, mode?: string, verNum?: number, deviceWidth?: number, stepIndex?) {
    // Get selected language for translation
    const lang = this.multilingualService.selectedLanguage;
    let params;
    if (lang) {
      params = { lang };
    }

    if (deviceWidth) {
      params = {
        ...params,
        device_width: deviceWidth,
      };
    }
    if(stepIndex){
      params = {
        ...params,
        stepIndex: stepIndex //if it is for dynamic resume, pass stepindex
      }
    }


    if (mode) {
      return this.getFlowConfigs(verNum, flowName, ALMFlowTypes.CUST_FLOW, mode, deviceWidth);
    }
    return this.commonApiService.get(`${this.adminUrl}/config/latest_route_config/${flowName}`, params)
      .pipe(map((response: Response) => response));
  }// public getLatestFlowConfigs(flowName: String)

  public getLatestFlowConfigsNewAdminPortal(productCode:string,isV2Flow,stepIndex?) {
      // Get selected language for translation
      const lang = this.multilingualService.selectedLanguage;
      let params;
      if (lang) {
        params = { lang };
      }
      if(stepIndex){
        params = {
          ...params,
          stepIndex: stepIndex //if it is for dynamic resume, pass stepindex
        }
      }
      return this.httpService.get(`${this.adminV2Url}/v2/config/latest-route-config/${productCode}?isV2Flow=${isV2Flow}`, params)
        .pipe(map((response: Response) => response));
    }

    //graphql which is to be called from admin url 
    public fetchPropertyDetails(property:any){
      const variables = {
        "property": property,
    };
    return this._apollo.use('adminAPIClient').query<any>({
    query:gql`
      query ($property: [String]) {
        propertyDetails(property: $property) {
          data {
            property
            type
            property_value
            property_desc
          }
        }
    }`,
    variables:variables,
    fetchPolicy:'no-cache'
  }).toPromise();
  }

    public fetchComponentConfigs(componentId,version) {
      const variables = {
        "count": false,
        "componentId": componentId,
        "version": version,
        "status": [
          "Published"
        ]
      }
    return this._apollo.use('adminAPIClient').query<any>({
    query:gql`
      query ($count: Boolean, $componentId: String, $status: [String], $version: String) {
        componentDetails(count: $count, componentId: $componentId, status: $status, version: $version) {
          data {
            name
            version
            componentId
            rules
            customValidations
            status
            tag
            createdBy
            modifiedTS
            fields
            displayFields
            rules
            customValidations
            displayName
            skipForNewCustomer
            skipForExistingCustomer
            enableForApplicantHeaderName
            applicantHeaderLabelName
            numberOfCoapplicants
            applicantHeaderName
            enableForMultipleCoApplicant
            lookupEnabled
            lookupConfigs
            disableForPrimaryApplicant
            isSingle
            displayOptions
            pageEvents
            action_buttons
            editorData
            isGuarantor
            enableHelpText
            application_status
            route_info
          }
          count
        }
      }`,
      variables:variables,
      fetchPolicy:'no-cache'
    }).toPromise();
    }

    //graphql which is to be called from admin url 
      public fetchPageDetails(page:any){
        const variables = {
          "pageId": page.pageId,
          "version": page.version,
          "status":["Published"]
      };
      return this._apollo.use('adminAPIClient').query<any>({
      query:gql`
      query ($pageId: String, $version: String, $status: [String]){
                  fetchPages(pageId: $pageId, version: $version, status: $status)
                      {
                    data{ pageId
                          version
                          description
                          displayName
                          tag
                          pageType
                          containers
                          mappedComponents
                    }  
                      }
                  }`,
      variables:variables,
      fetchPolicy:'no-cache'
    }).toPromise();
      }

  /**
   * Upload Nic to S3 Storage
   * @param app_id :any
   * @param filename :any
   * @param filePath :any
   * @param documentType :any
   */
  public uploadNICtoS3(app_id, filename, filePath, documentType) {
    console.log("from service uploadNICtoS3", documentType);
    const body = JSON.stringify({ app_id: app_id, filename: filename, filePath: filePath, docType: documentType });
    return this.commonApiService.post(`${this.baseURL}/api/uploadNICtos3`, body)
      .pipe(map(response => response));
  }// public uploadNICtoS3(app_id, filename, filePath, documentType)

  /**
   * Upload Signature to S3 storage
   * @param dataURI :string
   * @param app_id :string
   */
  public uploadSignaturetoS3(dataURI, app_id) {
    console.log("from service uploadSignaturetoS3");
    const body = JSON.stringify({ dataURI: dataURI, app_id: app_id });
    return this.commonApiService.post(`${this.baseURL}/api/uploadSignaturetoS3`, body)
      .pipe(map(response => response));
  }// public uploadSignaturetoS3(dataURI, app_id)

  /**
   * Upload Local file to the S3 Storage
   * @param incoming_headers :any
   * @param app_id :any
   * @param filename :any
   * @param filePath :any
   * @param documentType :any
   */
  public uploadLocalFiletoS3(incoming_headers, app_id, filename, filePath, documentType) {
    console.log("from service uploadNICtoS3", documentType);
    const body = JSON.stringify({ app_id: app_id, filename: filename, filePath: filePath, docType: documentType });
    const additionalHeaders = { 'x-uuid': this.uuid, 'x-access-token': this.token, ...incoming_headers };
    return this.commonApiService.post(`${this.baseURL}/api/uploadLocalFiletoS3`, body, null, additionalHeaders)
      .pipe(map(response => response));
  }// public uploadLocalFiletoS3(incoming_headers, app_id, filename, filePath, documentType)


  /**
   * Get user Financial Details
   * @param data any
   */
  public getFinanceDetails(data) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/net_per_fin_config/net_financing_calculation`, body)
      .pipe(map(response => response));
  }// public getFinanceDetails(data)


  public validateEarnPerMonth(event) {
    if (!(event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode)) || (event.charCode === 46 && (event.target.value.indexOf('.') === -1)))) {
      return false;
    }
    let amount = event.target.value;
    console.log(amount, "amount from service");

    if (event.key === '.' && amount.length < 1) {
      return false;
    }
    if (amount && amount.includes('.')) {
      amount = amount.split('.')[0];
      if (amount.length >= this.max_amount_length) {
        return false;
      }
    } else if (amount && amount.length >= this.max_amount_length) {
      return false;
    }
    return true;

  }// public validateEarnPerMonth(event)


  /**
   * Export Report
   * @param json :any[]
   * @param excelFileName :string
   */
  public exportReport(json: any[], excelFileName: string): void {
    console.log("donnnnne1", json);
    console.log("donnnnne11", excelFileName);
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook1: XLSX.WorkBook = { Sheets: { 'Report': worksheet1 }, SheetNames: ['Report'] };
    const excelBuffer1: any = XLSX.write(workbook1, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer1, excelFileName);
    console.log("donnnnne2", worksheet1);
  }// public exportReport(json: any[], excelFileName: string): void

  /**
   * Save Excel File
   */
  public saveAsExcelFile(buffer: any, fileName: string): void {
    console.log("donnnnne22");
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }// public saveAsExcelFile(buffer: any, fileName: string): void


  /**
   *
   * @param userDetails :Object
   */
  public login(userDetails) {
    return this.commonApiService.post(`${this.baseURL}/v3/auth/login`, userDetails)
      .pipe(map((response: Response) => response));
  }// public login(userDetails)

  /**
   * Method to convert XML to JSON
   * @param xmlString :object
   */
  public convertXMLToJson(xmlString) {
    return this.commonApiService.post(`${this.baseURL}/util/xmlToJSON`, xmlString)
      .pipe(map((response: Response) => response));
  }// public convertXMLToJson(xmlString)

  /**
   * Method to search an Employee
   * @param val :any
   * @param name :any
   */
  public employeeSearcher(val, name) {
    const Body = JSON.stringify({
      kid: val.kid,
      employeeName: name
    });
    return this.commonApiService.post(`${this.testURL}/chola/hfc/EmployeeSearch`, Body)
      .pipe(map((response: Response) => response));
  }// public employeeSearcher(val, name)


  /**
   * Method to make a Bank Statement
   * @param loanDetails :object
   */
  public uploadBankStatment(loanDetails) {
    const body = JSON.stringify(loanDetails);
    return this.commonApiService.post(`${this.baseURL}/perfios/transactionLink`, body)
      .pipe(map((response: Response) => response));
  }// public uploadBankStatment(loanDetails)


  /**
   * Method to make an Online Payment
   * @param body :any
   */
  public OnlinePayment(body) {
    return this.commonApiService.post(`${this.testURL}/chola/hfc/OnlinePayment`, body)
      .pipe(map((response: Response) => response));
  }// public OnlinePayment(body)


  /**
   * Create Pmay form based on the request object
   * @param Body:any
   */
  public createPmayForm(Body) {
    const additionalHeaders = { 'x-uuid': this.uuid, 'x-access-token': this.token };
    const body = JSON.stringify({
      "purposeLoan": Body.loanPurpose || ' ',
      "clss": Body.clss || ' ',
      "name": Body.name || ' ',
      "sex": Body.gender || ' ',
      "fdname": Body.fdName || ' ',
      "dob": Body.dob || ' ',
      "mobile": Body.mobile || ' ',
      "aadhaarNo": Body.aadhaar || ' ',
      "panNo": Body.pan || ' ',
      "presentAddress1": Body.presentAddress1 || ' ',
      "presentAddress2": Body.presentAddress2 || ' ',
      "presentpincode": Body.presentpincode || ' ',
      "presentcity": Body.presentcity || ' ',
      "colPropertyAddress1": Body.colPropertyAddress1 || ' ',
      "colPropertyAddress2": Body.colPropertyAddress2 || ' ',
      "colPropertycity": Body.colPropertycity || ' ',
      "colPropertypostcode": Body.colPropertypostcode || ' ',
      "ownerName": Body.ownerName || ' ',
      "typeOfHouse": Body.typeOfHouse || ' ',
      "carpetArea": Body.carpetArea || ' ',
      "religion": Body.religion || ' ',
      "noofYearsCity": Body.yearsInCity || ' ',
      "familyHouse": Body.familHouse || ' ',
      "empStatus": Body.empStatus || ' ',
      "familyBpl": Body.familyBpl || ' ',
      "loanRequirement": Body.loanRequirement || ' ',
      "application_id": Body.application_id || ' '
    });
    return this.commonApiService.post(`${this.testURL}/upload/pdfgeneration?formType=PMAY`, body, null, additionalHeaders)
      .pipe(map((response: Response) => response));
  }// public createPmayForm(Body)


  /**
   * Genrate PDF based on the parameter passed in
   * @param html :any
   * @param ver :any
   * @param category:any
   * @param app_id :any
   * @param user_name :any
   * @param subcategory :any
   */
  public GeneratePDF(html, ver, category, app_id, user_name, subcategory) {
    const body = JSON.stringify({
      upload_data: {
        template_data: html, // template to be generated
        version: ver, // any version no or 0(zero)
        category: category, // doc category
        application_id: app_id,
        uploadedby: user_name,
        refno: 0,
        subcategory: "PDF_GENERATION",
        categoryType: "NA"
      }
    });
    return this.commonApiService.post(`${this.baseURL}/pdfgenerate/generatePDF`, body)
      .pipe(map((response: Response) => response));
  }// public GeneratePDF(html, ver, category, app_id, user_name, subcategory)


  /**
   * Add Mandatory Validations
   * @param applicationId:any
   * @description - To add mandatory check to all application id's
   */
  public addMandatoryValidations(application_id) {
    const details = JSON.stringify({
      application_id
    });
    return this.commonApiService.post(`${this.baseURL}/validations/addMandatoryValidations`, details)
      .pipe(map((response: Response) => response));
  }// public addMandatoryValidations(application_id)

  /**
   * Execute validation and update madatory Validations
   * @param requestData :Object
   */
  public executeValidation(requestData) {
    const body = JSON.stringify(requestData.request);
    const req = this.commonApiService.post(`${this.baseURL}/validations/updateMandatoryValidations`, body)
      .pipe(map((response: Response) => response));
    req.subscribe(res => {
      const sub = requestData.subscription;
      sub.next(res);
      this.validationRequestQueue.shift();
      this.startNextValidationRequest();
    });
  }// public executeValidation(requestData)


    /**
   * Get setup config based on the category and subcategory
   * @param setupCategory :any
   * @param setupSubCategory :any
   */
    public getSetupConfigV2(data) {
      data.map(item => {
        delete item['mappedKeys'];
      })
      const variables = {
        "count": false,
        "configs": data,
        "status": [
          "Published"
        ]
      }
    return this._apollo.use('lookupAPIClient').query<any>({
    query:gql`
    query ($status: [String], $lookupId: String, $version: String,$configs:[JSONObject]) {
      lookupConfigsDetail(status: $status, lookupId: $lookupId, version: $version,configs:$configs) {
        data {
          setupCategory
          setupSubCategory
          setupDescription
          lookupId
          persona
          status
          modifiedTS
          version
          fields
        }
        count
      }
    }`,
      variables:variables,
      fetchPolicy:'no-cache'
    }).toPromise();
  }

  /**
   * Get setup config based on the category and subcategory
   * @param setupCategory :any
   * @param setupSubCategory :any
   */
  public getSetupConfig(setupCategory, setupSubCategory) {
    return this.commonApiService.get(`${this.adminUrl}/component/setup_config/${setupCategory}/${setupSubCategory}`)
      .pipe(map((response) => {
        return response;
      }));
  }// public getSetupConfig(setupCategory, setupSubCategory)

  /**
   * Enumerate SetupConfigDetails like themes etc..
   * @param argCategory :string
   * @param argSubcategory :string
   */
  public getSetupConfigDetails(argCategory: string, argSubcategory: string): Observable<any> {
    return this.commonApiService.get(`${this.adminUrl}/setup/configs/${argCategory}/${argSubcategory}`)
      .pipe(map((response) => response));
  }// getSetupConfigDetails(argCategory, argSubcategory)

  getAllSetupConfigs(skipFields?: any, skipTypes?: string[], argAllowedTypes?: string[], setupCategoryType?: string, setupCategory?: string) {
    if (!skipFields) {
      skipFields = [];
    }
    if (!skipTypes) {
      skipTypes = [];
    }
    let setupConfigUrl = `${this.baseURL}/v3/component/setup_configs?skipFields=${skipFields}&skipTypes=${skipTypes}`;
    if (argAllowedTypes) {
      setupConfigUrl += `&allowedTypes=${argAllowedTypes}`;
    }
    if (setupCategoryType) {
      setupConfigUrl += `&setupCategoryType=${setupCategoryType}`;
    }
    if (setupCategory) {
      setupConfigUrl += `&setupCategory=${setupCategory}`;
    }
    return this.commonApiService.get(setupConfigUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * Execute Decision Engine based on the applicationId and ConfigId
   * @param application_id :string
   * @param configId :string
   */
  public executeDecisionEngine(application_id: string, configId: string) {
    return this.commonApiService.get(`${this.decisionEngineUrl}/decision_engine/execute/${application_id}/${configId}`)
      .pipe(map((response) => response));
  }// public executeDecisionEngine(application_id: string, configId: string)

  public getApplications(body): Observable<any> {
    const backend_url = configs.clientUrl;
    return this.commonApiService.post(`${backend_url}/${body.url}`, body.query)
      .pipe(map(response => response));
  }// public getApplications(body): Observable<any>


  /**
   * copy application for debugging
   * @param application_id :string
   */
  public copyApplicationForDebug(application_id: string) {
    return this.commonApiService.get(`${this.baseURL}/v3/application/copy/${application_id}`)
      .pipe(map((response) => response));
  }// public copyApplicationForDebug(application_id: string)

  /**
   * Get firlds translation data
   * @param flowId :string
   */
  getFieldsTranslatedData(flowId: any) {
    const routeinfoBody = {
      flowId: flowId,
      componentId: { $exists: true },
      type: "fields"
    };
    return this.commonApiService.post(`${this.adminUrl}/v3/translation/fetchData`, routeinfoBody)
      .pipe(map((response) => { console.log(response); return response; }));
  }

  /**
   * Get Translation data for Component/tabs
   * @param flowId :string
   */
  getComponentsTranslatedData(flowId: any) {
    const routeinfoBody = {
      flowId: flowId,
      type: "components"
    };
    return this.commonApiService.post(`${this.adminUrl}/v3/translation/fetchData`, routeinfoBody)
      .pipe(map((response) => response));
  }

  public getAllApplicantDetails() {
    return new Promise((resolve) => {
      const currentModel = _.cloneDeep(this.getPersonalDetailsObject());
      let primaryModel = _.cloneDeep(this.getPersonalDetailsObject());
      let jointModel = _.cloneDeep(this.getJointPersonalDetailsObject());
      this.totalApplicants = _.concat(primaryModel, jointModel);
      resolve(true);
    });
  }// public getAllApplicantDetails()

  async validateSubmitData(form) {
    if (form.controls['fname']) {
      this.totalApplicants = [];
      let currentSection = this.getBackStatus();
      await this.getAllApplicantDetails();
      switch (this.currentApplicant.applicantType) {
        case AppConstants.PRIMARY_APPLICANT:
          this.totalApplicants.splice(0, 1);
          // console.log('gng inside primary applicant');
          break;

        case AppConstants.SECONDARY_APPLICANT:
          let coApplicantIndex = this.currentApplicant.selectedCOApplicantIndex;
          if (!coApplicantIndex) {
            coApplicantIndex = 0;
          }
          this.totalApplicants.splice(coApplicantIndex + 1, 1);
      }

      this.totalApplicants = _.filter(this.totalApplicants, (v) => {
        return !_.isEmpty(v.fname);
      });
      // this.totalApplicants = _.reject(this.totalApplicants,(v)=>{
      //     console.log('reject..',currentSection['subSection'],v.sub_section_id);
      //     return _.isEqual(v.sub_section_id,currentSection['subSection'])
      // });
      console.log('total applicant after filter in validate', this.totalApplicants);
      if (this.totalApplicants.length) {
        // let isInvalidData = this.validateData(this.totalApplicants, form);
        // if (isInvalidData) {
        //     this.backStageService.notification('error', applicationMsg['messages']['duplicateData']);
        //     return true;
        // } else {
        //     return false;
        // }
      } else {
        return false;
      }
    } else {
      return false;
    }

  }//  async validateSubmitData(form)

  getDomainUrl() {
    const endPoint = `${this.adminUrl}/setup/configs/Environment/Domain`;
    return this.commonApiService.get(endPoint)
      .pipe(map((response) => {
        this.domainConfig = response;
        return response;
      }));
  }

  /**
   * Get Group based on the LogedIn user Accesstoken
   */
  public getGroupDetailByGroupName(assignedTeams) {
    const reqUrl = `${this.baseURL}/v3/users/getGroupDetailByGroupName`;
    const data = {
      team: assignedTeams,
      token: this.userService.accessToken
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(reqUrl, body)
      .pipe(map((response: any) => response));
  }// public getGroupDetailByGroupName()

  public removeFile(fileId, application_id) {
    console.log("data received", fileId, application_id);
    const url = `${this.baseURL}/v3/remove/?fileId=${fileId}&application_id=${application_id}`;
    return this.commonApiService.delete(url);
  }

    /**
   * @description reset table data of all the sections of given template
   * @param templateId 
   * @param fieldkey 
   * @param application_id 
   * @returns 
   */
     async resetTemplateData(templateId,fieldkey,application_id){
      try {
        const response = await this._multiDataService.resetTemplateData(templateId,fieldkey,application_id);
        if(response && response.data){
          return response.data[fieldkey];
        }
      } catch(error){
          console.log(error,'error');
      }
    }

    getField(fieldKey: string) {
      const fieldDetails = this.fieldsArray.find(field=>field.key==fieldKey);
      return fieldDetails;
    }

    /**
     * function to decrypt fields in fields array
     * @param subSectionFields 
     * @returns 
     */
    formatFields(subSectionFields: any){
      let fieldsList: any = [];
      subSectionFields?.forEach(subSectionField=>{
          fieldsList.push({fieldId:subSectionField?.fieldId, field_type_details: {type:"element_row" },enable:true});
        subSectionField?.fields?.forEach(columnField=>{
          fieldsList.push({fieldId:columnField?.fieldId, field_type_details: {type:"element_column", displayConfigs: columnField?.field_type_details?.displayConfigs},enable:true});
          if(columnField.fields?.length>0) {
            const groupFields: any = columnField.fields.filter((field: any) => field?.groupKey && field?.field_identifier === 'G') || [];
            groupFields?.length ? groupFields.forEach((field: any) => fieldsList = [...fieldsList, ...field?.fields]) : '';
            fieldsList = [...fieldsList, ...columnField.fields];
          }
        })
      })
      return fieldsList;
    }
}
