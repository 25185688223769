<ngx-spinner size="medium" [type]="'ball-atom'" color = "#fff" bdColor = "rgba(0, 0, 0, 0.8)"></ngx-spinner>

<img style="height: 100%;width: 100%;" [src]="imageUrl" alt="resume-screen" *ngIf="imageUrl && imageUrl!='' && backstageServiceComponent.loading">

<!-- Application Common Model -->
<div class="vg-modal">
    <ng-template #appCommonModal let-closeModal="close">
        <div class="vg-modal-close">
            <span [hidden]="!applicationModal.showCloseBtn" (click)="close()" class="icon-Close1"
                aria-hidden="true"></span>
        </div>
        <div *ngIf="applicationModal.title.show" class="modal-header">
            <div class="vg-modal-header">
                <p class="vg-modal-header__title">{{applicationModal.title.text}}</p>
            </div>
        </div>
        <div class="modal-body">
            <div class="application-modal-container">
                <table>
                    <ng-container *ngFor="let entry of applicationModal.entries">
                        <ng-container *ngIf="entry.type === 'error'">
                            <p class="application-modal-container__entry">
                                <span class="application-modal-container__entry__icon">
                                    <span class="application-modal-container__entry__icon--errors icon-Close11"></span>
                                </span>
                                <span class="application-modal-container__entry__desc">{{entry.description}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="entry.type === 'keyvalue'">
                            <tr>

                                <td>
                                    <p class="application-modal-container__entry">
                                        <span class="application-modal-container__entry__desc">{{entry.key}}</span></p>
                                </td>
                                <td>
                                    <p class="application-modal-container__entry">
                                        <span class="application-modal-container__entry__desc--value">{{entry.value}}</span>
                                    </p>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </div>
        <!-- <div class="modal-footer">
        <ng-content select=".vg-modal-footer"></ng-content>
      </div> -->
    </ng-template>
</div>

<ion-app>
  <router-outlet></router-outlet>
</ion-app>
