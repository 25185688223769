import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APP_MODES } from "@vanguard/core/models/app-modes";
import { UserService } from "@vanguard/shared/services/user.service";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import { ToasterService } from "../services";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { BackendService } from "../services/backend.service";
import { CommonhttpService } from "../services/commonhttp.service";
import { environment as configs } from "environments/environment";
import sha256 from "crypto-js/sha256.js";
import ENC from "crypto-js/enc-hex.js";
import * as forge from "node-forge";
import { JSEncrypt } from 'jsencrypt';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public userService: UserService,
    private toasterService: ToasterService,
    private router: Router,
    private backendService: BackendService,
    private commonApiService: CommonhttpService
  ) {}

  // Below method encrypts the data with rsa public pem and retruns encrypted data in hexa format
  encryptInitializeVector(data) {
    const rsaKey = localStorage.getItem("_rk");
    const rsa_pub = forge.pki.publicKeyFromPem(rsaKey);
    const encryptedData = rsa_pub.encrypt(data);
    return forge.util.bytesToHex(encryptedData);
  }

  encryptInitializeVectorV2(data) {
    const rsaKey:any = localStorage.getItem('_rk');
    const crypt = new JSEncrypt();
    crypt.setKey(rsaKey); 
    let cipherKey = crypt.encrypt(data);
    return cipherKey;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth headers
    if (!this.commonApiService.validateExcludeHeadersRoutes(request.url)) {
      if (!request.url.includes("auth/logout") && !request.url.includes("api.ipify.org/?format=json") && !request.url.includes("vpnapi.io/api")) {
        request = request.clone({
          headers: request.headers.set("access_token", `${this.userService.accessToken}`),
        });
        request = request.clone({
          headers: request.headers.set("sessionId", `${this.userService.sessionId}`),
        });
        if (configs.encryptRequest && !this.commonApiService.validateRoutes(request.url) && localStorage.getItem("_sk")) {
          request = request.clone({
            headers: request.headers.set("x-sk", `${this.encryptInitializeVectorV2(localStorage.getItem("_sk"))}`),
          });
        }
      }
      // add app ref id header
      if (this.userService.appRefId && !request.url.includes("api.ipify.org/?format=json") && !request.url.includes("vpnapi.io/api")) {
        request = request.clone({
          headers: request.headers.set("appRefId", `${this.userService.appRefId}`),
        });
      }
      if (request.method === 'GET' && !request.url.includes("api.ipify.org/?format=json") && !request.url.includes("vpnapi.io/api")) {
        request = request.clone({ headers: request.headers.set('Cache-Control', `no-cache`) });
      }
      if (request.url.includes("v2/graphql")) {
        request = request.clone({
          headers: request.headers.set("entityId", localStorage.getItem("entityId") || "default"),
        });
      }
      // add content type headers
      if (request.method === "PUT" || request.method === "POST") {
        const _ch = this.generateChecksum(request.body);
        request = request.clone({
          headers: request.headers.set("x-signature", _ch),
        });
        if (!request.url.includes("v4/documents/upload")) {
          request = request.clone({
            headers: request.headers.set("Content-Type", "application/json"),
          });
        }
        request = request.clone({
          headers: request.headers.set("Accept", "application/json"),
        });
      }
      if (configs.encryptRequest && !this.commonApiService.validateRoutes(request.url) && request.body) {
        request = request.clone({
          body: this.commonApiService.encryptDataV2(request.body),
        });
        request = request.clone({
          headers: request.headers.set("Content-Type", "text/plain"),
        });
      }
      if (request.url.includes("auth/logout")) {
        request = request.clone({
          headers: request.headers.set("refresh_token", `${this.userService.refreshToken}`),
        });
      }
      // check debug app mode
      const mode = sessionStorage.getItem("app_mode");
      if (mode === APP_MODES.DEBUG) {
        let pathname;
        if (request.url.startsWith("http")) {
          const url = new URL(request.url);
          pathname = url.pathname;
        } else {
          pathname = request.url;
        }
        if (!pathname.includes("adminAPI")) {
          pathname = "/debugger" + pathname;
          const formattedUrl = `${pathname}`;
          request = request.clone({
            url: formattedUrl,
          });
        }
      }
    }
    return this.handleRequest(request, next);
  }

  /**
   *
   * @param data : data you want to encrypt
   * @returns : encrypted value
   */
  private generateChecksum(data) {
    try {
      if (typeof data === "string") {
        return sha256(data).toString(ENC);
      } else {
        return sha256(JSON.stringify(data)).toString(ENC);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *
   * @param body : data to be encrypted
   * @param signature : response header x-signature value to validate
   * @returns : true if encrypted value matches response header x-signature value else false
   */
  private validateCheckSum(body, signature) {
    try {
      if (body.data) {
        const hashedValue = this.generateChecksum(JSON.stringify(body.data));
        if (hashedValue !== signature) {
          return false;
        }
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @description handle request
   * @param next Next handler
   * @param req Request object
   * @returns observable
   */
  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        if (!(event instanceof HttpResponse)) return event;
        const headers: HttpHeaders = event.headers;
        if (headers.has("x-signature") && (req.method == "PUT" || req.method == "POST")) {
          let result;
          if (req.url.includes("/v3/graphql")) {
            let obj = Object.create(null);
            obj = event.body;
            result = this.validateCheckSum(obj, headers.get("x-signature"));
          } else {
            if (!this.commonApiService.validateExcludeHeadersRoutes(req.url)) {
              result = this.validateCheckSum(event.body, headers.get("x-signature"));
            }
          }
          if (!result && !this.commonApiService.validateExcludeHeadersRoutes(req.url)) {
            this.toasterService.error("Sorry, the response is tampered or corrupted");
          }
        }
        if (configs.encryptRequest && !this.commonApiService.validateRoutes(req.url) && (event.status === 200 || event.status === 202) && 
          !this.commonApiService.validateExcludeHeadersRoutes(req.url)) {
          event = event.clone({
            body: this.commonApiService.decryptDataV2(event.body),
          });
          return event;
        }
        return event;
      }),
      catchError((err) => {
        if (configs.encryptRequest && !this.commonApiService.validateRoutes(req.url) && !this.commonApiService.validateExcludeHeadersRoutes(req.url)) {
          err = this.commonApiService.decryptDataV2(err.error);
        }
        if (!req.url.includes("login") && err.status === 401) {
          this.toasterService.error("Session expired. Please login again.");
          this.backendService.logout().subscribe((res) => {
            this.userService.clearTokenData();
            this.userService.clearUserData();
          });
          if (!this.router.url.startsWith("/newapplication")) {
            this.router.navigateByUrl("/");
          } else {
            this.backendService.getDomainUrl().subscribe((domainInfo) => {
              if (domainInfo && domainInfo[0] && domainInfo[0].customerportal) {
                const gotoUrl = domainInfo[0].customerportal.split("?");
                this.router.navigateByUrl(
                  "/newapplication?" + gotoUrl[gotoUrl.length - 1]
                );
              }
            });
          }
        }
        return throwError(err);
      })
    );
  }
}
