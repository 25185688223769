import { RuleEngineApi } from './rule-engine.api';
import { Injectable, Optional } from '@angular/core';
import { Engine } from 'json-rules-engine';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { PersonalDetailsObject } from '@vanguard/shared/interfaces/personalDetails.interface';
import { of, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { FieldKeyTypes } from '../derived-fields/models/field-key-types';
import { FieldSetupService } from '@vanguard/shared/services';
import { BackendService } from '@vanguard/shared/services/backend.service';
import 'rxjs/add/operator/debounceTime';
import { CommunicationService } from '@vanguard/shared/services';
import { Subject } from "rxjs";


const DEFAULT_CACHE_MAX_AGE = 300000; // Setting maximum time for data cache to five minutes

interface LookupInputOutputField {
  key: string;
  value: string;
  type: string;
}
interface LookupConfig {
  type: string;
  value: string;
  key: string;
  category: string;
  subCategory: string;
  inputFields: Array<LookupInputOutputField>;
  outputFields: Array<LookupInputOutputField>;
}

interface DataCache {
  data: any;
  expiry: number;
}

@Injectable({
  providedIn: 'root'
})
export class RuleEngineService {

  private ruleEngine: Engine;
  private rulesConfig = []; // Raw rules config from API
  private ruleList = []; // Rule order as per component
  private rulesMap = {}; // Map created between field key and corresponding rules
  private fieldsMap = {}; // Map created between fieldKey and their current properties
  private userModel:any = {};
  private initialEventPriorityList = {};
  private fieldsConfig;
  private valueChangeSubscriptions = [];
  private productParameters = [];
  private disableSetValueAction = false;
  private lookupConfigMap: Map<string, LookupConfig> = new Map<string, LookupConfig>();
  private dataCacheMap: Map<string, DataCache> = new Map<string, DataCache>();
  private asyncFacts: Array<string> = [];
  private documentSubsecription ;
  public compActionButtons = [
    {
      type: "back",
      value: "GO BACK",
      hide: false,
      disable: false,
      route: null
    },
    {
      type: "next",
      value: "CONTINUE",
      hide: false,
      disable: false,
      route: null
    },
    {
      type: "skip",
      value: "SKIP",
      hide: true,
      disable: false,
      route: null
    }
  ];

  constructor(private ruleEngineApi: RuleEngineApi, private fieldSetupService: FieldSetupService, public backendService: BackendService,public communicationService :CommunicationService) {
  }

  private addRule(rule) {
    this.ruleEngine = new Engine();
    this.ruleEngine.addRule(rule);
    this.addCustomOperators(this.ruleEngine);
  }

  private executeRule(rule, facts) {
    return new Promise((resolve, reject) => {
      const currentRule = _.cloneDeep(rule);
      const ruleFacts = _.cloneDeep(facts);
      this.formatCondition(currentRule, ruleFacts);
      this.addRule(currentRule);
      this.ruleEngine
        .run(ruleFacts)
        .then(events => resolve(events?.events?.[0]))
        .catch(e => reject(e));
    });
  }
  private formatCondition(rule, ruleFacts) {
    return this.formatDyanmicConditionValues(rule, ruleFacts);
  }

  private formatDyanmicConditionValues(rule, ruleFacts) {
    if (rule instanceof Object) {
      Object.keys(rule).forEach((key: string) => {
        if (key === 'value') {
          // If value is a dynamic key, then substitute it with facts value
          try {
            const parsedValue = typeof rule['value'] === 'string' ? JSON.parse(rule['value']) : rule['value'];
            if (typeof parsedValue === 'object' && parsedValue.key) {
              if (parsedValue.type === 'LOOKUP') {
                const lookupKey = this.frameLookupKey(parsedValue);
                rule['value'] = ruleFacts[lookupKey];
              } else {
                rule['value'] = ruleFacts[parsedValue.key];
              }
            }
            // eslint-disable-next-line no-empty
          } catch (e) { }
        } else if (rule[key] instanceof Object) {
          this.formatDyanmicConditionValues(rule[key], ruleFacts);
        } else if (rule[key] instanceof Array) {
          this.formatDyanmicConditionValues(rule[key], ruleFacts);
        }
      });
    } else if (rule instanceof Array) {
      rule.forEach(condition => {
        if (condition instanceof Object) {
          this.formatDyanmicConditionValues(condition, ruleFacts);
        }
      });
    }
  }

  public setRulesConfig(rulesConfig) {
    this.rulesConfig = _.cloneDeep(rulesConfig);
  }

  public updateDocumentAtModelAtLevel(){
    if (this.userModel && this.userModel["filesUpload"] && Array.isArray(this.userModel["filesUpload"])) {
      for (const file of this.userModel["filesUpload"]) {
          if (file.isDeleted) {
              continue;
          }
          this.userModel[`DOCUMENT#${file.type}`] = true;
      }
    }
    if (this.userModel && this.userModel["documents"] && Array.isArray(this.userModel["documents"])) {
      let checkFile: any;
      for (const document of this.userModel["documents"]) {
          checkFile = document.files.find((file: { deleted: boolean; }) => {
              return file.deleted === false;
          });
        if (checkFile) {
          this.userModel[`DOCUMENT#${document.type}`] = true;
          
        }
      }
    }
}


  public initializeRules(ruleList, form, model: PersonalDetailsObject, fieldsConfig?, productParameters?, userDetails?, disableValueAction?) {
    // Clear fields map
    this.clearFieldsMap();

    // Clear rules map
    this.clearRulesMap();

    // Reset value change subscrptions
    this.valueChangeSubscriptions = [];

    this.ruleList = ruleList?.map(data => data.configId);

    // Product parameters
    this.productParameters = productParameters ? productParameters : [];
    this.userDetails = userDetails ? userDetails : {};

    // Update disableSetValueAcction based on parameter
    this.disableSetValueAction = disableValueAction ? disableValueAction : this.disableSetValueAction;

    if (this.ruleList && this.ruleList.length > 0) {
      // Exclude rules which are disabled
      this.excludeDisabledRules();

      // Assign user model to the current value passed
      if (model) {
        this.userModel = _.cloneDeep(model);
        this.updateDocumentAtModelAtLevel();
      }

      // Assign formservice passed to local variable
      if (fieldsConfig) {
        this.fieldsConfig = fieldsConfig;
      }

      // Get all rule details from database
      this.generateRulesMap(form);
      // Subscribe to File Event Add / Delete and re-run the rules dependent of the file
      // Subscribe to File Event Add / Delete and re-run the rules dependent of the file
      this.ruleEngineApi.getFileAction().subscribe(fileAction => {
        if(fileAction.type){
          const documentFact = `DOCUMENT#${fileAction.type}`;
          this.userModel[`DOCUMENT#${fileAction.type}`] = fileAction.showData;
          this.executeFileSubscription(documentFact,form);
        }
      })

    }
  }

  private excludeDisabledRules() {
    const formattedRuleConfig = [];
    for (const rule of this.rulesConfig) {
      if (rule && rule.hasOwnProperty('isEnabled')) {
        if (rule.isEnabled) {
          formattedRuleConfig.push(rule);
        }
      } else {
        formattedRuleConfig.push(rule);
      }
    }

    // Assign formattedRuleConfig to actual rulesConfig
    this.rulesConfig = formattedRuleConfig;

    // Get all the rule id from rulesConfig;
    const rulesConfigIds = this.rulesConfig.map((rule) => rule.configId);

    // Remove all rule id's from ruleList which is not present in rulesConfigIds (enabled rules)
    // We cannot directly take from rulesConfig since actual ruleList order need to be maintained
    const formattedRuleList = [];
    for (const ruleId of this.ruleList) {
      if (rulesConfigIds.includes(ruleId)) {
        formattedRuleList.push(ruleId);
      }
    }
    this.ruleList = formattedRuleList;
  }

  private addCustomOperators(ruleEngine) {
    // Check if fact contains a string
    ruleEngine.addOperator('contains', (factValue, jsonValue) => {
      if (typeof factValue !== 'string') {
        factValue = factValue + '';
      }
      if (typeof jsonValue !== 'string') {
        jsonValue = jsonValue + '';
      }
      if (factValue && jsonValue) {
        return factValue.includes(jsonValue);
      }
      return false;
    });
    // Check if fact doesnt contain a string
    ruleEngine.addOperator('doesnotContain', (factValue, jsonValue) => {
      if (typeof factValue !== 'string') {
        factValue = factValue + '';
      }
      if (typeof jsonValue !== 'string') {
        jsonValue = jsonValue + '';
      }
      if (factValue && jsonValue) {
        return !factValue.includes(jsonValue);
      }
      return false;
    });
  }

  // Rules will be resolved on component page load
  public getRuleDetails(ruleConfigs) {
    if (ruleConfigs && ruleConfigs.length > 0) {
      // const ruleConfigString = ruleConfigs.join(',');
      return this.ruleEngineApi.getRuleDetails(ruleConfigs);
    } else {
      return of(true);
    }
  }

  private executeFileSubscription(fact,form){
    // Initialize facts list and factRules map
    const factsList = [];
    const factRules = {};
    // Loop through each rule
    for (const ruleId of Object.keys(this.rulesMap)) {
      // Push all rule facts to facts list
      for (const fact of this.rulesMap[ruleId].facts) {
        if (!factsList.includes(fact)) {
          factsList.push(fact);
        }
        if (!factRules[fact]) {
          factRules[fact] = [];
        }
        if (!factRules[fact].includes(ruleId)) {
          factRules[fact].push(ruleId);
        }
      }
    }

   // Initialize event priority list
   const eventPriorityList = {};
   // Find all the rules which has the fact
   const matchingRules = factRules[fact]?factRules[fact]:null;
   if(matchingRules){
   const promiseList = [];
   for (const ruleId of matchingRules) {
     promiseList.push(new Promise((resolve) => {
       this.populateFacts(ruleId, form, this.productParameters, this.userDetails).then(facts => {
         // After populating facts, since value modified execute rule
         this.executeRule(this.rulesMap[ruleId].rule, facts).then((event) => {
           if (event !== 'All facts are empty') {
             const ruleOrder = this.ruleList.indexOf(ruleId);
             this.createEvent(form, ruleId, event, eventPriorityList, ruleOrder);
             resolve(true);
           } else {
             resolve(true);
           }
         });
       });
     }));
   }
   Promise.all(promiseList).then(() => {
     // Execute events populated previously based on priority
     this.handleEventPriorityList(eventPriorityList, form).then(()=>{
       //this.communicationService.postRuleExecute(true);
     });
   });
  }
 }


  private generateRulesMap(form) {
    if (this.ruleList.length > 0) {
      this.formatRules(this.rulesConfig);
      const tempRulesMap = this.arrayToObject(this.rulesConfig, 'configId');
      const promiseList = [];
      for (const ruleId of this.ruleList) {
        promiseList.push(new Promise((resolve) => {
          // Populate facts object required for the rule
          if (tempRulesMap[ruleId] && tempRulesMap[ruleId].hasOwnProperty('rule')) {

            // Find all facts in the rule
            const facts = [];
            this.findFacts(tempRulesMap[ruleId], facts);

            this.rulesMap[ruleId] = {};
            this.rulesMap[ruleId]['rule'] = tempRulesMap[ruleId]['rule'];
            this.rulesMap[ruleId]['facts'] = facts;
            this.rulesMap[ruleId]['rulePriority'] = tempRulesMap[ruleId]['rule_priority'];

            // Execute rule once on initialization
            this.populateFacts(ruleId, form, this.productParameters, this.userDetails).then(factsvalue => {
              // Initialize event priority list
              this.initialEventPriorityList = {};
              this.executeRule(this.rulesMap[ruleId].rule, factsvalue).then((event) => {
                if (event !== 'All facts are empty') {
                  const ruleOrder = this.ruleList.indexOf(ruleId);
                  this.createEvent(form, ruleId, event, this.initialEventPriorityList, ruleOrder, true);
                  resolve(true);
                } else {
                  resolve(true);
                }
              });
            });
          } else {
            resolve(true);
          }
        }));
      }
      Promise.all(promiseList).then(() => {
        // Execute events populated previously based on priority
        this.handleEventPriorityList(this.initialEventPriorityList, form, true).then(() => {
          // Create change listeneres for all fields having rules
          this.createChangeListeners(form);
        });
      });
    }
  }

  private findFacts(rule, facts) {	
    if (rule instanceof Object && !Array.isArray(rule)) {	
      Object.keys(rule).forEach(key => {	
        if (key === 'fact') {	
          // Check if fact is a lookup field	
          if (this.lookupConfigMap.get(rule['fact'])) {	
            // If yes then add input fields as facts	
            const asyncFacts = [...this.lookupConfigMap.get(rule['fact']).inputFields.map(field => field.key)];	
            this.addAsyncFacts(asyncFacts);	
            facts.push(...[...asyncFacts, rule['fact']]);	
          } else {	
            facts.push(rule['fact']);	
          }	
        } else if (key === 'value') {	
          // If value itself is a dynamic key, then add it to the rule facts	
          try {	
            const parsedValue = rule['value'] === 'string' ? JSON.parse(rule['value']) : rule['value'];	
            if (typeof parsedValue === 'object') {	
              if (parsedValue.type === 'LOOKUP') {	
                const lookupKey = this.frameLookupKey(parsedValue);	
                this.lookupConfigMap.set(lookupKey, parsedValue);	
                const lookupInputFields = parsedValue.inputFields;	
                const asyncFacts = [...lookupInputFields.map(field => field.key)];	
                this.addAsyncFacts(asyncFacts);	
                facts.push(...[...asyncFacts, lookupKey]);	
              } else {	
                facts.push(parsedValue.key);	
              }	
            }	
            // eslint-disable-next-line no-empty	
          } catch (e) { }	
        } else if (rule[key] instanceof Object && !Array.isArray(rule[key])) {	
          this.findFacts(rule[key], facts);	
        } else if (Array.isArray(rule[key])) {	
          this.findFacts(rule[key], facts);	
        }	
      });	
    } else if (Array.isArray(rule)) {	
      rule.forEach(condition => {	
        if (condition instanceof Object && !Array.isArray(condition)) {	
          this.findFacts(condition, facts);	
        }	
      });	
    }	
  }

  private createChangeListeners(form) {
    let factSubscriptionFormControl;
    let factSubscriptionMultiRecord;
    // Initialize facts list and factRules map
    const factsList = [];
    const factRules = {};
    // Loop through each rule
    for (const ruleId of Object.keys(this.rulesMap)) {
      // Push all rule facts to facts list
      for (const fact of this.rulesMap[ruleId].facts) {
        if (!factsList.includes(fact)) {
          factsList.push(fact);
        }
        if (!factRules[fact]) {
          factRules[fact] = [];
        }
        if (!factRules[fact].includes(ruleId)) {
          factRules[fact].push(ruleId);
        }
      }
    }

    // Loop through each fact in factslist
    for (const fact of factsList) {
      if (form.controls.hasOwnProperty(fact)) {
        // When value of a fact field changes, then execute rule related to that
        factSubscriptionFormControl = form.controls[fact].valueChanges.distinctUntilChanged().debounceTime(this.isFactAsync(fact) ? 400 : 0).subscribe(() => {
          // Initialize event priority list
          const eventPriorityList = {};
          // Find all the rules which has the fact
          const matchingRules = factRules[fact];
          const promiseList = [];
          for (const ruleId of matchingRules) {
            promiseList.push(new Promise((resolve) => {
              this.populateFacts(ruleId, form, this.productParameters, this.userDetails).then(facts => {
                // After populating facts, since value modified execute rule
                this.executeRule(this.rulesMap[ruleId].rule, facts).then((event) => {
                  if (event !== 'All facts are empty') {
                    const ruleOrder = this.ruleList.indexOf(ruleId);
                    this.createEvent(form, ruleId, event, eventPriorityList, ruleOrder);
                    resolve(true);
                  } else {
                    resolve(true);
                  }
                });
              });
            }));
          }
          Promise.all(promiseList).then(() => {
            // Execute events populated previously based on priority
            this.handleEventPriorityList(eventPriorityList, form).then();
          });
        });
        this.valueChangeSubscriptions.push(factSubscriptionFormControl);
      } else {
        const keyParts = fact && fact.split('__');
        if (keyParts && keyParts.length > 2) {
          const containerKey = keyParts[0];
          const keyType = keyParts[1].toUpperCase();
          const fieldKey = keyParts[2];
          switch (keyType) {
            case FieldKeyTypes.TOTAL:
              if (form.controls.hasOwnProperty(containerKey)) {
                factSubscriptionMultiRecord = form.controls[containerKey]
                ['controls']['total']['controls']
                [fieldKey].valueChanges.subscribe(() => {
                  // Initialize event priority list
                  const eventPriorityList = {};
                  // Find all the rules which has the fact
                  const matchingRules = factRules[fact];
                  // Sort rules based on order sepcified in the component (TBD)

                  const promiseList = [];
                  for (const ruleId of matchingRules) {
                    promiseList.push(new Promise((resolve) => {
                      this.populateFacts(ruleId, form, this.productParameters, this.userDetails).then(facts => {
                        // After populating facts, since value modified execute rule
                        this.executeRule(this.rulesMap[ruleId].rule, facts).then((event) => {
                          if (event !== 'All facts are empty') {
                            const ruleOrder = this.ruleList.indexOf(ruleId);
                            this.createEvent(form, ruleId, event, eventPriorityList, ruleOrder);
                            resolve(true);
                          } else {
                            resolve(true);
                          }
                        });
                      });
                    }));
                  }
                  Promise.all(promiseList).then(() => {
                    // Execute events populated previously based on priority
                    this.handleEventPriorityList(eventPriorityList, form).then();
                  });
                });
              }
              break;
          }
        }
        this.valueChangeSubscriptions.push(factSubscriptionMultiRecord);
      }
    }
  }

  private async populateFacts(ruleId, form: UntypedFormGroup, productParameters, userDetails) {
    // Populate facts required for the rule from form, if not availbale then from modal
    const facts = {};

    for (const fact of this.rulesMap[ruleId].facts) {
      // Check in product parameter
      const parameterValue = this.getProductParameterValue(fact, productParameters);
      const userPropertyValue = this.getUserPropertyValue(fact, userDetails);
      const lookupValue = await this.getLookupValue(fact, form, this.userModel);
      if (form.controls.hasOwnProperty(fact)) { // Form control
        facts[fact] = form.controls[fact].value;
      } else if (this.userModel.hasOwnProperty(fact)) { // Model
        facts[fact] = this.userModel[fact];
      } else if (parameterValue !== null && parameterValue !== undefined) { // Product parameter
        facts[fact] = parameterValue;
      } else if (userPropertyValue !== null && userPropertyValue !== undefined) { // Product parameter
        facts[fact] = userPropertyValue;
      } else if (lookupValue !== null && lookupValue !== undefined) { // Lookup value
        facts[fact] = lookupValue;
      } else {
        const keyParts = fact && fact.split('__');
        if (keyParts && keyParts.length > 2) {
          const containerKey = keyParts[0];
          const keyType = keyParts[1].toUpperCase();
          const fieldKey = keyParts[2];
          switch (keyType) {
            case FieldKeyTypes.TOTAL:
              if (form.controls.hasOwnProperty(containerKey)) {
                facts[fact] = form.controls[containerKey]['controls']['total']['controls'][fieldKey].value;
              } else if (this.userModel.hasOwnProperty(containerKey)) {
                facts[fact] = this.userModel[fact]['total'][fieldKey];
              }
              break;
          }
        }
      }
      facts[fact] = this.formatFactValue(facts[fact]);
    }

    return facts;
  }

  private formatFactValue(fact) {
    if (fact === undefined) {
      fact = null;
    }
    if (this.isBoolean(fact)) {
      if (String(fact) == 'true') {
        return true;
      } else {
        return false;
      }
    } else if (this.isNumeric(fact)) {
      return +fact;
    } else if (this.isObjectValid(fact) && fact.type === "otp") {
      return this.formatFactValue(fact.isVerified);
    } else {
      try {
        const formattedValue = JSON.parse(fact);
        if (this.isTypeObject(formattedValue) || this.isTypeArray(formattedValue)) {
          return formattedValue;
        } else {
          return fact;
        }
      } catch (e) {
        return fact;
      }
    }
  }

  private createEvent(form, ruleId, event, eventPriorityList, ruleExecutionOrder, initialize?) {
    const rulePriority = this.rulesMap[ruleId]['rulePriority'] || 0;
    if (!event) {
      // Since rule is not passed, check for event condition and excute opposite of event
      const ruleEvent = this.rulesMap[ruleId].rule.event;
      if (ruleEvent.type === 'actions') {
        ruleEvent.params.forEach((action) => {
          if (action.type === 'hide' || action.type === 'show') {
            const eventId = `showHide${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: 'showHide',
              actionSubType: action.type,
              actionKey: action.key,
              ruleExecutionResult: false,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                this.showOrHideField(form, action.key, action.type, false, action.clearvalueonhide);
              }
            });
          } else if (action.type === 'value') {
            if (this.disableSetValueAction) {
              return;
            }
            const eventId = `${action.type}${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: action.type,
              actionKey: action.key,
              ruleExecutionResult: false,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                if (action.defaultValue !== undefined) {
                  this.setFieldValue(form, action.key, action.defaultValue);
                }
              }
            });
          } else if (action.type === 'enable' || action.type === 'disable') {
            const eventId = `enableDisable${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: 'enableDisable',
              actionSubType: action.type,
              actionKey: action.key,
              ruleExecutionResult: false,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                this.enableOrDisableField(form, action.key, action.type, false);
              }
            });
          } else if (action.type === 'formActionButton') {
            const eventId = `${action.type}${action.value.action}${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: action.type,
              actionSubType: action.value.action,
              actionKey: action.key,
              ruleExecutionResult: false,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                this.modifyFormActionButtons(action.key, action.value, false, initialize);
              }
            });
          }
        });
      }
    } else {
      if (event.type === 'actions') {
        event.params.forEach((action) => {
          if (action.type === 'hide' || action.type === 'show') {
            const eventId = `showHide${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: 'showHide',
              actionSubType: action.type,
              actionKey: action.key,
              ruleExecutionResult: true,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                this.showOrHideField(form, action.key, action.type, true, action.clearvalueonhide);
              }
            });
          } else if (action.type === 'value') {
            const eventId = `${action.type}${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: action.type,
              actionKey: action.key,
              ruleExecutionResult: true,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                if (action.setValue !== undefined) {
                  this.setFieldValue(form, action.key, action.setValue);
                }
              }
            });
          } else if (action.type === 'enable' || action.type === 'disable') {
            const eventId = `enableDisable${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: 'enableDisable',
              actionSubType: action.type,
              actionKey: action.key,
              ruleExecutionResult: true,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                this.enableOrDisableField(form, action.key, action.type, true);
              }
            });
          } else if (action.type === 'formActionButton') {
            const eventId = `${action.type}${action.value.action}${action.key}`;
            if (!eventPriorityList[eventId]) {
              eventPriorityList[eventId] = [];
            }
            eventPriorityList[eventId].push({
              ruleId: ruleId,
              actionType: action.type,
              actionSubType: action.value.action,
              actionKey: action.key,
              ruleExecutionResult: true,
              priority: rulePriority,
              order: ruleExecutionOrder,
              callbackFunction: () => {
                this.modifyFormActionButtons(action.key, action.value, true, initialize);
              }
            });
          }
        });
      }
    }
  }

  private modifyFormActionButtons(buttonType, buttonValue, eventValue, initialize?) {

    if (this.backendService && this.backendService.compActionButtons) {
      this.compActionButtons = this.backendService.compActionButtons;
    }

    if (initialize) {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          if (!this.fieldsMap[buttonType]) {
            this.fieldsMap[buttonType] = {};
          }
          this.fieldsMap[buttonType]['intialValue'] = { ...actionButton };
        }
      }
    }
    if (buttonValue.action === 'show') {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          // If condition is satisfied do the action specified in rule
          if (eventValue === true) {
            actionButton.hide = false;
          } else { // If not do the opposite action
            actionButton.hide = true;
          }
        }
      }
    } else if (buttonValue.action === 'hide') {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          // If condition is satisfied do the action specified in rule
          if (eventValue === true) {
            actionButton.hide = true;
          } else { // If not do the opposite action
            actionButton.hide = false;
          }
        }
      }
    } else if (buttonValue.action === 'enable') {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          // If condition is satisfied do the action specified in rule
          if (eventValue === true) {
            actionButton.disable = false;
          } else {// If not do the opposite action
            actionButton.disable = true;
          }
        }
      }
    } else if (buttonValue.action === 'disable') {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          // If condition is satisfied do the action specified in rule
          if (eventValue === true) {
            actionButton.disable = true;
          } else {// If not do the opposite action
            actionButton.disable = false;
          }
        }
      }
    } else if (buttonValue.action === 'value') {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          // If condition is satisfied do the action specified in rule
          if (eventValue === true) {
            actionButton.value = buttonValue.setValue;
          } else {// If not do the opposite action
            actionButton.value = this.fieldsMap[buttonType]['intialValue'].value;
          }
        }
      }
    } else if (buttonValue.action === 'route') {
      for (const actionButton of this.compActionButtons) {
        if (actionButton.type === buttonType) {
          // If condition is satisfied do the action specified in rule
          if (eventValue === true) {
            actionButton.route = buttonValue.component;
          } else {// If not null the value
            actionButton.route = null;
          }
        }
      }
    }
  }


  private setFieldValue(form: UntypedFormGroup, fieldKey, value) {
    // Initialize fieldsMap for fieldKey if it is empty
    this.fieldsMap[fieldKey] = !this.fieldsMap[fieldKey] ? {} : this.fieldsMap[fieldKey];

    if (value === null || value === undefined) {
      const intialValue = this.getInitialValue(fieldKey);
      if (!(this.getInitialValue(fieldKey) instanceof Error)) {
        return form.controls[fieldKey].setValue(intialValue);
      }
    }
    if (form.controls.hasOwnProperty(fieldKey)) {
      this.updateFieldValidity(form.controls[fieldKey]);
      return form.controls[fieldKey].setValue(value);
    }
  }

  private enableOrDisableField(form: UntypedFormGroup, fieldKey, type, value) {
    if ((type === 'enable' && value === false) || (type === 'disable' && value === true)) {
      if (form.controls.hasOwnProperty(fieldKey)) {
        form.controls[fieldKey].disable();
      }
    } else {
      if (form.controls.hasOwnProperty(fieldKey)) {
        form.controls[fieldKey].enable();
        form.controls[fieldKey].markAsPristine();
        form.controls[fieldKey].updateValueAndValidity();
      }
    }
  }

  private showOrHideField(form: UntypedFormGroup, fieldKey, type, value, clearvalueonhide) {
    // Initialize fieldsMap for fieldKey if it is empty
    this.fieldsMap[fieldKey] = !this.fieldsMap[fieldKey] ? {} : this.fieldsMap[fieldKey];
    // Hide field if below condition matches
    if ((type === 'show' && value === false) || (type === 'hide' && value === true)) {
      this.fieldsMap[fieldKey]['hide'] = true;
      // Disbale control so that it doesnt affect form validation
      if (form.controls.hasOwnProperty(fieldKey)) {
        if (clearvalueonhide) {
          const intialValue = this.getInitialValue(fieldKey);
          if (!(this.getInitialValue(fieldKey) instanceof Error)) {
            if(Array.isArray(form.controls[fieldKey].value) && form.controls[fieldKey].value[0] && form.controls[fieldKey].value[0].hasOwnProperty('templateId')){
              form.controls[fieldKey].value.forEach(async templateData=>{
               await this.backendService.resetTemplateData(templateData.templateId,fieldKey,this.userModel.application_id); //reset the table datas of given template id sections to empty array
               templateData.sections.forEach(section => {
                 section.tableData = [];
               })
               form.controls[fieldKey].setValue(form.controls[fieldKey].value);
               this.userModel[fieldKey] = form.controls[fieldKey].value;
              })
             } else {
               form.controls[fieldKey].setValue(intialValue);
             }
          }
        }
        if ((form.controls[fieldKey].value === '' || form.controls[fieldKey].value === null || form.controls[fieldKey].value === undefined || form.controls[fieldKey].value === false) ||
          (form.controls[fieldKey].value && typeof form.controls[fieldKey].value === 'object' && form.controls[fieldKey].value.hasOwnProperty('uploadedDocuments')) || (this.fieldSetupService.getField(fieldKey) && this.fieldSetupService.getField(fieldKey).field_type_details && this.fieldSetupService.getField(fieldKey).field_type_details.type == 'multi_data')) {//added last condition to check if it the field is multidata. if yes, no need to validate multidata since it is a hidden field
          form.controls[fieldKey].disable();
        }
      }
    } else {
      // Show field if condition fails
      this.fieldsMap[fieldKey]['hide'] = false;
      // Enable control and make it as Pristine
      if (form.controls.hasOwnProperty(fieldKey)) {
        form.controls[fieldKey].enable();
        form.controls[fieldKey].markAsPristine();
        form.controls[fieldKey].updateValueAndValidity();
      }
    }
  }

  private arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })))

  private isNumeric(num) {
    return !isNaN(parseFloat(num)) && isFinite(num);
  }

  private isBoolean(variable) {
    if (variable === true || variable === false || variable === "true" || variable === "false") {
      return true;
    }
    return false;
  }

  private isTypeArray(arr) {
    return typeof arr === "object" && Array.isArray(arr);
  }

  private isTypeObject(arr) {
    return typeof arr === "object" && !Array.isArray(arr);
  }

  private isArrayValid(arr) {
    return arr && this.isTypeArray(arr) && arr.length > 0;
  }

  private isObjectValid(obj) {
    return obj && this.isTypeObject(obj) && Object.keys(obj).length > 0;
  }

  public isFieldSectionHidden(fieldKey) {
    if(!this.fieldsMap.hasOwnProperty(fieldKey)){
      return null;
    }
    return this.fieldsMap.hasOwnProperty(fieldKey) && this.fieldsMap[fieldKey]['hide'];
  }

  public clearFieldsMap() {
    this.fieldsMap = {};
  }

  public clearRulesMap() {
    this.rulesMap = {};
  }

  private formatRules(ruleConfigs) {
    return this.ruleValueFormatter(ruleConfigs, null);
  }

  private ruleValueFormatter(rules, parent) {
    if (rules instanceof Object && !Array.isArray(rules)) {
      Object.keys(rules).forEach(key => {
        if (rules[key] instanceof Object && !Array.isArray(rules[key])) {
          this.ruleValueFormatter(rules[key], rules);
        } else if (Array.isArray(rules[key])) {
          if (key === 'any' || key === 'all') {
            // Remove empty any or all conditions created
            if (Array.isArray(rules[key]) && rules[key].length === 0) {
              if (Array.isArray(parent)) {
                const indexToRemove = parent.indexOf(rules);
                if (indexToRemove !== -1) {
                  parent.splice(indexToRemove, 1);
                }
              }
            }
          }
          this.ruleValueFormatter(rules[key], rules);
        } else {
          if (key === 'value') {
            rules['value'] = this.formatFactValue(rules['value']);
          }
          if (key === 'fact') {
            try {
              // Flow fields
              const parsedValue = JSON.parse(rules['fact']);
              if (typeof parsedValue === 'object') {
                if (parsedValue.type === 'LOOKUP') {
                  const lookupKey = this.frameLookupKey(parsedValue);
                  this.lookupConfigMap.set(lookupKey, parsedValue);
                  rules['fact'] = lookupKey;
                } else {
                  rules['fact'] = parsedValue.key;
                }
              }
              // eslint-disable-next-line no-empty
            } catch (e) { }
          }
        }
      });
    } else if (Array.isArray(rules)) {
      rules.forEach(rule => {
        this.ruleValueFormatter(rule, rules);
      });
    }
  }

  private getInitialValue(fieldKey) {
    try {
      let field;
      // used inside accordian to clear the value on hide
      if (Array.isArray(this.fieldsConfig) && this.fieldsConfig.length > 0) {
        field = this.fieldsConfig.find(f => f.key === fieldKey);
      }
      if ((Array.isArray(this.fieldsConfig) && this.fieldsConfig.length > 0 && field) || (this.fieldsConfig && this.fieldsConfig.hasOwnProperty(fieldKey))) {
        field = !Array.isArray(this.fieldsConfig) ? this.fieldsConfig[fieldKey] : field;
        const fb = new UntypedFormBuilder();
        const fieldDetail = this.fieldSetupService.getFormControl(field, new PersonalDetailsObject);
        if (fieldDetail instanceof UntypedFormGroup) {
          return fieldDetail.value;
        }
        const fieldFormControl: UntypedFormControl = fb.control(fieldDetail[0], fieldDetail[1]);
        return fieldFormControl.value;
      } else {
        return new Error('Field list empty');
      }
    } catch (e) {
      return e;
    }
  }

  private async handleEventPriorityList(eventPriorityList, form, initialize?) {
    if (initialize) {
      const changeEvents = Object.keys(eventPriorityList);
      if (changeEvents && Array.isArray(changeEvents) && changeEvents.length > 0) {
        // Sort change events based on rule order
        const changeEventsOrder = this.sortChangeEventsBasedOnOrder(changeEvents, eventPriorityList);
        for (const changeEvent of changeEventsOrder) {
          this.executeProrityEventList(changeEvent.events);
        }
      }
    } else {
      const changeEvents = Object.keys(eventPriorityList);
      if (changeEvents && Array.isArray(changeEvents) && changeEvents.length > 0) {
        // Sort change events based on rule order
        const changeEventsOrder = this.sortChangeEventsBasedOnOrder(changeEvents, eventPriorityList);
        for (const changeEventOrdered of changeEventsOrder) {
          const changeEventList = changeEventOrdered.events;
          const changeEventRules = changeEventList.map((event) => event.ruleId);
          const initialMatchingEvents = this.initialEventPriorityList[changeEventOrdered.changeEvent];
          if (initialMatchingEvents && Array.isArray(initialMatchingEvents) && initialMatchingEvents.length > 0) {
            const otherImpactingEvents = initialMatchingEvents.filter((event) => !changeEventRules.includes(event.ruleId));
            // Execute other impacting rules and find latest event
            if (otherImpactingEvents && Array.isArray(otherImpactingEvents) && otherImpactingEvents.length > 0) {
              const otherImpactingRules = otherImpactingEvents.map((event) => event.ruleId);
              for (const impactingRule of otherImpactingRules) {
                const facts = await this.populateFacts(impactingRule, form, this.productParameters, this.userDetails);
                // After populating facts, execute rule
                const event = await this.executeRule(this.rulesMap[impactingRule].rule, facts);
                if (event !== 'All facts are empty') {
                  const ruleOrder = this.ruleList.indexOf(impactingRule);
                  this.createEvent(form, impactingRule, event, eventPriorityList, ruleOrder);
                }
              }
            }
          }
          this.executeProrityEventList(eventPriorityList[changeEventOrdered.changeEvent]);
        }
      }
    }
  }
  userDetails(impactingRule: any, form: any, productParameters: any[], userDetails: any) {
    throw new Error("Method not implemented.");
  }

  private sortChangeEventsBasedOnOrder(changeEvents, eventPriorityList) {
    const changeEventsOrder = [];
    for (const changeEvent of changeEvents) {
      const changeEventList = eventPriorityList[changeEvent];
      const changeEventOrder = this.determineEventListOrder(changeEventList);
      changeEventsOrder.push({
        order: changeEventOrder,
        events: changeEventList,
        changeEvent: changeEvent
      });
    }
    // Sort change events based on order
    changeEventsOrder.sort((a, b) => {
      const orderA = a.order,
        orderB = b.order;
      // Compare the 2 orders
      if (orderA < orderB) { return -1; }
      if (orderA > orderB) { return 1; }
      return 0;
    });
    return changeEventsOrder;
  }

  private determineEventListOrder(eventList) {
    let order;
    if (eventList && Array.isArray(eventList) && eventList.length > 0) {
      order = eventList[0].order;
      for (const event of eventList) {
        if (event.order < order) {
          order = event.order;
        }
      }
    }
    return order;
  }

  private executeProrityEventList(eventsList) {
    if (eventsList && Array.isArray(eventsList) && eventsList.length > 0) {
      const passedEvents = eventsList.filter((event) => event.ruleExecutionResult === true);
      const failedEvents = eventsList.filter((event) => event.ruleExecutionResult === false);
      // If any one event is passed, it will be executed irrespective of priority
      if (passedEvents && Array.isArray(passedEvents) && passedEvents.length > 0) {
        // Execute highest priority passed event
        this.executeHighestPriorityEvent(passedEvents);
      } else {
        // Execute highest priority failed event
        this.executeHighestPriorityEvent(failedEvents);
      }
    }
  }

  private executeHighestPriorityEvent(eventsList) {
    if (eventsList && Array.isArray(eventsList) && eventsList.length > 0) {
      // Sort events based on priority
      eventsList.sort((a, b) => {
        const priorityA = a.priority,
          priorityB = b.priority;
        // Compare the 2 priority
        if (priorityA < priorityB) { return 1; }
        if (priorityA > priorityB) { return -1; }
        return 0;
      });
      // Execute highest priority failed event
      const highestPriorityEvent = eventsList[0];
      highestPriorityEvent.callbackFunction();
    }
  }

  updateFieldValidity(formControl) {
    try {
      formControl.markAsPristine();
      formControl.updateValueAndValidity();
    } catch (e) { }
  }

  unsubscribe() {
    if (this.valueChangeSubscriptions.length > 0) {
      this.valueChangeSubscriptions.forEach((subscription: Subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
    if(this.documentSubsecription){
      this.documentSubsecription.unsubscribe();
    }

  }

  getProductParameterValue(parameterKey, productParameters) {
    if(parameterKey && parameterKey.includes('DOCUMENT#')){
      let documentType: string = '';
      if(parameterKey.split('#').length>1){
        documentType = parameterKey.split('#')[1];
      }
      if(this.userModel && this.userModel.filesUpload && Array.isArray(this.userModel.filesUpload)){
        const documentIndex = this.userModel.filesUpload.findIndex(documentData=>{
          if(documentData.type == documentType && documentData.uploadedFilesCount){
            return true;
          }
        });
        if(documentIndex>=0){
          return true;
        } 
      }
      return null;
    } if (productParameters &&
      Array.isArray(productParameters) &&
      productParameters.length > 0) {
      const productParameter = productParameters.filter((parameter) => parameter.parameterKey === parameterKey);
      if (productParameter && Array.isArray(productParameter) && productParameter.length > 0) {
        return this.formatFactValue(productParameter[0]['parameterValue']);
      }
    }
    return null;
  }

  /**
   * get user properties facts values
   */
  getUserPropertyValue(propertyKey, userDetails) {
    const propKey = propertyKey && propertyKey.replace('user_', '');
    if (userDetails && userDetails[propKey]) {
      if (propKey === 'teams') {
        return this.formatFactValue(userDetails[propKey].map(team => team.name).join(','));
      }
      if (propKey === 'roles') {
        return this.formatFactValue(userDetails[propKey].join(','));
      }
      return this.formatFactValue(userDetails[propKey]);
    } else if (userDetails && propKey && propKey.includes('attributes')) {
      const attributeKeys = propKey && propKey.split('.');
      if (userDetails[attributeKeys[0]] && userDetails[attributeKeys[0]][attributeKeys[1]]) {
        return this.formatFactValue(userDetails[attributeKeys[0]][attributeKeys[1]].join(','));
      }
      return null;
    }
    return null;
  }

  async getLookupValue(fact: string, form, userModel) {
    if (fact && this.lookupConfigMap.get(fact)) {
      const lookupConfig = this.lookupConfigMap.get(fact);
      const lookupKey = lookupConfig.key;
      const inputFields = lookupConfig.inputFields;
      // Frame lookup query string
      let finalLookupQuery = "";
      for (const field of inputFields) {
        let value;
        if (form.controls.hasOwnProperty(field.key)) { // Form control
          value = form.controls[field.key].value;
        } else if (userModel.hasOwnProperty(field.key)) { // Model
          value = userModel[field.key];
        }
        finalLookupQuery += `${field.key}=${value}&&${field.key}__type=${field.type}&&`;
      }
      finalLookupQuery += `filter=${lookupKey}`;
      // Check if data is already is cached, if yes then return from cache
      const { isValidCacheAvailable, cachedData } = this.getDataCache(finalLookupQuery);
      if (isValidCacheAvailable) {
        return cachedData;
      } else {
        let finalLookupData = null;
        // If data not available/expired, then fetch data again from backend
        const lookupResult = await this.ruleEngineApi.getLookupData(lookupConfig.category, lookupConfig.subCategory, finalLookupQuery).toPromise();
        if (lookupResult && lookupResult['configs'] && Array.isArray(lookupResult['configs']) && lookupResult['configs'].length > 0) {
          const lookupData = lookupResult['configs'][0];
          if (lookupData[lookupKey]) {
            finalLookupData = lookupData[lookupKey];
          }
        }
        // Set resolved data to data cache, so that multiple api calls can be avoided
        this.setDataCache(finalLookupQuery, finalLookupData);
        return finalLookupData;
      }
    }
    return null;
  }

  private setDataCache(key, data) {
    this.dataCacheMap.set(key, {
      data,
      expiry: Date.now() + DEFAULT_CACHE_MAX_AGE
    });
  }

  private getDataCache(key) {
    let isValidCacheAvailable = false;
    const cachedData = this.dataCacheMap.get(key);
    if (cachedData) {
      if (cachedData.expiry > Date.now()) {
        isValidCacheAvailable = true;
        return { isValidCacheAvailable, cachedData: cachedData.data };
      } else {
        this.dataCacheMap.delete(key);
      }
    }
    return { isValidCacheAvailable };
  }

  private addAsyncFacts(facts) {
    for (const fact of facts) {
      if (!this.asyncFacts.includes(fact)) {
        this.asyncFacts.push(fact);
      }
    }
  }

  private isFactAsync(fact) {
    return this.asyncFacts.includes(fact);
  }

  private frameLookupKey(lookupConfig: LookupConfig) {
    return `${lookupConfig.category}#${lookupConfig.subCategory}#${lookupConfig.key}`;
  }
}
