import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PersonalDetailsObject } from '@vanguard/shared/interfaces/personalDetails.interface';
import { ModeTypes } from '@vanguard/shared/enums/modes.enum';
import { ElementThemes } from '@vanguard/themes/ui-elements/elementThemes.enum';
import { RuleEngineService } from '@vanguard/core/modules/rule-engine';
import { BackendService } from '@vanguard/shared/services/backend.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ui-elements',
  templateUrl: './ui-elements.component.html',
  styleUrls: ['./ui-elements.component.scss']
})
export class UiElementsComponent {
  @Input() public field: any;
  @Input() public form: UntypedFormGroup;
  @Input() public fieldService: any;
  @Input() public model: PersonalDetailsObject;
  @Input() public primaryModel: PersonalDetailsObject;
  @Input() public jointModel: PersonalDetailsObject;
  @Input() public currentApplicant: any;
  @Input() public theme: ElementThemes;
  @Input() public mode: ModeTypes;
  @Input() public configs: Map<string, Array<Object>>;
  @Input() public enableChildLinking: any;  // used for handling dropdown in case of multidata linking
  @Output() click = new EventEmitter();
  elementThemes = ElementThemes;
  modeTypes = ModeTypes;

  constructor(public ruleEngineService:RuleEngineService, public backendService: BackendService){}

  ngOnInit() {
  }

  handleClick(componentName) {
    this.click.emit(componentName);
  }  

  // async fetchSignatureDocument(){
  //   try{
  //   if(!this._signaturePadService.isDocumentId){
  //     this._signaturePadService.isDocumentId = true;
  //        await this._signaturePadService.fetchDocumentId();
  //   }
  //   }catch(error){
  //     console.error("File: core-elements, Method: fetchSignatureDocument",error);
  //   }
  // }
}
