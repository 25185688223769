import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from '@vanguard/shared/services/toaster/toaster.service';
import { UserService } from '@vanguard/shared/services/user.service';
import { Router } from '@angular/router';

import { environment as configs } from 'environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HTTPService {
  constructor(
    private readonly _http: HttpClient,
    private toasterService: ToasterService,
    private _userService: UserService,
    private router: Router
  ) {}

  setHeadersDefault(resType?: boolean) {
    let defaultHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let uploadHeader = new HttpHeaders({
    });
    let headers = resType ? uploadHeader : defaultHeader;
    if (this._userService.access_token || this._userService.accessToken) {
      headers = headers.append('access_token', this._userService.access_token ? this._userService.access_token : this._userService.accessToken );
    }
    if (this._userService.sessionId) {
      headers = headers.append('sessionId', this._userService.sessionId);
    }
    headers = headers.append('entityId', localStorage.getItem('entityId') || '');
    return headers;
  }

  handleError(error: any): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status} Message: ${error.message}`;
    }
    //console.error(errorMessage.toString());
    return throwError(error);
  }

  /**
   * SetMicroflowHeaders
   * @param microFlowHeaders
   * Description : Passing all ids in the headers because these are required in the microflow execution
   * in the server side to fetch paticular component configuration
   */
   setMicroflowHeaders = (microFlowHeaders,isAppRef?) => {
    let headers = this.setHeadersDefault();
    if(isAppRef){
      headers = headers.append('apprefid', microFlowHeaders.appRefId);
      return headers;
    }
    headers = headers.append('apprefid', microFlowHeaders.appRefId);
    headers = headers.append('application_id', microFlowHeaders.application_id);
    headers = headers.append('componentid', microFlowHeaders.componentId);
    headers = headers.append('sectionId', microFlowHeaders.sectionId);
    headers = headers.append('subSectionId', microFlowHeaders.subSectionId)
    return headers;

  }

    /**
   * setUamPortalHeaders
   * Description : Setting UAMPORTAL headers for dropdown which requires decrypt_token and e-id as headers
   */
     setUamPortalHeaders = () => {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      headers= headers.append("token",this._userService.access_token?this._userService.access_token:this._userService.accessToken);
      headers=headers.append("e-id","Customer_flow");
      headers=headers.append("decrypt_token","enabled");
      return headers;

    }

  /**
   * Common Http GET method for all Get Api method
   * @param path :string api path
   * @param params :HttpParams request params
   */

  // get(path: string, params: any = {}, body?: any): Observable<any> {
  //     const url = configs.coreAPIBaseUrl; // base url
  //     return this._http.get(`${url}${path}`, {
  //         params,
  //         headers: this.setHeadersDefault(),

  //     }).pipe(catchError(this.handleError));
  // }

  get(
    path: string,
    params: any = {},
    login?: any,
    imageType?: boolean,
    resType?:boolean,
    uamPortalHeaders?:boolean
  ): Observable<any> {
    return this._http
      .get(`${path}`, {
        params,
        headers:uamPortalHeaders?this.setUamPortalHeaders() : login == false  ? {} : this.setHeadersDefault(resType),
        responseType: (imageType ? 'blob' : 'json') as any,
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * Common Http POST method
   * @param path api path
   * @param body request body
   * @param params request params
   * @param headers request headers
   */
  post(
    path: string,
    body: Object = {},
    params: any = {},
    type?: FormData,
    image?: boolean,
    resType?: boolean,
    headers?: any,
    isAppRef?:any
  ): Observable<any> {
    return this._http
      .post(`${path}`, image ? type : body, {
        params,
        headers: headers ? this.setMicroflowHeaders(headers,isAppRef) : this.setHeadersDefault(image),
        responseType: (resType ? 'blob' : 'json') as any,

      })
      .pipe(map((response: any) => response),catchError(this.handleError));
  }

  put(path: string, params: string, body: any, resType: boolean): Observable<any> {
    //const baseUrl = configs.coreAPIBaseUrl; // configs.baseUrl
    return this._http
      .put(`${path}${params}`, body, {
        headers: this.setHeadersDefault(resType),
      })
      .pipe(catchError(this.handleError));
  }

  delete(path: string, params: string, body: any,resType?:any): Observable<any> {
    const options: any = {
      headers: this.setHeadersDefault(resType),
      body: body,
    };
    return this._http
      .delete(`${path}${params}`, options)
      .pipe(catchError(this.handleError));
  }

  patch(path: string, body?: any): Observable<any> {
    return this._http
      .patch(`${path}`, body, {
        headers: this.setHeadersDefault(),
      })
      .pipe(catchError(this.handleError));
  }
}
