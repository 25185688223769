import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment as configs } from 'environments/environment';
import { SharedModule } from '@vanguard/shared';
import { PagenotfoundComponent } from '@vanguard/shared/components/pagenotfound/pagenotfound.component';
import { UserService } from '@vanguard/shared/services/user.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Globals } from 'app/globals';
import { ChartsModule } from 'ng2-charts';
import { IonicModule } from '@ionic/angular';
import { CustomErrorComponent } from '@vanguard/shared/components/customError/customError.component';
import { AppConfigService } from '@vanguard/shared/services/app-config.service';
import { ExpiredLinkErrorComponent } from '@vanguard/shared/components/expiredLinkError/expiredLinkError.component';
import { EmailVerificationComponent } from '@vanguard/shared/components/email-verification/email-verification.component';
import { CommonModule } from '@angular/common';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { AppResolver } from './app.resolver';
import { HTTPService } from './modules/core/ui-elements/v4/services/http.service';
import '@latitudenpm/ezee-lend-components';
import { NgxVishwamLibModule } from "ngx-vishwam-lib";
import { GraphQLModule } from './graphql.module';
const VishwamConfig = { API_URL: configs.vishwamSdkUrl }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export function appInit(appConfigService: AppConfigService) {
//   return () => appConfigService.getMapsConfig('Analytics','Google Maps');
// }

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    CustomErrorComponent,
    ExpiredLinkErrorComponent,
    EmailVerificationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    NgxSpinnerModule,
    GraphQLModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: configs.production }),
    ChartsModule,
    IonicModule.forRoot(),
    CommonModule,
    NgxVishwamLibModule.forRoot(VishwamConfig),
  ],
  providers: [UserService,
    AppResolver,
    HTTPService,
    CommonhttpService,
    Globals,
    // AppConfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInit,
    //   multi: true,
    //   deps: [AppConfigService]
    // }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
