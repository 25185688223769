import { AppConstants } from '@vanguard/config/appConstants';
import * as _ from 'lodash';
import { Validators, FormControl } from '@angular/forms';
import { DateTimeFormat } from '@vanguard/config/dateTimeFormat';
import * as moment from 'moment';

export class FieldConfigs {

  static getApplicantField(field, applicantType) {
    let fieldConfig = _.cloneDeep(field);
    if (applicantType === AppConstants.SECONDARY_APPLICANT) {
      // Check if secondary config is available
      if (field.hasOwnProperty('secondary') && field.secondary.hasOwnProperty('sameAsPrimary')) {
        const { secondary, ...primaryApplicantProperties } = fieldConfig;
        const { sameAsPrimary, ...secondaryApplicantProperties } = secondary;
        // Check if same as primary is false
        if (!secondary.sameAsPrimary) {
          // If available then use that configuration
          fieldConfig = {
            ...secondaryApplicantProperties,
            secondaryEnable: secondary['enable'] // Will be enabled if only enabled for secondary appliant
          };
        } else {
          // Otherwise use primary applicant configuration itself
          fieldConfig = {
            ...primaryApplicantProperties,
            secondaryEnable: primaryApplicantProperties.enable // Will be enabled if enabled for primary applicant
          };
        }
        // Enable formula for secondary applicant if enabled in seperate configuration
        if (fieldConfig.formulas && Array.isArray(fieldConfig.formulas) && fieldConfig.formulas.length > 0) {
          for (const formula of fieldConfig.formulas) {
            // If enabled, then make secondary enabled as true
            if (formula['enabled']) {
              formula['secondaryEnabled'] = true;
            }
          }
        }
        // If the type is container then attach container fields configured for primary in secondary
        if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
          fieldConfig = {
            ...fieldConfig,
            fields: field.fields
          };
        }
      } else {
        // Enable formula for secondary applicant if no seperate configuration present
        if (fieldConfig.formulas && Array.isArray(fieldConfig.formulas) && fieldConfig.formulas.length > 0) {
          for (const formula of fieldConfig.formulas) {
            // If enabled, then make secondary enabled as true
            if (formula['enabled']) {
              formula['secondaryEnabled'] = true;
            }
          }
        }
      }
    } else if (applicantType === AppConstants.GUARANTOR_APPLICANT) {
      // Check if guarantor config is available
      if (field.hasOwnProperty('guarantor') && field.guarantor.hasOwnProperty('sameAsPrimary')) {
        const {guarantor, ...primaryApplicantProperties} = fieldConfig;
        const {sameAsPrimary, ...guarantorApplicantProperties} = guarantor;
        // Check if same as primary is false
        if (!guarantor.sameAsPrimary) {
          // If available then use that configuration
          fieldConfig = {
            ...guarantorApplicantProperties,
            guarantorEnable: guarantor['enable'] // Will be enabled if only enabled for guarantor appliant
          };
        } else {
          // Otherwise use primary applicant configuration itself
          fieldConfig = {
            ...primaryApplicantProperties,
            guarantorEnable: primaryApplicantProperties.enable // Will be enabled if enabled for primary applicant
          };
        }
        // Enable formula for guarantor applicant if enabled in seperate configuration
        if (fieldConfig.formulas && Array.isArray(fieldConfig.formulas) && fieldConfig.formulas.length > 0) {
          for (const formula of fieldConfig.formulas) {
            // If enabled, then make guarantor enabled as true
            if (formula['enabled']) {
              formula['guarantorEnabled'] = true;
            }
          }
        }
        // If the type is container then attach container fields configured for primary in guarantor
        if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
          fieldConfig = {
            ...fieldConfig,
            fields: field.fields
          };
        }
      } else {
        // Enable formula for guarantor applicant if no seperate configuration present
        if (fieldConfig.formulas && Array.isArray(fieldConfig.formulas) && fieldConfig.formulas.length > 0) {
          for (const formula of fieldConfig.formulas) {
            // If enabled, then make guarantor enabled as true
            if (formula['enabled']) {
              formula['guarantorEnabled'] = true;
            }
          }
        }
      }
    } else {
      // If applicant is primary
      if (field.hasOwnProperty('secondary')) {
        const { secondary, ...primaryApplicantProperties } = field;
        // Check if same as primary is false
        if (!secondary.sameAsPrimary) {
          fieldConfig = {
            ...primaryApplicantProperties,
            secondaryEnable: secondary['enable'] // Will be enabled if only enabled for secondary appliant
          };
        } else {
          fieldConfig = {
            ...primaryApplicantProperties,
            secondaryEnable: primaryApplicantProperties.enable // Will be enabled if enabled for primary applicant
          };
        }
      } else if (field.hasOwnProperty('guarantor')) {
        const {guarantor, ...primaryApplicantProperties} = field;
        // Check if same as primary is false
        if (!guarantor.sameAsPrimary) {
          fieldConfig = {
            ...primaryApplicantProperties,
            guarantorEnable: guarantor['enable'] // Will be enabled if only enabled for guarantor appliant
          };
        } else {
          fieldConfig = {
            ...primaryApplicantProperties,
            guarantorEnable: primaryApplicantProperties.enable // Will be enabled if enabled for primary applicant
          };
        }
      }
    }
    // TODO - this is just fix not permanent solution
    if (fieldConfig && fieldConfig.field_type_details
      && ['dropdown', 'radio'].indexOf(fieldConfig.field_type_details.type) > -1 && fieldConfig.field_type_details.property === '') {
      fieldConfig.field_type_details.property = fieldConfig.key + Math.random();
    }
    return fieldConfig;
  }

  static isFieldApplicable(field, applicantType) {
    if ((// If the current applicant is primary or guarantor, then check if its enabled for primary
      ((applicantType === AppConstants.PRIMARY_APPLICANT ||
        applicantType === AppConstants.GUARANTOR) && field.enable) ||
      // If the current applicant is secondary
      (applicantType === AppConstants.SECONDARY_APPLICANT && (
        // If yes then check secondaryenable flag
        field.secondaryEnable)
      )) &&
      !field.internal) {
      return true;
    }
    return false;
  }

  static getApplicantFields(fields, applicantType) {
    const outputFields = [];
    const actualFields = _.cloneDeep(fields);
    if(actualFields.length){
      for (const actualField of actualFields) {
        const field = FieldConfigs.getApplicantField(actualField, applicantType);
        outputFields.push(field);
      }
    }
    return outputFields;
  }

  static getRowColumnFields(fields) {
  let customizedFields = [];
  let columnFields = [];
  const rowFields = [];
  let columnConfig;
  let rowConfig;
  let groupFields = [];
  let currentGroup;
  for (const field of fields) {
      if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
        customizedFields.push(field);
      } else {
        if (field.field_type_details.type === 'element_row') {

          if (customizedFields.length > 0) {
            columnFields.push({
              config: columnConfig,
              fields: customizedFields
            });
            customizedFields = [];
          }
          if (columnFields.length > 0) {
            rowFields.push({
              config: rowConfig,
              fields: columnFields
            });
            columnFields = [];
          }

          // Set element_row display config
          if (field.field_type_details.displayConfigs) {
            // Format height to VG grid width class vg-grid__row-height-<height size>
            const heightType = field.field_type_details.displayConfigs.heightType;
            let height = field.field_type_details.displayConfigs.height;
            switch (heightType) {
              case 'custom':
                height = `vg-grid__row--height-${height}percent`;
                break;
              case 'content':
                height = `vg-grid__row--height-content`;
                break;
              case 'auto':
                height = '';
                break;

              default:
                height = '';
                break;
            }
            field.field_type_details.displayConfigs.height = height;
            rowConfig = field.field_type_details.displayConfigs;
          } else {
            rowConfig = {};
          }

        } else if (field.field_type_details.type === 'element_column') {
          if (groupFields.length > 0) {
            customizedFields.push({
              groupKey: currentGroup,
              fields: groupFields,
              field_identifier: groupFields[0].field_identifier,
              hide:groupFields[0].hide
            });
            groupFields = [];
          }
          if (customizedFields.length > 0) {
            columnFields.push({
              config: columnConfig,
              fields: customizedFields
            });
            customizedFields = [];
          }

          // Set element_column display config
          if (field.field_type_details?.displayConfigs) {

            // Format width to VG grid width class vg-grid__col-width-<width size>
            const widthType = field.field_type_details.displayConfigs?.widthType;
            let width = field.field_type_details.displayConfigs?.width;
            switch (widthType) {
              case 'custom':
                width = `vg-grid__col--width-${width}percent`;
                break;
              case 'content':
                width = `vg-grid__col--width-content`;
                break;
              case 'auto':
                width = '';
                break;

              default:
                width = '';
                break;
            }
            field.field_type_details.displayConfigs['width'] = width;

            // Format grid alignment to VG grid alignment class
            // vertical vertical-top/vertical-center/vertical-bottom
            // horizontal horizontal-left/horizontal-center/horizontal-right
            if (field.field_type_details.displayConfigs?.alignment) {
              if (field.field_type_details.displayConfigs.alignment?.vertical) {
                const verticalAlignment = field.field_type_details.displayConfigs.alignment.vertical;
                field.field_type_details.displayConfigs.alignment.vertical
                  = verticalAlignment === 'auto' ? '' : `vg-grid__content--align-vertical-${field.field_type_details.displayConfigs.alignment.vertical}`;
              }
              if (field.field_type_details.displayConfigs.alignment?.horizontal) {
                const horizontalAlignment = field.field_type_details.displayConfigs.alignment.horizontal;
                field.field_type_details.displayConfigs.alignment.horizontal
                  = horizontalAlignment === 'auto' ? '' : `vg-grid__content--align-horizontal-${field.field_type_details.displayConfigs.alignment.horizontal}`;
              }
            }
            columnConfig = field.field_type_details.displayConfigs;
          } else {
            columnConfig = {};
          }

        } else {
          if (field.field_identifier !== 'G' && field.field_identifier !== 'CG') {
            if (groupFields.length > 0) {
              customizedFields.push({
                groupKey: currentGroup,
                fields: groupFields,
                field_identifier: groupFields[0].field_identifier,
                hide:groupFields[0].hide
              });
              groupFields = [];
            }
            customizedFields.push(field);
          } else {
            if (currentGroup === field.groupKey) {
              groupFields.push(field);
            } else {
              if (groupFields.length > 0) {
                customizedFields.push({
                  groupKey: currentGroup,
                  fields: groupFields,
                  field_identifier: groupFields[0].field_identifier,
                  hide:groupFields[0].hide
                });
                groupFields = [];
              }
              groupFields.push(field);
              currentGroup = field.groupKey;
            }
          }
        }
      }
    }
    if (groupFields.length > 0) {
      customizedFields.push({
        groupKey: currentGroup,
        fields: groupFields,
        field_identifier: groupFields[0].field_identifier,
        hide:groupFields[0].hide
      });
    }
    if (customizedFields.length > 0) {
      columnFields.push({
        config: columnConfig,
        fields: customizedFields
      });
      rowFields.push({
        config: rowConfig,
        fields: columnFields
      });
    }
    return rowFields;
  }

}
