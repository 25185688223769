import {  Injectable,  } from "@angular/core";
import { HTTPService } from '../services/http.service';
import { environment as configs } from 'environments/environment';
import { HttpClient } from "@angular/common/http";
import { TaskService } from "../services/task.service";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";

@Injectable({
    providedIn: 'root'
  })
export class MultiDataService{
  
  //These variables are either updated from multidatacomponent or multidatapopup component - of multidata
  public templatesConfiguration:any; 
  public model:any;
  public fieldKey:any;
  public initialFormValue:any;
  public deleteTemplate:any;
  public rowDataToBeDelete:any;
  public currentIndexEntry:any;
  public deleteSection:any;
  public tempTaskId: string = '';

    constructor(private _http: HTTPService , private http: HttpClient, private _taskService: TaskService, private _apollo: Apollo){

    }
    /**
     * @description  getPublishedMultiDataTemplates
    */
    getPublishedMultiDataTemplates(templateIds){
        try{
          const variables = {
            "count": false,
            "multiData": templateIds,
            "status": ["Published"],
          };
          return this._apollo.use('adminAPIClient').query<any>({
            query:gql`
            query ($count:Boolean, $multiData:[JSONObject],$status: [String], $version:String,) {
              multiDataDetails(
               status: $status,
                count:$count,
                multiData:$multiData,
                version:$version
              ) {
                  data {
                  name
                  version
                  multiDataId
                  templateId
                  status
                  createdBy
                  modifiedTS
                  description
                  displayName
                  modifiedBy
                  createdTS
                  Rules
                  customValidations
                  fields
                  displayFields
                  enableHelpText
                  lookupConfigs
                  lookupEnabled
                  multiDataId
                  }
                }
              }`,
           variables:variables,
           fetchPolicy:'no-cache'
          }).toPromise();
        } catch (err){
            console.log(err);
            return 
            }
        }


  
    /**
    * @description  saveMultiDataTemplateApi - rowdata , appid , sectionId
    */
    saveMultiDataTemplateApi(instantTableData , application_id ,templateId, sectionId, fieldKey , enableLinking, taskId,version, enableChildLinking?){
      try{
        const body = { tableData : instantTableData , fieldKey : fieldKey , enableLinking: enableLinking};
        let response;
        //Check if entry Id is present 
        if(instantTableData.entryId){
          //Call post api if entryId is already there 
          response = this._http.post(`${configs.clientUrl}/v4/application/${application_id}/task/${taskId}/multidataupdate/${templateId}/${version}/${sectionId}` ,body);
        } else {
          //Save for the first time without entryId
          response = this._http.post(`${configs.clientUrl}/v4/application/${application_id}/task/${taskId}/multidata/${templateId}/${version}/${sectionId}` ,body);
        }
        if (enableChildLinking) {
          return response.toPromise();
        }
        return response;
      } catch (err){ 
            console.log(err);
            return 
            }
       }
       
    /**
    * @description  generateMultiDataSection - application_id , templateId  , fieldKey
    */
    generateMultiDataSection(application_id,templateId,fieldKey,taskId,category?,limitMaxSectionGenerationToOne?){
      try{
          const body = { fieldKey : fieldKey , category: category || null,limitMaxSectionGenerationToOne:limitMaxSectionGenerationToOne || null };
        return this._http.post(`${configs.clientUrl}/v4/application/${application_id}/task/${taskId}/multidata/${templateId}/generate/section` ,body);
        } catch (err){
            console.log(err);
            return 
            }
         }

      /**
      * @description  deleteRow - application_id 
      */  
       deleteRow(application_id,fieldKey,enableLinking, taskId){
        try{
          if(this.rowDataToBeDelete && this.rowDataToBeDelete.entryId){
            const entryId = this.rowDataToBeDelete.entryId; 
          const body = { enableLinking: enableLinking };
          return this._http.delete(`${configs.clientUrl}/v4/application/${application_id}/task/${taskId}/multidata/${this.deleteTemplate.multiDataId}/section/${this.deleteSection.sectionId}/fieldKey/${fieldKey}/entryId/${entryId}`, "" ,body);
          }
          } catch (err){
            console.log(err);
            return 
            }
      }

      /**
      * @description  deleteSection - application_id 
      */  
      deleteTemplateSection(application_id,fieldKey, taskId){
          try{
            return this._http.delete(`${configs.clientUrl}/v4/application/${application_id}/task/${taskId}/multidata/${this.deleteTemplate.multiDataId}/section/${this.deleteSection.sectionId}/fieldKey/${fieldKey}`, "" , null); 
          } catch (err){
            console.log(err);
            return 
            }
        }

    /**
    * @description  reset table data of all the sections of given template
    * @param templateId 
    * @param fieldKey 
    * @param application_id 
    * @returns 
    */
    async resetTemplateData(templateId,fieldKey,application_id){
      try {
        let taskId: string = '';
        const response = await this._taskService.getTaskIdByApplicationId(application_id);
        if(response && response.data && response.data.fetchTasksOrQueriesByApplicationId && response.data.fetchTasksOrQueriesByApplicationId.data.length>0){
          taskId = response.data.fetchTasksOrQueriesByApplicationId.data[0].taskId;
        }
        return this._http.delete(`${configs.clientUrl}/v4/application/${application_id}/task/${taskId}/multidata-section/${templateId}/fieldKey/${fieldKey}`, "" ,{}).toPromise();
      } catch(error){
        console.log(error,'error');
      }
  }


}
