import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { environment } from 'environments/environment';

import { UserService } from '@vanguard/shared/services/user.service';
import { HttpClient } from '@angular/common/http';

interface RecordedAudioOutput {
    blob: Blob;
    title: string;
}
@Injectable()

export class VideoConfService {
    baseURL: string = environment.clientUrl;
    adminURL: string = environment.adminUrl;
    postVideoConferenceUrl: string = environment.postVideoConferenceUrl;
    
    constructor(private userService: UserService,public router: Router, private commonApiService?: CommonhttpService,private http?: HttpClient) {
    }
    createApplication(reqBody) {
        const body = JSON.stringify(reqBody);
        const reqUrl = `${this.baseURL}/v3/application/create`;
        return this.commonApiService.post(reqUrl, body)
            .pipe(map((response: Response) => response));
    }
    updateStepData(reqBody) {
        const body = JSON.stringify(reqBody);
        const reqUrl = `${this.baseURL}/v3/application/consumer/save_or_update`;
        return this.commonApiService.post(reqUrl, body)
            .pipe(map((response: Response) => response));
    }
    updateStepDataUser(reqBody) {
        reqBody['userName'] = this.userService.userName;
        const body = JSON.stringify(reqBody);
        const reqUrl = `${this.baseURL}/v3/application/user/save_or_update`;
        return this.commonApiService.post(reqUrl, body)
            .pipe(map((response: Response) => response));
    }
  
  

    private toString(value) {
        let val = value;
        if (!value) {
            val = '00';
        }
        if (value < 10) {
            val = '0' + value;
        }
        return val;
    }

    getFieldsForVKYC(reqBody) {
        const body = JSON.stringify(reqBody);
        const reqUrl = `${this.baseURL}/v3/vkyc/getFieldsForVKYC`;
        return this.commonApiService.post(reqUrl, body)
            .pipe(map((response: Response) => response));
    }
    getFaceMatchPer(reqBody) {
        const body = JSON.stringify(reqBody);
        const reqUrl = `${this.baseURL}/v3/vkyc/faceMatch`;
        return this.commonApiService.post(reqUrl, body)
            .pipe(map((response: Response) => response));
    }

    initiateRecordingPostProcess(recordingDirectory: string,application_id:any,childID:any,clientId:any) {
        const body = JSON.stringify({
            dir: recordingDirectory,
            application_id:application_id,
            initiatedBy: this.userService.userName,
            userId: this.userService.userId,
            childApplicationId:childID,
            clientId: clientId
        });
        const reqUrl = `${this.baseURL}/v3/vkyc/executePostSession`;
        return this.commonApiService.post(reqUrl, body)
            .pipe(map((response: Response) => response));
    }

    getQuestions(category:any,subcategory:any,limit:any,filter:any){
        const reqUrl = `${this.baseURL}/v3/dataset/random/${category}/${subcategory}/${limit}/${filter}`;
        return this.commonApiService.get(reqUrl, {})
        .pipe(map((response: Response) => response));
    }

    getPanMatch(reqBody) {
        const body = JSON.stringify(reqBody);
        const reqUrl = `${this.baseURL}/v3/vkyc/ocr/india/pan`;
        return this.commonApiService.post(reqUrl, body, {})
        .pipe(map((response: Response) => response));
    }

    getPanStatus(operationId) {
        const reqUrl = `${this.baseURL}/v3/vkyc/ocr/india/pan/status_check/${operationId}`;
        return this.commonApiService.get(reqUrl, {})
        .pipe(map((response: Response) => response));
    }

      /**
* Determine the mobile operating system.
* This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
*
* @returns {String}
*/
  getMobileOperatingSystem() {
    //Detect the Mobile OperatingSystem
    let userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return "iOS";
    }

    return "other";
  }

  getIpAddress(){
    const reqUrl = `https://api.ipify.org/?format=json`;
    return this.http.get(reqUrl).toPromise();
  }

  getVPNCheck(ip) {
    const reqUrl = `https://vpnapi.io/api/${ip}?key=${environment['vpnApiKey']}`;
    return this.http.get(reqUrl).toPromise();
  }

  speedTest(imageUrl, downloadSize) {
    return new Promise((resolve, reject) => {
      let startTime, endTime;
      let download = new Image();
      download.onload = function () {
        endTime = (new Date()).getTime();
        showResults();
      }

      download.onerror = function (err, msg) {
        reject({success:false,speed:'0',msg:"Invalid image, or error downloading"})
      }

      startTime = (new Date()).getTime();
      let cacheBuster = "?nnn=" + startTime;
      download.src = imageUrl + cacheBuster;

      function showResults() {
        let duration = (endTime - startTime) / 1000;
        let bitsLoaded = downloadSize * 8;
        let speedBps = (bitsLoaded / duration).toFixed(2);
        let speedKbps = (parseFloat(speedBps) / 1024).toFixed(2);
        let speedMbps = (parseFloat(speedKbps) / 1024).toFixed(2);
        resolve({success:true,speed:speedMbps,msg:"Your connection speed is:"+speedMbps + " Mbps"})
      }
    })
  }

  browserDetect() {
    //Detect the Browser
    return new Promise((resolve, reject) => {
      let browser = (function () {
        var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
        switch (true) {
          case test(/edg/i): return "Microsoft Edge";
          case test(/trident/i): return "Microsoft Internet Explorer";
          case test(/firefox/i): return "Mozilla Firefox";
          case test(/fxios/i): return "Mozilla Firefox IOS";
          case test(/opr\//i): return "Opera";
          case test(/ucbrowser/i): return "UC Browser";
          case test(/samsungbrowser/i): return "Samsung Browser";
          case test(/miui/i): return "MI Browser";
          case test(/chrome|chromium/i): return "Google Chrome";
          case test(/crios/i): return "Google Chrome IOS";
          case test(/safari/i): return "Apple Safari";
          default: return "Other";
        }
      })();
      let device = this.getMobileOperatingSystem()
      console.log("device", device)
      console.log("browser", browser)

      console.log("browseName", browser, browser, device)
      if (device == 'iOS' && browser == "Apple Safari") {
        resolve({ status: true, device: device, browseName: browser, msg: "Valid Browser" })
      } else if (device == 'Android' && browser == "Google Chrome") {
        resolve({ status: true, device: device, browseName: browser, msg: "Valid Browser" })
      } else if (device == 'other' && browser == "Google Chrome") {
        resolve({ status: true, device: device, browseName: browser, msg: "Valid Browser" })
      } else if (device == 'Windows Phone' && browser == "Google Chrome") {
        resolve({ status: true, device: device, browseName: browser, msg: "Valid Browser" })
      } else {
        let verifiedMsg = `Current Browser: ${browser} not supported. 
            \n Kindly connect with below browsers and device. 
            \n Desktop: Windows/MAC Browser: Google Chrome
            \n Mobile: Android Browser: Google Chrome, Mobile: iOS Browser: Safari`;
        resolve({ status: false, device: device, browseName: browser, msg: verifiedMsg })
      }
    })
  }

  getLivelinessData(reqBody) {
    const body = JSON.stringify(reqBody);
    const reqUrl = `${this.baseURL}/v3/vkyc/unassisted`;
    return this.commonApiService.post(reqUrl, body, {})
    .pipe(map((response: Response) => response));
}

getLivelinessStatus(operationId){
    const reqUrl = `${this.baseURL}/v3/vkyc/unassisted/status_check/${operationId}`;
    return this.commonApiService.get(reqUrl, {})
    .pipe(map((response: Response) => response));
}

faceMatchKYCAPI(reqBody) {
    const body = JSON.stringify(reqBody);
    console.log("reqBody",reqBody)
    const reqUrl = `${this.baseURL}/v3/vkyc/match/face_match`;
    return this.commonApiService.post(reqUrl, body, {})
    .pipe(map((response: Response) => response));
}

getQueueDate(application_id) {
  try {
    return this.commonApiService
      .get(
        `${this.baseURL}/v4/vkyc/waiting-time/${application_id}`
      )
      .toPromise();
  } catch (error) {
    console.log(error);
  }
}

getTimeDates(date, calenderConfigId) {
  try {
    return this.commonApiService
      .get(
        `${this.adminURL}/v4/private/calender-config/${calenderConfigId}/slot/${date}`
      )
      .toPromise();
  } catch (error) {
    console.log(error);
  }
}

getSlotDates(flowname) {
  try {
    return this.commonApiService
      .post(
        `${this.adminURL}/v4/private/calender-config/working-days/${flowname}?count=6`
      )
      .toPromise();
  } catch (error) {
    console.log(error);
  }
}

public getQueuedCount() {
  try {
    return this.commonApiService
      .get(`${this.baseURL}/v3/vkyc/waiting-time/`)
      .toPromise();
  } catch (error) {
    console.log(error);
  }
}

revertApplication(application_id) {
  try {
    return this.commonApiService
      .put(
        `${this.baseURL}/v3/vkyc/revert-to-confirm-appoinment/${application_id}`
      )
      .toPromise();
  } catch (error) {
    console.log(error);
  }
} 

saveTimeslot(body) {
  try {
    return this.commonApiService
      .post(`${this.adminURL}/v4/private/calender-config/schedule-slot`, body)
      .toPromise();
  } catch (error) {
    console.log(error``);
  }
}
}
