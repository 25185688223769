import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { session } from '@vanguard/config/keycloakSessionConfig';
import { environment as configs } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ToasterService } from '@vanguard/shared/services/toaster/toaster.service';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import * as forge from 'node-forge';
import { PublicKeyCredential } from '../../config/publicKeyConfig';
import * as jwt_decode from 'jwt-decode';
import { AppConstants } from '@vanguard/config/appConstants';

@Injectable()
export class AuthenticationService {
  baseURL: string = configs.clientUrl;
  adminURL: string = configs.adminV2Url;
  domain: string = configs.domain;
  public userDetails: any;
  public refresh_token: any;
  public access_token: any;
  public timer1: any;
  public timer2: any;
  public expireTime: any;
  /**
   * To show warning/error/success message
  */
  public toasterService: ToasterService;

  constructor(public router: Router, toasterService: ToasterService,
    private commonApiService: CommonhttpService) {
    this.toasterService = toasterService;
    this.expireTime = (session.session_expire_warning / 60).toFixed(1);
  }

   // call platform api to get token with auth_code instead of uam api
   public uamGetTokenV2(code, redirectUri) {
    const data = {
      username: "",
      password: "",
      auth_code: code,
      redirect_uri: redirectUri
    }
    // const body = JSON.stringify(data);
    const url = `${this.baseURL}/v3/auth/login`;
    // const header = { 'e-id': this.eId }
    return this.commonApiService.post(url, data)
      .pipe(map(response => response));
  }
  
  public login(userDetails) {
    let password = userDetails.password;
    if (configs.encryptPassword) {
      password = this.encryptData(userDetails.password);
    }
    const data = {
      username: userDetails.username,
      password: password,
    };
    const body = JSON.stringify(data);
    const url = `${this.baseURL}/v3/auth/login`;
    console.log(url);
    return this.commonApiService.post(url, body)
      .pipe(map(response => response));
  }

  public getUserDetails(userDetails) {
    const data = {
      userid: userDetails.userid,
      token: userDetails.token,
    };
    const body = JSON.stringify(data);
    const url = `${this.adminURL}/v4/user-details`;
    return this.commonApiService.get(url)
      .pipe(map(response => response));
  }

  public getUserRoles(userDetails) {
    const data = {
      userid: userDetails.userid,
      token: userDetails.token,
    };
    const body = JSON.stringify(data);
    const url = `${this.baseURL}/v3/users/roles`;
    return this.commonApiService.get(url)
      .pipe(map(response => response));
  }

  public getUserGroups(userDetails) {
    const body = JSON.stringify(userDetails);
    const url = `${this.baseURL}/v3/users/groupsByUserId`;
    return this.commonApiService.get(url)
      .pipe(map(response => response));
  }

  public sessionControl() {
    if (this.access_token) {
      this.getRefreshToken().subscribe(newToken => {
        this.access_token = newToken['access_token'];
        this.refresh_token = newToken['refresh_token'];
        if (newToken['expires_in'] >= session.valid_session_time) {
          clearTimeout(this.timer2);
          clearTimeout(this.timer1);
          this.timer1 = setTimeout(() => {
            this.toasterService.question(`Your session will expire in next ${this.expireTime} minute.
                        Do you wish to increase the session ? `, this.getRefreshToken, this.closeSession);
          }, (newToken['expires_in'] - session.session_expire_warning) * 1000);

          this.timer2 = setTimeout(() => {
            this.toasterService.warning(`Your session expired `);
          }, newToken['expires_in'] * 1000);
        }
        if (newToken['error'] === 'Invalid user') {
          location.href = './login';
        }

      });
    }
  }
  invalidateSession() {
    console.log('abc....');
    this.closeSession();
  }
  continueSession() {
    this.getRefreshToken();
  }

  public getRefreshToken() {
    const data = {
      refresh_token: this.refresh_token,
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/auth/refresh-token`, body)
      .pipe(map(response => response));
  }

  public closeSession() {
    const data = {
      refresh_token: this.refresh_token,
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/auth/end-session`, body)
      .pipe(map(response => response));
  }

  public userCantLogin() {
    const data = {
      redirectURL: this.domain,
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/auth/forget-password`, body)
      .pipe(map(response => response));
  }// public userCantLogin(): Observable<any>

  public updateSelectedUserTeams(userTeams) {
    return this.commonApiService.post(`${this.baseURL}/v3/users/update_selected_teams`, { teams: userTeams })
      .pipe(map(response => response));
  }

  public encryptData(data): string {
    const pk = forge.pki.publicKeyFromPem(PublicKeyCredential.KEY);
    const buffer = forge.util.createBuffer(pk.encrypt(data), 'raw');
    return buffer.toHex();
  }

  public decryptJWT(token){
    const decoded = jwt_decode(token);
    return decoded.data;
  }

  public initiateUAMLogin() {
    return new Promise((resolve, reject) => {
      try {
        const browser = window.open(configs.loginUrl, `_self`);
        resolve(browser);
      } catch (error) {
        reject(error);
      }
    });
  }
  
  public checkAppAuthorisation(userAttributes){
    const appName = AppConstants.APP_NAME;
    const appAccess = userAttributes['applications'];
    return (userAttributes && appAccess && appAccess.includes(appName))?true:false;
  }

}
