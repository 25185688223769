import { BackendService } from '@vanguard/shared/services/backend.service';
import { Injectable } from '@angular/core';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { RequestHeaderType } from '@vanguard/shared/models/customHttpResponse';
import { map, catchError } from 'rxjs/operators';
import { UserService } from '@vanguard/shared/services/user.service';
import { environment as configs } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class RuleEngineApi {

    constructor(private commonApiService: CommonhttpService, private backendService: BackendService, private userService: UserService, private _apollo: Apollo) {

  }
  adminUrl: string = configs.adminUrl;
  private _fileAction = new BehaviorSubject<any>({});
  public fileAction$: any = this._fileAction.asObservable();

    /**
   * @description update file action
   * @param data 
   */
   updateFileAction(data){
    this._fileAction.next(data);
  }

    /**
   * @description get file action
   * @returns 
   */
  getFileAction = (): any => {
    try {
      return this._fileAction.asObservable();
    } catch (error) {
      console.log(error);
    }
  };

  async getRuleDetails(ruleConfigs){
    const configId = ruleConfigs
    const res = await this.fetchRule(configId);
    return res?.data?.rules?.data;
}
// public getRuleDetails(ruleConfigs)
public fetchRule(configIds:any){
  const variables = {
    "configIds":configIds
  };
  return this._apollo.use('adminAPIClient').query<any>({
    query:gql`query($configIds:[JSONObject]){
      rules(configIds:$configIds){
        data {
          rule,
          isEnabled,
          configId
        }
      }
    }`,
  variables:variables,
  fetchPolicy:'no-cache'
  }).toPromise();
}
  public getLookupData(lookupCategory, lookupSubCategory, queryString) {
    return this.commonApiService.get(`${this.adminUrl}/setup/configs/lookup/${lookupCategory}/${lookupSubCategory}?${queryString}`)
    .pipe(map((response: Response) => response), catchError(this.backendService.handleError));
  }

}
