import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { PersonalDetailsObject } from '../interfaces/personalDetails.interface';
import { IApplicationProgress } from '../interfaces/applicationProgress.interface';
import { BackendService } from '@vanguard/shared/services/backend.service';
import * as _ from 'lodash';
import { AppConstants } from '@vanguard/config/appConstants';
import { BackstageServiceComponent } from './backstage.service';
import { IField } from '@vanguard/core/interfaces/field-details.interface';
import { forkJoin } from 'rxjs';
import { LookUpConfig } from '@vanguard/core/modules/lookup/models/lookup-config';
import { FormUtils } from '../util/form.util';
import { SaveAppDataCore } from '@vanguard/core/utils/saveData';
import { FieldConfigs } from '@vanguard/core/components/fieldConfigs';
import { RoutesCore } from '@vanguard/core/components/routes';
import { CreateFormControl } from '@vanguard/core/components/createFormControl';
import { ApplicationProgressStatus } from '@vanguard/backstage/models/appProgress';
import { NavigationActions } from '../enums/navigationActions.enum';
import { IFieldService } from '@vanguard/core/interfaces/field-service.interface';
import { IPropertyDetails } from '../interfaces/property-details.interface';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable()
export class FormFieldSetupService extends FormUtils implements IFieldService {

  configs: Map<string, [IPropertyDetails]>;
  applicantConfigsMap: Map<string, Map<string, Array<Object>>>;
  form: any;
  fieldsConfig;
  pageRulesConfig;
  updatedDetailsModel: PersonalDetailsObject;
  externalModel: PersonalDetailsObject;
  model: PersonalDetailsObject;
  public _MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
  isGuarantorTriggred: Boolean = false;
  currentStage = 0;
  currentStageName = '';
  currentSectionName = '';
  currentSubSectionName = '';
  currentSectionId: Number;
  currentSubSectionId: Number;
  currentSubsection;
  flowRules = [];
  loadingChangeSection = [];
  public moveNextComponent;
  public movePreviousComponent;
  public previousNavigationAction: NavigationActions;
  previousForm;
  accessControl;
  currentModel: any;
  pmayFormField: any = false;
  pmayloanPurpose: any;
  primaryModel: PersonalDetailsObject;
  applicantDetailsMap: Map<string, PersonalDetailsObject>;
  externalDetailsMap: Map<string, PersonalDetailsObject>;
  updatedDetailsMap: Map<string, PersonalDetailsObject>;
  filesUploadForm: any;
  fieldKeyvalueAuditMap: Map<string, Object[]> = new Map<string, Object[]>();
  applicantFieldsMap: Map<string, any[]>;
  serviceType = 'USER';
  applicationProgress = {};
  markControlDirtyMap = {};
  public appStatus= ApplicationProgressStatus;
  public newLookupConfigArr:any = [];
  public newLookupConfigComplete:any = [];
  constructor(public _fb: UntypedFormBuilder, public backstageService: BackstageServiceComponent, public backendService: BackendService, private _apollo?: Apollo) {
    super(_fb);
    super.init(this, null);
  }

  init(derivedFieldService) {
    super.init(this, derivedFieldService);
  }


  async getFieldsConfig(fields: any) {
    // this will be map, get object by passing application_id
    const applicationIds = Array.from(this.applicantDetailsMap.keys());
    const currentGroupMap = new Map<string, string>();
    const groupFieldsMap = new Map<string, any[]>();
    const customizedFieldsMap = new Map<string, any[]>();
    const filteredFieldsMap = new Map<string, any[]>();
    const rowFieldsMap = new Map<string, any[]>();
    const columnFieldsMap = new Map<string, any[]>();
    this.applicantFieldsMap = new Map<string, any[]>();
    this.applicantConfigsMap = new Map<string, Map<string, Array<Object>>>();
    const dependencyConfigs = [];
    const internalDependencies = [];
    const fieldsConfig = {};

    applicationIds.forEach(applicationId => {

      currentGroupMap.set(applicationId, '');
      groupFieldsMap.set(applicationId, []);
      customizedFieldsMap.set(applicationId, []);
      filteredFieldsMap.set(applicationId, []);
      rowFieldsMap.set(applicationId, []);
      columnFieldsMap.set(applicationId, []);
      this.applicantFieldsMap.set(applicationId, []);
    });

    fields.forEach((actualField, fieldIndex) => {
      this.applicantDetailsMap.forEach((applicationDetails, applicationId) => {
        const applicantType = applicationDetails.applicant;
        const field = _.cloneDeep(FieldConfigs.getApplicantField(actualField, applicantType));
        if (field['groupType'] && field.groupType === 'CONTAINER') {
          field.fields.forEach(containerField => {
            if (containerField.field_type_details.property_type === 'DROPDOWN'
              || containerField.field_type_details.property_type === 'RADIO') {
              //
              if (field.field_type_details && field.field_type_details.internal) {
                if (!field.field_type_details.internal.option) {
                  this.addDependency(dependencyConfigs, {
                    property_type: containerField['field_type_details'].property_type,
                    property: containerField['field_type_details'].property,
                  });
                } else {
                  internalDependencies.push(field.field_type_details);
                }
              } else {
                this.addDependency(dependencyConfigs, {
                  property_type: containerField['field_type_details'].property_type,
                  property: containerField['field_type_details'].property,
                });
              }
              //
            }
          });
        } else {
          if (field.field_type_details && (field.field_type_details.property_type === 'DROPDOWN'
            || field.field_type_details.property_type === 'RADIO')) {
            //
            if (field.field_type_details && field.field_type_details.internal) {
              if (!field.field_type_details.internal.option) {
                this.addDependency(dependencyConfigs, {
                  property_type: field['field_type_details'].property_type,
                  property: field['field_type_details'].property,
                });
              } else {
                internalDependencies.push(field.field_type_details);
              }
            } else {
              this.addDependency(dependencyConfigs, {
                property_type: field['field_type_details'].property_type,
                property: field['field_type_details'].property,
              });
            }
            //
          }
        }
        if (!FieldConfigs.isFieldApplicable(field, applicantType)) {
          return;
        }
        const filteredFields = filteredFieldsMap.get(applicationId);
        let groupFields = groupFieldsMap.get(applicationId);
        let customizedFields = customizedFieldsMap.get(applicationId);
        let currentGroup = currentGroupMap.get(applicationId);
        let columnFields = columnFieldsMap.get(applicationId);
        let rowFields = rowFieldsMap.get(applicationId);
        let applicantFields = this.applicantFieldsMap.get(applicationId);
        filteredFields.push(field);
        fieldsConfig[field.key || field.groupKey] = field;
        if (field.field_type_details && field.field_type_details.type === 'element_row') {

          if (customizedFields.length > 0) {
            columnFields.push(customizedFields);
            customizedFields = [];
          }
          if (columnFields.length > 0) {
            rowFields.push(columnFields);
            columnFields = [];
          }
        } else if (field.field_type_details && field.field_type_details.type === 'element_column') {
          if (groupFields.length > 0) {
            customizedFields.push({
              groupKey: currentGroup,
              fields: groupFields,
              field_identifier: groupFields[0].field_identifier,
              enable: groupFields[0].enable,
              secondaryEnable: groupFields[0].secondaryEnable,
              hide: groupFields[0].hide
            });
            groupFields = [];
          }
          if (customizedFields.length > 0) {
            columnFields.push(customizedFields);
            customizedFields = [];
          }
        } else if (field.field_identifier !== 'G' && field.field_identifier !== 'CG') {
          if (groupFields.length > 0) {
            customizedFields.push({
              groupKey: currentGroup,
              fields: groupFields,
              field_identifier: groupFields[0].field_identifier,
              enable: groupFields[0].enable,
              secondaryEnable: groupFields[0].secondaryEnable,
              hide: groupFields[0].hide
            });
            groupFields = [];
          }
          customizedFields.push(field);
          applicantFields.push(field);
        } else {
          if (currentGroup === field.groupKey) {
            groupFields.push(field);
            applicantFields.push(field);
          } else {
            if (groupFields.length > 0) {
              customizedFields.push({
                groupKey: currentGroup,
                fields: groupFields,
                field_identifier: groupFields[0].field_identifier,
                enable: groupFields[0].enable,
                secondaryEnable: groupFields[0].secondaryEnable,
                hide: groupFields[0].hide
              });
              groupFields = [];
            }
            groupFields.push(field);
            applicantFields.push(field);
            currentGroup = field.groupKey;
          }
        }
        groupFieldsMap.set(applicationId, groupFields);
        customizedFieldsMap.set(applicationId, customizedFields);
        currentGroupMap.set(applicationId, currentGroup);
        columnFieldsMap.set(applicationId, columnFields);
        this.applicantFieldsMap.set(applicationId, applicantFields);
      });
    });
    this.fieldsConfig = fieldsConfig;
    for (const applicationId of applicationIds) {
      const groupFields = groupFieldsMap.get(applicationId);
      const customizedFields = customizedFieldsMap.get(applicationId);
      const currentGroup = currentGroupMap.get(applicationId);
      const columnFields = columnFieldsMap.get(applicationId);
      const rowFields = rowFieldsMap.get(applicationId);
      if (groupFields.length > 0) {
        customizedFields.push({
          groupKey: currentGroup,
          fields: groupFields,
          field_identifier: groupFields[0].field_identifier,
          enable: groupFields[0].enable,
          secondaryEnable: groupFields[0].secondaryEnable,
          hide: groupFields[0].hide
        });
      }
      if (customizedFields.length > 0) {
        columnFields.push(customizedFields);
        rowFields.push(columnFields);
      }
    }

    const internalConfig = (internalDependencies.length > 0) ? await this.fetchInternalDependency(internalDependencies) : [];

    if (dependencyConfigs.length === 0 && internalConfig.length === 0) {
      this.form = this.customFormBuilder(filteredFieldsMap);
      Object.keys(this.markControlDirtyMap).forEach(fieldKey => {
        Object.keys(this.form.controls).forEach(control => {
          if (this.form.controls[control] && this.form.controls[control].get(fieldKey)) {
            this.form.controls[control].get(fieldKey).markAsDirty();
          }
        });
      });
      return ({
        form: this.form,
        customizedFieldsMap,
        rowFieldsMap
      });
    }

    let response = {};
    if (dependencyConfigs.length !== 0) {
      response = await (new Promise((resolve, reject) => {
        this.backstageService.getFieldsConfig(dependencyConfigs)
          .subscribe(res => {
            if (!res.error) {
              resolve(res);
            } else {
              reject(res.error);
            }
          }, error => {
            reject(error);
          });
      }));
    }

    let configs = (response['data'] !== undefined) ? response['data'] : [];
    configs = configs.concat(internalConfig);
    this.configs = new Map<string, [IPropertyDetails]>();
    configs.forEach(config => {
      this.configs.set(config._id, config.properties);
    });
    applicationIds.forEach(application_id => {
      this.applicantConfigsMap.set(application_id, _.cloneDeep(this.configs));
    });
    this.form = this.customFormBuilder(filteredFieldsMap);
    Object.keys(this.markControlDirtyMap).forEach(fieldKey => {
      Object.keys(this.form.controls).forEach(control => {
        if (this.form.controls[control] && this.form.controls[control].get(fieldKey)) {
          this.form.controls[control].get(fieldKey).markAsDirty();
        }
      });
    });
    return {
      form: this.form,
      customizedFieldsMap,
      rowFieldsMap
    };
  }

  async fetchInternalDependency(internalDependencies) {
    for (const internalDependency of internalDependencies) {
      console.log("internalDependency ", internalDependency);
      if (internalDependency.internal.type === "PRODUCT") {
        const internalConfig = [];
        let properties = [];
        if (internalDependency.internal.showAllType) {
          const response = await (new Promise((resolve, reject) => {
            this.backstageService.getProductList()
              .subscribe((res:any) => {
                if (res.error) {
                  return reject(res.error);
                } else {
                  return resolve(res.data);
                }
              });
          }));
          properties = (response as any).map(rd => {
            return { property_desc: rd.product_name, property_value: rd.product_code };
          });
          internalConfig.push({
            properties,
            _id: `${internalDependency.property_type}${internalDependency.property}`
          });
          return internalConfig;
        } else {
          properties = internalDependency.internal_list.map(internalList => {
            return { property_desc: internalList.value, property_value: internalList.key };
          });
          internalConfig.push({
            properties,
            _id: `${internalDependency.property_type}${internalDependency.property}`
          });
          return internalConfig;
        }
      } else if (internalDependency.internal.type === "TEMPLATE") {
        const internalConfig = [];
        let properties = [];
        const allTemplates = [];
        let propertiesList;
        const response: any = await (new Promise((resolve, reject) => {
          this.backstageService.getAllTemplatesAllVersionsByTypeV3(['PDF', 'SMS', 'HTML', 'EMAIL'])
            .subscribe((res:any) => {
              if (res.error) {
                return reject(res.error);
              } else {
                return resolve(res.data.map(template => {
                  return {
                    key: { id: template.template_id, name: template.template_name, type: template.template_type, verNum: template.verNum },
                    value: template.template_name };
                }));
              }
            });
        }));
        allTemplates.push(...response);
        if (internalDependency.internal_list && internalDependency.internal_list.length > 0) {
          properties = internalDependency.internal_list.map(internalList => {
            return { property_desc: internalList.value, property_value: internalList.key };
          });
          internalConfig.push({
            properties,
            _id: `${internalDependency.property_type}${internalDependency.property}`
          });
          return internalConfig;
        }
        if (internalDependency.internal.showAllPdfType && internalDependency.internal.showAllSmsType
          && internalDependency.internal.showAllHtmlType && internalDependency.internal.showAllEmailType) {
          internalConfig.push({
            allTemplates,
            _id: `${internalDependency.property_type}${internalDependency.property}`
          });
          return internalConfig;
        } else {
          if (internalDependency.internal.showAllPdfType) {
            propertiesList = allTemplates.filter(data => data.key.type === 'PDF');
          }
          if (internalDependency.internal.showAllSmsType) {
            propertiesList = allTemplates.filter(data => data.key.type === 'SMS');
          }
          if (internalDependency.internal.showAllHtmlType) {
            propertiesList = allTemplates.filter(data => data.key.type === 'HTML');
          }
          if (internalDependency.internal.showAllEmailType) {
            propertiesList = allTemplates.filter(data => data.key.type === 'EMAIL');
          }
          internalConfig.push({
            propertiesList,
            _id: `${internalDependency.property_type}${internalDependency.property}`
          });
          return internalConfig;
        }
      } else {
        return [];
      }
    }
  }

  addDependency(configs, dependency) {
    // Initialize dependency if not done earlier
    if (!configs) {
      configs = [];
    }
    // Check if the property already present in dependency configs
    // if yes, skip adding again
    let propertyFound = false;
    for (const config of configs) {
      if (config && config.property === dependency.property) {
        propertyFound = true;
      }
    }
    if (!propertyFound) {
      configs.push(dependency);
    }
  }

  lookupConfigs(lookupPageConfigs) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const lookupConfigsUpdated: any = [];
        // Filter out duplicates based on setupCategory and setupSubCategory
        const uniqueLookupConfigs = this.removeDuplicates(lookupPageConfigs, ['setupCategory', 'setupSubCategory']);
        // Update newLookupConfigComplete and newLookupConfigArr with unique entries
        this.newLookupConfigComplete = uniqueLookupConfigs;
        this.newLookupConfigArr = uniqueLookupConfigs.map((data) => ({
          setupCategory: data?.setupCategory,
          setupSubCategory: data?.setupSubCategory,
          version: data?.version,
        }));
        // Process unique entries
        const res = await this.newLookupConfig();
        console.log(res?.data?.lookupConfigsDetail?.data);
  
        if (res?.data?.lookupConfigsDetail?.data) {
          const lookupConfigsUpdated: any = [];
          lookupConfigsUpdated.push(res?.data?.lookupConfigsDetail?.data);
          resolve(lookupConfigsUpdated);
        } else {
          resolve([]);
        }
      } catch (error) {
        console.error("Error in newLookupConfig:", error);
        reject(error);
      }
    });
  }

  removeDuplicates(arr, keys) {
    const seen = new Set();
    return arr.filter((obj) => {
      const uniqueKey = keys.map((key) => obj[key]).join('-');
      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);
        return true;
      }
      return false;
    });
  }
  
  customFormBuilder(fieldsMap: Map<string, any[]>) {
    const groupMap = {};
    this.markControlDirtyMap = {};
    fieldsMap.forEach((fields, applicationId) => {
      const group = {};
      this.updatedDetailsModel = this.updatedDetailsMap.get(applicationId);
      this.model = this.applicantDetailsMap.get(applicationId);
      this.externalModel = this.externalDetailsMap.get(applicationId);
      if (!this.updatedDetailsModel) {
        this.updatedDetailsModel = new PersonalDetailsObject('', '', '', '', '', '', '');
      }
      if (!this.updatedDetailsModel.customFields) {
        this.updatedDetailsModel.customFields = {};
      }
      if (!this.externalModel) {
        this.externalModel = new PersonalDetailsObject('', '', '', '', '', '', '');
      }
      if (!this.externalModel.customFields) {
        this.externalModel.customFields = {};
      }
      if (!this.model.customFields) {
        this.model.customFields = {};
      }
      _.forEach(fields, field => {
        switch (field.category) {
          case 'USER': this.getUserTypeFormControl(field, group);
            break;
          case 'EXTERNAL': this.getExternalTypeFormControl(field, group);
            break;
          default: this.getCustomerTypeFormControl(field, group);
        }
      });
      groupMap[applicationId] = this._fb.group(group);
    });
    return this._fb.group(groupMap);
  }

  getCustomerTypeFormControl(field, group) {
    let fieldValue;
    if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
      fieldValue = this.model[field.groupKey];
      if (!group[field.groupKey]) {
        group[field.groupKey] = this.createTableForm(field, this.model);
      }
      return;
    }
    switch (field.field_identifier) {
      case 'C': fieldValue = this.model.customFields[field.key];
        break;
      case 'G': fieldValue = this.model[field.key];
        break;
      case 'CG': fieldValue = this.model[field.key];
        break;
      case 'PG': fieldValue = this.model[field.key];
        break;
      case 'P': fieldValue = this.model[field.key];
        break;
      case 'PI': fieldValue = this.model[field.key];
        break;
      default: return;
    }
    group[field.key] = CreateFormControl.getFormFieldControl(field, fieldValue, this.configs, this._fb);
    if ((fieldValue === '' || fieldValue === null || fieldValue === undefined) &&
      field.field_type_details.hasOwnProperty('defaultValue') && field.field_type_details.defaultValue) {
      this.markControlDirtyMap[field.key] = true;
    }
  }

  getExternalTypeFormControl(field, group) {
    let fieldValue;
    if (this.externalModel) {
      switch (field.field_identifier) {
        case 'C': if (this.externalModel.customFields) {
          fieldValue = this.externalModel.customFields[field.key] ||
            this.externalModel.customFields[`formatted_${field.key}`];
        }
          if (Array.isArray(fieldValue) && fieldValue.length == 0) {
            fieldValue = this.externalModel.customFields[`formatted_${field.key}`];
          }
          break;
        case 'G':

        case 'CG':
        case 'PG':
        case 'P':
        case 'PI': fieldValue = this.externalModel[field.key] ||
          this.externalModel[`formatted_${field.key}`];
          if (Array.isArray(fieldValue) && fieldValue.length == 0) {
            fieldValue = this.externalModel[`formatted_${field.key}`];;
          }
          break;
        default: return;
      }
    }

    group[field.key] = CreateFormControl.getFormFieldControl(field, fieldValue, this.configs, this._fb);
    if ((fieldValue === '' || fieldValue === null || fieldValue === undefined) &&
      field.field_type_details.hasOwnProperty('defaultValue') && field.field_type_details.defaultValue) {
      this.markControlDirtyMap[field.key] = true;
    }
  }

  getUserTypeFormControl(field, group) {
    let fieldValue;
    switch (field.field_identifier) {
      case 'C': fieldValue = this.updatedDetailsModel.customFields[field.key] ||
        this.model.customFields[field.key];
        break;
      case 'G': fieldValue = this.updatedDetailsModel[field.key] ||
        this.model[field.key];
        break;
      case 'CG': fieldValue = this.updatedDetailsModel[field.key] ||
        this.model[field.key];
        break;
      case 'PG': fieldValue = this.updatedDetailsModel[field.key] ||
        this.model[field.key];
        break;
      case 'P': fieldValue = this.updatedDetailsModel[field.key] ||
        this.model[field.key];
        break;
      case 'PI': fieldValue = this.updatedDetailsModel[field.key] ||
        this.model[field.key];
        break;
      default: return;
    }
    group[field.key] = CreateFormControl.getFormFieldControl(field, fieldValue, this.configs, this._fb);
    if ((fieldValue === '' || fieldValue === null || fieldValue === undefined) &&
      field.field_type_details.hasOwnProperty('defaultValue') && field.field_type_details.defaultValue) {
      this.markControlDirtyMap[field.key] = true;
    }
  }

  productFieldFormControl(field) {
    return CreateFormControl.getFormFieldControl(field, '', this.configs, this._fb);
  }

  public setConfigs(configs) {
    this.configs = configs;
  }

  isValid(field: any, form: UntypedFormGroup) {
    return form.controls[field.key].valid && form.controls[field.key].dirty;
  }

  isFieldValid(key: any, form: UntypedFormGroup) {
    return form.controls[key].valid;
  }
  isInValid(field: any, form: UntypedFormGroup) {
    return form && form.controls && form.controls[field.key] && form.controls[field.key].invalid && form.controls[field.key].dirty;
  }
  /**
   * @description - which helps to validate field
   */
  isFieldInValid(field: any, form: UntypedFormGroup) {
    return form.controls[field.key].invalid;
  }

  isFieldEnabled(key) {
    return this.fieldsConfig[key];
  }
  selectDropdown(value, field: any, form: UntypedFormGroup) {
    form.controls[field.key].setValue(value);
  }

  getProperties(field, application_id?: string) {
    const details = field.field_type_details;
    if (!application_id) {
      return this.configs.get(`${details.property_type}${details.property}`);
    }
    const configs = this.applicantConfigsMap.get(application_id);
    return this.configs.get(`${details.property_type}${details.property}`);

  }

  getPropertiesByKey(fieldKey, application_id?: string) {
    const field = this.fieldsConfig[fieldKey];
    return this.getProperties(field, application_id);
  }

  increment(field, form: UntypedFormGroup) {
    let max = field.field_type_details.max_value;
    max = parseInt(max);
    let value = form.controls[field.key].value;
    if (value < max) {
      form.controls[field.key].setValue(parseInt(value) + 1);
    }
  }

  decrement(field, form: UntypedFormGroup) {
    let min = field.field_type_details.min_value;
    min = parseInt(min);
    let value = form.controls[field.key].value;
    if (value > min) {
      form.controls[field.key].setValue(parseInt(value) - 1);
    }
  }

  getFieldValue(field, form: UntypedFormGroup) {
    return form.controls[field.key].value;
  }

  isFieldSectionDisabled(config) {

    if (!this.pageRulesConfig) {
      return false;
    }
    switch (config.field_identifier) {
      case 'C':
      case 'P':
      case 'H':
        if (this.pageRulesConfig[config.key] && this.pageRulesConfig[config.key].hidden) {
          return true;
        }
        if (this.pageRulesConfig[config.key] && this.pageRulesConfig[config.key].rulesEnabled) {
          let andConditions = this.pageRulesConfig[config.key].conditions['and'];
          let orConditions = this.pageRulesConfig[config.key].conditions['or'];
          if (!andConditions && !orConditions) {
            return false;
          }
          let rulesConfig = this.pageRulesConfig[config.key].rulesConfig;

          if (!this.checkAndConditionsMatch(andConditions, rulesConfig)) {
            return false;
          }
          if (andConditions.indexOf('isPropertyFinalized') > -1 &&
            this.form.controls['isPropertyFinalized'].value == 'No') {
            return true;
          }
          return this.checkOrConditionsMatch(orConditions, rulesConfig);
        }
        break;
      case 'G':
      case 'CG': if (this.pageRulesConfig[config.groupKey] && this.pageRulesConfig[config.groupKey].hidden) {
        return true;
      }
        if (this.pageRulesConfig[config.groupKey] && this.pageRulesConfig[config.groupKey].rulesEnabled) {
          let andConditions = this.pageRulesConfig[config.groupKey].conditions['and'];
          let orConditions = this.pageRulesConfig[config.groupKey].conditions['or'];
          if (!andConditions && !orConditions) {
            return false;
          }
          let rulesConfig = this.pageRulesConfig[config.groupKey].rulesConfig;
          if (!this.checkAndConditionsMatch(andConditions, rulesConfig)) {
            return false;
          }
          if (andConditions.indexOf('isPropertyFinalized') > -1 &&
            this.form.controls['isPropertyFinalized'].value == 'No') {
            return true;
          }
          return this.checkOrConditionsMatch(orConditions, rulesConfig);
        }
    }
    return false;

  }

  checkAndConditionsMatch(conditions, rulesConfig) {
    let isDisable = false;
    if (conditions && conditions.length > 0) {
      _.every(conditions, fieldKey => {
        let fieldValue = this.form.controls[fieldKey].value;
        if (!rulesConfig[fieldKey] ||
          !rulesConfig[fieldKey].matches[fieldValue] ||
          rulesConfig[fieldKey].matches[fieldValue].type.indexOf('and') < 0) {
          isDisable = true;
          return false;
        }
        return true;
      })
    }
    return isDisable;
  }

  checkOrConditionsMatch(conditions, rulesConfig) {
    let isDisable = false;
    if (conditions && conditions.length > 0) {
      _.every(conditions, fieldKey => {
        let fieldValue = this.form.controls[fieldKey].value;
        if (!rulesConfig[fieldKey] ||
          !rulesConfig[fieldKey].matches[fieldValue] ||
          rulesConfig[fieldKey].matches[fieldValue].type.indexOf('or') < 0) {
          isDisable = true;
          return false;
        }
        return true;
      })
    }
    else {
      isDisable = true;
    }
    return isDisable;
  }

  isFormValid() {
    let status = true;
    const applicationIds = Object.keys(this.form.controls);
    if (applicationIds.length > 0) {
      // tslint:disable-next-line:forin
      for (let id in applicationIds) { // check for each field key in all the primary aand co applicants
        let applicationId = applicationIds[id];
        this.form.controls[applicationId].updateValueAndValidity();
        // tslint:disable-next-line:forin
        for (let key in this.fieldsConfig) { // each field key
          this.makeFormDirty(this.form.controls[applicationId]);
          if (
            (this.form.controls[key] && this.form.controls[key].invalid) ||
            (this.form.controls[applicationId] && this.form.controls[applicationId].controls[key] && this.form.controls[applicationId].controls[key].invalid)) {
            let field = this.fieldsConfig[key];
            if (!this.isFieldSectionDisabled(field)) {
              this.form.controls[applicationId].controls[key].markAsDirty();
              status = false;
            }
          }
        }
      }
    }
    return status;
  }

  makeFormDirty(form) {
    if (form instanceof UntypedFormGroup) {
      Object.keys(form.controls).forEach((key: string) => {
        if (form.controls[key] instanceof UntypedFormGroup) {
          this.makeFormDirty(form.controls[key]);
        }
        if (form.controls[key] instanceof UntypedFormArray) {
          this.makeFormDirty(form.controls[key]);
        }
        if (form.controls[key] instanceof UntypedFormControl) {
          this.makeFormDirty(form.controls[key]);
        }
      });
    } else if (form instanceof UntypedFormArray) {
      form.controls.forEach(control => {
        if (control instanceof UntypedFormGroup) {
          this.makeFormDirty(control);
        }
        if (control instanceof UntypedFormControl) {
          this.makeFormDirty(control);
        }
        if (control instanceof UntypedFormArray) {
          this.makeFormDirty(control);
        }
      });
    } else if (form instanceof UntypedFormControl) {
      if (form.invalid || form.errors) {
        form.markAsDirty();
      }
    }
  }

  invalidMessageOnSubmit() {
    const applicationIds = Object.keys(this.form.controls);
    if (applicationIds.length > 0) {
      // tslint:disable-next-line:forin
      for (let key in applicationIds) {
        const applicationId = applicationIds[key];
        _.forEach(this.form.controls[applicationId].controls, function (control) {
          if (_.has(control.errors, 'required')) {
            control.markAsDirty();
          }
        });
      }
    }
  }

  zeroAdder(key) {
    let val = parseInt(key);
    if (val < 10) {
      return `0${key}`
    } else {
      return key
    }
  }

  formDisabled(form) {
    form.disable();
  }
  formEnabled(form) {
    form.enable();
  }
  formstatus(form) {
    return form.enabled;
  }

  async saveOrUpdate(options) {
    if (options.isDocUpload) {
      return this.saveOrUpdateDocs();
    }
    if (this.currentSubsection.type !== "APPLICATION_SUMMARY" && options.enableMandatoryValidations && !this.isFormValid()) {
      this.invalidMessageOnSubmit();
      // tslint:disable-next-line:no-string-throw
      throw 'Please fill all mandatory fields';
    }
    const applicationIds = Array.from(this.applicantDetailsMap.keys());
    const updateResponseList = [];
    for(let i = 0; i < applicationIds.length; i++) {
      // Skip application update, when the component is not dispalyed for the applicant
      const applicantType = this.applicantDetailsMap.get(applicationIds[i])['applicant'];
      // tslint:disable-next-line:max-line-length
      if (applicantType === AppConstants.PRIMARY_APPLICANT && !RoutesCore.isPrimaryApplicantEnabled(this.primaryModel, this.currentSectionId, this.currentSubSectionId)) {
        return;
      }
      // tslint:disable-next-line:max-line-length
      if (applicantType === AppConstants.SECONDARY_APPLICANT && RoutesCore.isSingleEnabled(this.primaryModel, this.currentSectionId, this.currentSubSectionId)) {
        return;
      }
      const customerData = {};
      const externalData = {};
      const updatedData = {};
      const applicantInfo = {};
      for (const key of Object.keys(this.fieldsConfig)) {
        const field = this.fieldsConfig[key];
        const form = this.form.controls[applicationIds[i]];
        if (SaveAppDataCore.isFieldVerificationPresent(field.key, this.model)) {
          customerData['verifications'] = this.model['verifications'];
        }
        if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
          if (!form.controls[field.groupKey] ||
            form.controls[field.groupKey].value == null ||
            form.controls[field.groupKey].value === undefined) {
            continue;
          }
        } else {
          if (!field ||
            !field.field_type_details ||
            !field.field_type_details.type ||
            field.field_type_details.type.includes('heading')) {
            continue;
          } else {
            if (!form.controls[key] ||
              form.controls[key].value == null ||
              form.controls[key].value === undefined) {
              continue;
            }
          }
        }
        switch (this.fieldsConfig[key]['category']) {
          case 'EXTERNAL': this.structureUpdateData(externalData, this.fieldsConfig[key], form);
            break;
          case 'USER': this.structureUpdateData(updatedData, this.fieldsConfig[key], form);
            break;
          default: if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
            this.structureUpdateData(customerData, field, form);
            if (!customerData[field.groupKey]) {
              this.structureUpdateData(customerData, field, form);
            }
          } else {
            this.structureUpdateData(customerData, this.fieldsConfig[key], form);
          }
        }
      }
      try {
        if (Object.keys(customerData).length > 0) {
          if (this.isGuarantorTriggred) {
            customerData['isGuarantorTriggred'] = true;
            this.isGuarantorTriggred = false;
          }
          let model = this.applicantDetailsMap.get(applicationIds[i]);
          if (model.filesUpload && this.model.filesUpload.length > 0) {
            const throughMailSubCategories = this.model.filesUpload.find(fileObj => fileObj.hasOwnProperty('collectionMode'));
            if (throughMailSubCategories) {
              customerData['filesUpload'] = this.model.filesUpload;
            }
          }
          applicantInfo['customerData'] = customerData;
          model = Object.assign(model, customerData);
        }
        if (Object.keys(externalData).length > 0) {
          applicantInfo['externalData'] = externalData;
          let externalModel = this.externalDetailsMap.get(applicationIds[i]);
          externalModel = Object.assign(externalModel, externalData);
        }
        if (Object.keys(updatedData).length > 0) {
          applicantInfo['updatedData'] = updatedData;
          let updatedDetailsModel = this.updatedDetailsMap.get(applicationIds[i]);
          updatedDetailsModel = Object.assign(updatedDetailsModel, updatedData);
        }
        applicantInfo['application_id'] = applicationIds[i];
        applicantInfo['auditStage'] = this.currentStageName;
        applicantInfo['auditSection'] = this.currentSectionName;
        const saveResponse = await this.save(applicantInfo);
        updateResponseList.push(saveResponse);
      } catch (error) {
        throw error;
      }
    }
    return updateResponseList;
  }

  /**
   * @author Vivek Sinha
   *
   * @param applicantInfo{
   *  application_id,
   *  customerData
   *  updatedData,
   *  externalData
   *  e.g. {
   *  application_id: 'test',
   *  customerData: {
   *    fname: 'test'
   *  },...
   *  }
   *
   * }
   */
  async save(applicantInfo) {
    return this.backstageService.saveApplicantDetails(applicantInfo).toPromise();
  }

  async saveOrUpdateDocs() {
    const updateResponseList = [];
    this.applicantDetailsMap.forEach(async (applicantDetails, applicationId) => {
      const form = this.filesUploadForm.controls[applicationId];
      const customerData = {
        filesUpload: form.controls['filesUpload'].value
      };
      applicantDetails['filesUpload'] = form.controls['filesUpload'].value;
      const applicantInfo = {};
      applicantInfo['application_id'] = applicationId;
      applicantInfo['customerData'] = customerData;
      try {
        const saveResponse = await this.backstageService.saveApplicantDetails(applicantInfo).toPromise();
        if (saveResponse.code !== '200') {
          console.log(saveResponse);
          throw saveResponse.message;
        }
        updateResponseList.push(saveResponse);
      } catch (error) {
        throw error;
      }
    });
    return updateResponseList;
  }

  structureUpdateData(updateInfo, field, form: UntypedFormGroup) {
    if (!updateInfo) {
      updateInfo = {};
    }
    if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
      updateInfo[field.groupKey] = form.controls[field.groupKey].value;
      return updateInfo;
    }
    switch (field.field_identifier) {
      case 'C': updateInfo[`customFields.${field.key}`] = form.controls[field.key].value;
        break;
      default: updateInfo[field.key] = form.controls[field.key].value;
        return updateInfo;
    }
  }


  isFormModified() {
    if (this.loadingChangeSection.length === 0 && !this.isEditDisabled()) {
      if (this.previousForm) {
        const previousFormValue = JSON.stringify(this.previousForm.value);
        const currentFormValue = JSON.stringify(this.form.value);
        if (this.form.dirty || previousFormValue !== currentFormValue) {
          return true;
        }
      }
    }
    return false;
  }

  resetForm() {
    if (this.loadingChangeSection.length === 0 && !this.isEditDisabled()) {
      if (this.previousForm) {
        this.form = this.previousForm;
      }
    }
  }

  isEditDisabled() {
    if (this.currentStage !== this.backstageService.currentTaskConfigs.stepInfo.stepNumber) {
      return true;
    }
    if (!this.accessControl || (this.accessControl && !this.accessControl.access) ||
      (this.accessControl && this.accessControl.access == 'WRITE')) {
      return false;
    } else {
      return true;
    }
  }

  generateFilesUploadForm() {
    const filesFormGroup = {};
    this.applicantDetailsMap.forEach((applicationDetails, key) => {
      if (!applicationDetails['filesUpload']) {
        applicationDetails['filesUpload'] = [];
      }
      filesFormGroup[key] = this._fb.group({
        'filesUpload': [applicationDetails['filesUpload']]
      });
    });
    this.filesUploadForm = this._fb.group(filesFormGroup);
  }


  getField(fieldKey: string) {
    return this.fieldsConfig[fieldKey];
  }

  updateFieldConfig(field: IField) {
    this.fieldsConfig[field.key || field.groupKey] = field;
  }

  updateProperties(propertyIdentifier: string, properties, application_id: string) {
    const configs = this.applicantConfigsMap.get(application_id);
    configs.set(propertyIdentifier, properties);
    this.configs.set(propertyIdentifier,properties); // update configs in this.configs
    this.applicantConfigsMap.set(application_id,configs); //update configs in applicantConfigs
  }

  setApplicationProgress(modelObj?, routeConfig?, returnObj?) {
    try {
      modelObj = modelObj || this.model;
      let components: IApplicationProgress[] = modelObj['progress'] && modelObj['progress'].map(ap => ap.component);
      // let invalidFormat = components.filter(c => c === undefined);
      // if (invalidFormat.length > 0) {
      //   throw 'Invalid Format';
      // }

      const applicationProgress = {};
      if (routeConfig) {
        routeConfig.forEach((section, index) => {
          const subSections = [];
          section.sub_sections.forEach(subSection => {
            if((modelObj.applicant === "primary" && !subSection.disableForPrimaryApplicant) ||
            (modelObj.applicant === "secondary" && !subSection.isSingle)) {
              subSections.push(subSection);
            }
          })
          const statusObj = {
            index: index + 1,
            id: section.sectionId,
            subSectionsId: 0,
            name: section.name,
            progress: 0,
            subSections: subSections.length,
            completedSubSections: 0,
            errors: 0,
            dependsOn: section.dependsOn,
            secondaryEnable: false,
            secondaryEnableSection: [],
            disableForPrimary: false,
            disableForPrimarySection: [],
            applicant: modelObj.applicant
          };

          if (modelObj.applicant !== AppConstants.PRIMARY_APPLICANT) {
            statusObj.subSections = section.sub_sections.filter(subSection => !subSection.isSingle && subSection).length || 0;
          }

          let completedSubSection = 0;
          section.sub_sections.forEach((subSection, sIdx) => {
            // const findProgress = modelObj['progress'] && modelObj['progress'].filter(function (item) {
            //   return item.hasOwnProperty(subSection.route_info);
            // })[0];
            let findProgress = [].concat.apply([], components).filter(c => c.componentId === subSection.componentId)[0];

            if (!subSection.isSingle) {
              statusObj.secondaryEnable = true;
            }
            statusObj.secondaryEnableSection[subSection.componentId] = !(subSection.isSingle) ?  true : false;

            if(!subSection.disableForPrimaryApplicant) {
              statusObj.disableForPrimary = true;
            }
            statusObj.disableForPrimarySection[subSection.componentId] = (subSection.disableForPrimaryApplicant) ?  true : false;

            if (findProgress) {
              switch (findProgress.status) {
                case this.appStatus.PROGRESS:
                  completedSubSection += 0.5;
                  statusObj.subSectionsId = sIdx;
                  break;
                case this.appStatus.COMPLETE:
                  completedSubSection++;
                  break;
              }
            }
          });
          statusObj.completedSubSections = completedSubSection;
          // statusObj.progress = (statusObj.subSections >0) ? Math.round(Number(completedSubSection) / Number(statusObj.subSections) * 100) : 0;
          statusObj.progress = (statusObj.subSections >0) ? Math.round(Number(completedSubSection) / Number(section.sub_sections.length) * 100) : 0;
          const setIndex = section.sectionId || section.section_id || statusObj.index;
          applicationProgress[setIndex] = statusObj;
        });
      }
      if (!returnObj) {
        this.applicationProgress = _.cloneDeep(applicationProgress);
      } else {
        return applicationProgress;
      }
    } catch (error) {
      throw error;
    }
  }

  getApplicationProgress() {
    return this.applicationProgress;
  }

  /**
   * Set Progress of section
   * @param status
   */
  setProgress(model, status) {
    const progressArray: IApplicationProgress[] = model['progress'] || [];
    const componentConfigs = model.routeConfig[model.section_id].sub_sections[model.sub_section_id];
    if (model.applicant !== 'primary' && componentConfigs.isSingle) {
      return progressArray;
    }
    let components = [];
    const allComponents = model.routeConfig[model.section_id].sub_sections;
    allComponents.forEach(comp => {
      if((model.applicant === 'primary' && !comp.disableForPrimaryApplicant) ||
      (model.applicant === 'secondary' && !comp.isSingle)) {
        components.push(comp);
      }
    });
    const progressArrayBySection = progressArray.filter(pa => model.routeConfig[model.section_id].sectionId === pa.sectionId);
    if (progressArrayBySection.length > 0) {
      let findProgress;
      progressArrayBySection.map(function (item) {
        findProgress= item.component.filter(co => {
          return co.componentRouteInfo === componentConfigs.route_info
        })[0];
      });
      if (findProgress) {
        findProgress.status = status;
      } else {
        progressArrayBySection[0].component.push({
          componentId: componentConfigs.componentId,
          componentRouteInfo: componentConfigs.route_info,
          status: status
        });
      }
      const overallStatus = components.length === progressArrayBySection[0].component.length && progressArrayBySection[0].component.find(c=> c.status !== this.appStatus.COMPLETE);
      progressArrayBySection[0].status = (components.length === progressArrayBySection[0].component.length && overallStatus) ? this.appStatus.PROGRESS : this.appStatus.COMPLETE;
    } else {
      let progress = {
        sectionId: model.routeConfig[model.section_id].sectionId,
        status: (components.length === 1 && status === this.appStatus.COMPLETE) ? this.appStatus.COMPLETE : this.appStatus.PROGRESS,
        component:[]
      };
      components.forEach((co, ix) => {
        if (componentConfigs.componentId === co.componentId) {
          progress.component.push({
            componentId: co.componentId,
            componentRouteInfo: co.route_info,
            status: (model.sub_section_id === ix) ? status : this.appStatus.PENDING
          });
        }
      });
      progressArray.push(progress);
    }
    return progressArray;
  }

  newLookupConfig(){
    try {
      const variables = {
        count:false,
        "configs":this.newLookupConfigArr,
        "status":["Published"]
      };
      return this._apollo.use('lookupAPIClient').query<any>({
        query:gql`
        query ($status: [String], $lookupId: String, $version: String,$configs:[JSONObject]) {\r\n  lookupConfigsDetail(status: $status, lookupId: $lookupId, version: $version,configs:$configs) {\r\n    data {\r\n      setupCategory\r\n      setupSubCategory\r\n      setupDescription\r\n      lookupId\r\n      persona\r\n      status\r\n      modifiedTS\r\n      version\r\n      fields\r\n    }\r\n    count\r\n  }\r\n}`,
        variables:variables,
        fetchPolicy:'no-cache'
      }).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

}
