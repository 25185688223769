import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as configs } from 'environments/environment';
import { UserService } from '@vanguard/shared/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowConfigs } from '../models/flow-configs';
import { TranslateService } from '@ngx-translate/core';
import { CommonhttpService } from './commonhttp.service';
import { HttpClient } from '@angular/common/http';
import { ALM_MODES } from '@vanguard/core/models/alm-modes';
import { ApplicationTasksConfigs } from '@vanguard/shared/interfaces/applicationTaskConfigs.interface';


@Injectable()
export class BackstageServiceComponent {
  // @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  baseURL: string = configs.clientUrl;
  testURL: string = configs.testUrl;
  adminURL: string = configs.adminUrl;

  primaryApplicantID = "";
  currentSubStatus = ""; // Not used currently, need to remove
  currentTaskConfigs: ApplicationTasksConfigs;
  flowId = 'f56faea0-3107-11e9-ac30-a72fb5bf0990';
  verNum = 243;
  public tableConfig = new BehaviorSubject<any>([]);
  public currentConfig = this.tableConfig.asObservable();
  public userGroupsLoggedIn: any = {
    userId: "",
    userGroups: "",
    activities: []
  };
  public saveType: any = 'primary'; // for all applicants use string as 'all' else 'primary';
  public overrideSave = false;
  public overridePrimaryDataToSave: any;
  public isApplicationAssigned: Boolean = true;
  public materialPopupData: any = null;
  public viewMode: Boolean = false;
  public showMaterialPopup: boolean = false;
  public selectedRadioBtnData: any = null;
  /* strcture for primary applicant is
   {
          "application_id":"DB2018100117026",
          "customerData":{"fname":"VS"}, //for customer data
          "updatedData":{"fname":"VS"}, //for user data
          "externalData":{"fname":"VS"} //for external data
   }
   */
  public overrideJointDataToSave: any = [];
  public loaderShowing = false;
  /* strcture for other applicants is array of below structure
   [{
          "application_id":"DB2018100117028",
          "customerData":{"fname":"VS"}, //for customer data
          "updatedData":{"fname":"VS"}, //for user data
          "externalData":{"fname":"VS"} //for external data
   },
  {
          "application_id":"DB2018100117027",
          "customerData":{"fname":"VS"}, //for customer data
          "updatedData":{"fname":"VS"}, //for user data
          "externalData":{"fname":"VS"} //for external data
   }]
   */
  public isBackClicked = false;
  public validateSection = new EventEmitter();
  public saveValidation = new EventEmitter();
  public openSaveModal = new EventEmitter();
  private actionsConfig;
  public previewMode = false;
  public loading = false;

  public isFilterEnabled= false;
  public filterApplicationDetail: any = {
    productCode: "",
    applicationId: "",
    stepName: "",
    selectedQueryType: "", // Assigned/Queued/followed
    listOfProductStages: [],
    url:""
  };
  public filterNotificationApplication = new BehaviorSubject<any>([]);
  filterNotificationApplication$ = this.filterNotificationApplication.asObservable();

  constructor( private http: HttpClient, public router: Router,
    private userService: UserService, private commonApiService: CommonhttpService, public translate: TranslateService, private spinnerService: NgxSpinnerService) {
  }

  public goToApplication() {
    this.filterNotificationApplication.next({filterData:this.filterApplicationDetail, fromObservable: true});
  }// public goToApplication()

  public setBackClicked(status) {
    this.isBackClicked = status;
  }// public setBackClicked(status)

  public getBackClicked() {
    return this.isBackClicked;
  }// public getBackClicked()

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }// private handleError(error: HttpErrorResponse)

  public getTableConfig(tableName: any) {
    return this.commonApiService.get(`${this.baseURL}/backstage/getTableConfig/${tableName}`)
      .pipe(map(response => response));
  }// public getTableConfig(tableName: any)

  public getApplications(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getApplications`, body)
      .pipe(map(response => response));
  }// public getApplications(config: any)

  public getFieldsConfig(configDetails: any) {
    const currentlang = this.translate.currentLang;
    if (configDetails.length > 0) {
      configDetails.map(val => {
        val.language = currentlang;
        return val;
      });
    }
    const body = JSON.stringify(configDetails);
    return this.commonApiService.post(`${this.adminURL}/config/get_fields_config`, body)
      .pipe(map(response => response));
  }// public getFieldsConfig(configDetails: any)

  public getFilters(filterType: any, filterUser: any) {
    const body = JSON.stringify({ filterType: filterType, filterUser: filterUser });
    return this.commonApiService.post(`${this.baseURL}/backstage/getFilter`, body)
      .pipe(map(response => response));
  }// public getFilters(filterType: any, filterUser: any)

  public getFlowConfig(configDetails, mode, deviceWidth?: number) {
    return this.commonApiService.get(`${this.adminURL}/config/flow_config_by_id/${configDetails.verNum}/${configDetails.flowId}?mode=${mode}${deviceWidth ? `&device_width=${deviceWidth}` : ''}`)
      .pipe(map(response => response));
  }// public getFlowConfig(configDetails)

  public getFlowConfigs(configDetails: FlowConfigs, mode?: string, deviceWidth?: number) {
    mode = mode || ALM_MODES.SAVED;
    // tslint:disable-next-line: max-line-length
    return this.commonApiService.get(`${this.adminURL}/config/flow_configs?flow_ids=${configDetails.flow_ids}&ver_num=${configDetails.verNum}&flow_type=${configDetails.flow_type}&mode=${mode}${deviceWidth ? `&device_width=${deviceWidth}` : ''}`)
      .pipe(map(response => response));
  }// public getFlowConfig(configDetails)

  public getFlowRules(configDetails) {
    return this.commonApiService.get(`${this.adminURL}/v3/flow/fetch_rules?flow_ids=${configDetails.flowId || configDetails.flow_ids}&ver_num=${configDetails.verNum}`)
      .pipe(map(response => response));
  }// public getFlowConfig(configDetails)

  public getProductList() {
    // return this.commonApiService.get(`${this.adminURL}/v3/lead/getAllProducts`)
    //   .pipe(map(response => response));
    return of({});
  }// public getProductList()

  public getAllTemplatesAllVersionsByTypeV3(temp_type: any) {
    // const body = JSON.stringify({ templateTypesArray: temp_type });
    // return this.commonApiService.post(`${this.adminURL}/v3/template/templateListAllVersion`, body)
    //   .pipe(map(response => response));
    return of({});
  }// public getALLTypesOfTemplatesList()

  public captureUserAction(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/captureAction`, body)
      .pipe(map(response => response));
  }// public captureUserAction(actionDetails)

  public getAllUsers() {
    return this.commonApiService.get(`${this.baseURL}/v3/users`)
      .pipe(map(response => response));
  }// public captureUserAction(actionDetails)

  public rejectApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/reject`, body)
      .pipe(map(response => response));
  }// public rejectApplication(actionDetails)

  public cancelRejectApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/cancelReject`, body)
      .pipe(map(response => response));
  }// public rejectApplication(actionDetails)

  public cancelApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/cancel`, body)
      .pipe(map(response => response));
  }// public cancelApplication(actionDetails)

  public inactivateApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/inactivate`, body)
      .pipe(map(response => response));
  }// public inactivateApplication(actionDetails)

  public activateApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/activate`, body)
      .pipe(map(response => response));
  }// public activateApplication(actionDetails)

  public revertStage(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/revert_stage`, body)
      .pipe(map(response => response));
  }// public revertStage(actionDetails)

  public releaseApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/release`, body)
      .pipe(map(response => response));
  }// public releaseApplication(actionDetails)

  public deleteApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/delete`, body)
      .pipe(map(response => response));
  }// public deleteApplication(actionDetails)

  public requestForCallback(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/request_for_callback`, body)
      .pipe(map(response => response));
  }// public requestForCallback(actionDetails)

  public completeCallback(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/complete_callback`, body)
      .pipe(map(response => response));
  }// public completeCallback(actionDetails)

  public assignApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/assign`, body)
      .pipe(map(response => response));
  }// public assignApplication(actionDetails)

  public reassignApplication(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/reassign`, body)
      .pipe(map(response => response));
  }// public assignApplication(actionDetails)

  public completeUserStep(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/action/complete_step/user`, body)
      .pipe(map(response => response));
  }// public completeUserStep(actionDetails)


  public approveApproval(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/approvals/approve`, body)
      .pipe(map(response => response));
  }

  public rejectApproval(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/approvals/reject`, body)
      .pipe(map(response => response));
  }

  public forwardApproval(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/approvals/forward`, body)
      .pipe(map(response => response));
  }

  public sendBackApproval(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/approvals/send_back`, body)
      .pipe(map(response => response));
  }

  public raiseQuery(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/queries/requestUserInfo`, body)
      .pipe(map(response => response));
  }

  public requestForInfo(actionDetails) {
    const body = JSON.stringify(actionDetails);
    return this.commonApiService.post(`${this.baseURL}/v3/application/queries/requestInfo`, body)
      .pipe(map(response => response));
  }


  public changeConfig(configuration) {
    this.tableConfig.next(configuration);
  }// public changeConfig(configuration)

  public idVerification(requestInfo) {
    console.log('request info', requestInfo);
    const body = JSON.stringify(requestInfo.body);
    return this.commonApiService.post(`${this.baseURL}${requestInfo.url}`, body)
      .pipe(map(response => response));
  }// public idVerification(requestInfo)

  public getVerificationFieldConfigs(apiDetails) {
    const body = JSON.stringify(apiDetails);
    return this.commonApiService.post(`${this.baseURL}/externalAPI/configs`, body)
      .pipe(map(response => response));
  }// public getVerificationFieldConfigs(apiDetails)

  public getMailTemplates(template_name) {
    return this.commonApiService.get(`${this.baseURL}/backstage/getMailTemplate/${template_name}`)
      .pipe(map(response => response));
  }// public getMailTemplates(template_name)

  public getProduct() {
    return this.commonApiService.get(`${this.baseURL}/backstage/Products`)
      .pipe(map(response => response));
  }// public getProduct()

  public sendMailCommon(mailDetails) {
    const body = JSON.stringify(mailDetails);
    return this.commonApiService.post(`${this.baseURL}/backstage/sendMailCommon`, body)
      .pipe(map(response => response));
  }// public sendMailCommon(mailDetails)

  public notification(tosterType: any, tosterMsg: any) {
    const _type = {
      success: "toast success",
      warning: "toast warning",
      error: "toast error"
    };
    // this.toastrService.show(tosterMsg, null, {
    //     tapToDismiss: true,
    //     toastClass: _type[tosterType]
    // });
  }

  public saveApplicantDetails(applicantInfo) {
    applicantInfo['userName'] = this.userService.userName;
    const body = JSON.stringify(applicantInfo);
    return this.commonApiService.post(`${this.baseURL}/v3/application/user/save_or_update`, body)
      .pipe(map(response => response));
  }

  public updateQueriesSchema(applicantInfo) {
    applicantInfo['userName'] = this.userService.userName;
    const body = JSON.stringify(applicantInfo);
    return this.commonApiService.post(`${this.baseURL}/v3/queries/updateAndResolveQuery`, body)
      .pipe(map(response => response));
  }

  public getDeviationDetails(details) {
    const body = JSON.stringify(details);
    return this.commonApiService.post(`${this.testURL}/chola/hfc/deviationapi`, body)
      .pipe(map(response => response));
  }

  public spinnerLoad(_type: any) {
    if (_type === 'show') {
      this.spinnerService.show();
    } else {
      this.spinnerService.hide();
    }
  }

  public showSpinner() {
    this.spinnerService.show();
  }

  public hideSpinner() {
    this.spinnerService.hide();
  }

  public calculations(details) {
    const body = JSON.stringify(details);
    return this.commonApiService.post(`${this.baseURL}/calculations/calculate`, body)
      .pipe(map(response => response));
  }

  public getAppnNew(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getApplicationsNew`, body)
      .pipe(map(response => response));
  }

  public getAppnFollowed(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getApplicationsFollowed`, body)
      .pipe(map(response => response));
  }

  public getFields(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/v3/queries/getFields`, body)
      .pipe(map(response => response));
  }

  public createOrUpdateQuery(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/queries/createOrUpdateQuery`, body)
      .pipe(map(response => response));
  }// public createOrUpdateQuery(data: any)

  public updateQuery(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/queries/updateQuery`, body)
      .pipe(map(response => response));
  }

  public fetchFieldsForReqInfo(data: any) {
    return this.commonApiService.get(`${this.baseURL}/v3/queries/fetchFieldsForReqInfo/${data.requestForInfoId}`)
      .pipe(map(response => response));
  }

  public sendEmailForRequestForInfo(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/queries/sendRequestForInfoNotifications`, body)
      .pipe(map(response => response));
  }

  public markDocumentAsDeleted(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/deleteDocument`, body)
      .pipe(map(response => response));
  }

  public updateDocumentMetada(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/document/updatemetadata`, body)
      .pipe(map(response => response));
  }

  public updateDocument(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/updateDocument`, body)
      .pipe(map(resp => resp));
  }

  public documentCoping(data: any){
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/copyFile`, body)
    .pipe(map(response => response));
  }
  
  public mergeDocument(data: any) {
    const body = JSON.stringify(data);
    return this.commonApiService.post(`${this.baseURL}/v3/mergeDocument`, body)
      .pipe(map(resp => resp));
  }

  public getQueries(data: any) {
    return this.commonApiService.get(`${this.baseURL}/v3/queries/${data.application_id}`)
      .pipe(map((response: any) => response));
  }// public getQueries(data: any)

  public getNsmApplications() {
    const body = JSON.stringify({});
    return this.commonApiService.post(`${this.baseURL}/backstage/getNsmApplications`, body)
      .pipe(map(response => response));
  }// public getNsmApplications()

  public getNsmZoneGroup() {
    const body = JSON.stringify({});
    return this.commonApiService.post(`${this.baseURL}/backstage/getNsmZoneGroup`, body)
      .pipe(map(response => response));
  }// public getNsmZoneGroup()

  public getReporteeDetails(details: any) {
    const body = JSON.stringify(details);
    return this.commonApiService.post(`${this.baseURL}/backstage/getReporteeDetails`, body)
      .pipe(map(response => response));
  }// public getReporteeDetails(details: any)

  public getAppnDeclined(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getApplicationsDeclined`, body)
      .pipe(map(response => response));
  }// public getAppnDeclined(config: any)

  public rebook(appDetails: any) {
    const body = JSON.stringify(appDetails);
    return this.commonApiService.post(`${this.baseURL}/backstage/rebook`, body)
      .pipe(map(response => response));
  }// public rebook(appDetails: any)

  public getfilteredNsm(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getfilteredNsm`, body)
      .pipe(map(response => response));
  }// public getfilteredNsm(config: any)


  public getOveallTarget() {
    return this.commonApiService.get(`${this.baseURL}/target/getOveallTarget`)
      .pipe(map(response => response));
  }// public getOveallTarget()

  public getTarget(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/target/getTarget`, body)
      .pipe(map(response => response));
  }// public getTarget(config: any)

  public globalSearch(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/globalSearch`, body)
      .pipe(map(response => response));
  }// public globalSearch(config: any)

  public getGeoLimitStatus(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getGeoLimitStatus`, body)
      .pipe(map(response => response));
  }// public getGeoLimitStatus(config: any)

  public getCollectionDetails(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/collectionConfig/cardTypes`, body)
      .pipe(map(response => response));
  }// public getCollectionDetails(config: any)

  public removeDocument(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/upload/removeDocument`, body)
      .pipe(map(response => response));
  }// public removeDocument(config: any)


  public getPercentageCompletionDeviationDetails(config: any) {
    console.log('config', config);
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.adminURL}/workFlowExecution/captureAction`, body)
      .pipe(map(response => response));
  }// public getPercentageCompletionDeviationDetails(config: any)

  public loanCreation(config: any) {
    console.log('config', config);
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/chola/hfc/loanCreationAPI`, body)
      .pipe(map(response => response));
  }// public loanCreation(config: any)


  public disbursementTranche(config: any) {
    console.log('config', config);
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/chola/hfc/disbursementTranche`, body)
      .pipe(map(response => response));
  }// public disbursementTranche(config: any)

  public achMandate(config: any) {
    console.log('config', config);
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/chola/hfc/achMandateResponse`, body)
      .pipe(map(response => response));
  }// public achMandate(config: any)

  public getTargetByUserID(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/target/getTargetByUserID`, body)
      .pipe(map(response => response));
  }// public getTargetByUserID(config: any)


  public getCibilObligation(details: any) {
    const body = JSON.stringify(details);
    return this.commonApiService.post(`${this.baseURL}/cibil/obligation`, body)
      .pipe(map(response => response));
  }// public getCibilObligation(details: any)


  public getLoanAmountByUserID(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/target/getLoanAmountByUserID`, body)
      .pipe(map(response => response));
  }// public getLoanAmountByUserID(config: any)


  public getApplicationByInitiatedByAndProductType(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/target/getApplicationByInitiatedByAndProductType`, body)
      .pipe(map(response => response));
  }// public getApplicationByInitiatedByAndProductType(config: any)


  public getApplicationByInitiatedByAndFlowName(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/target/getApplicationCountByInitiatedByAndFlowName`, body)
      .pipe(map(response => response));
  }// public getApplicationByInitiatedByAndFlowName(config: any)


  public getQueriesUnresolvedCountByUserId(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/queries/getQueriesUnresolvedCountByUserId`, body)
      .pipe(map(response => response));
  }// public  getQueriesUnresolvedCountByUserId(config: any)

  public getApplicationFiles(application_id: string) {
    return this.commonApiService.get(`${this.baseURL}/v3/application/${application_id}`)
      .pipe(map(response => response));
  }

  public isActivityAvailable(activityName) {
    const activites = this.userGroupsLoggedIn.activities;
    const activityIndex = activites.indexOf(activityName);
    if (activityIndex === -1) {
      return false;
    } else {
      return true;
    }
    // return true;
  }// public isActivityAvailable(activityName)


  public localSearch(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/localSearch`, body)
      .pipe(map(response => response));
  }// public localSearch(config: any)


  public getApplicationsCountBasedOnRange(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getApplicationsCountBasedOnRange`, body)
      .pipe(map(response => response));
  }// public getApplicationsCountBasedOnRange(config: any)


  public logout(config) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/user/logout`, body)
      .pipe(map(response => response));
  }// public logout(config)

  public getApplicationsByInitiatedByAndProductType(config: any) {
    const body = JSON.stringify(config);
    return this.commonApiService.post(`${this.baseURL}/backstage/getApplicationsByInitiatedByAndProductType`, body)
      .pipe(map(response => response));
  }// public getApplicationsByInitiatedByAndProductType(config: any)

  public downloadFile(filePath): Observable<Blob> {
    const backend_url = configs.clientUrl;
    const additionalHeaders = { 'responseType': 'ArrayBuffer' };
    return this.commonApiService.get(`${backend_url}/${filePath}`, null, additionalHeaders)
      .pipe(map((response: any) => response.blob()));
  }// public downloadFile(filePath): Observable<Blob>

  public genertaeHTMLTemplate(templateDetails) {
    const body = JSON.stringify({
      "MainApplicant": templateDetails.mainApplicant,
      "Applicant1": templateDetails.applicant1 || 'NA',
      "Applicant2": templateDetails.applicant2 || 'NA',
      "Applicant3": templateDetails.applicant3 || 'NA',
      "Guarantor": templateDetails.applicant3 || 'NA',
      "template_name": templateDetails.templateName,
      "template_type": templateDetails.type,
      "product_type": templateDetails.productType
    });
    return this.commonApiService.post(`${this.baseURL}/pdfgenerate/createTemplate`, body)
      .pipe(map(response => response));
  }// public genertaeHTMLTemplate(templateDetails)

  public generatePDF(templateDetails) {
    const body = JSON.stringify({
      upload_data: {
        template_data: templateDetails.htmlContent, // template to be generated
        version: templateDetails.version, // any version no or 0(zero)
        category: templateDetails.category, // doc category
        application_id: templateDetails.application_id,
        uploadedby: templateDetails.userName,
        refno: 0,
        subcategory: "PDF_GENERATION",
        categoryType: "NA"
      }
    });
    return this.commonApiService.post(`${this.baseURL}/pdfgenerate/generatePDF`, body)
      .pipe(map((response) => response));
  }// public generatePDF(templateDetails)

  /**
   * Added Method to enumerate users from the keyclock for approvals
   * Right now connecting to the (Product manger backend url) `https://van-admin-backend.herokuapp.com/keycloak/users`
   * Please provide similar endpoint in the backstage backend
   */
  public enumerateUsers(): Observable<any> {
    return this.commonApiService.get(`${this.baseURL}/v3/users`)
      .pipe(map((response) => response));
  }// public enumerateUsers(): Observable<any>

  public setActionConfigs(actionsConfig) {
    this.actionsConfig = actionsConfig;
  }

  public getActionConfig() {
    return this.actionsConfig;
  }

  // Make the customer status as true/false based on the events (It is for VKYC 2.0)
  setCustomerStatus(online, applicationId) {
    try {
      return this.commonApiService
        .put(`${configs.clientUrl}/v4/vkyc/consumer-status/${applicationId}`, {
          onlineStatus: online,
        })
        .toPromise();
    } catch (error) {
      console.log(error);
    }
  }
}

