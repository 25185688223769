import { BackendService } from '@vanguard/shared/services/backend.service';
import { Injectable } from "@angular/core";
import { AppConstants } from '@vanguard/config';

declare var ga: any;

@Injectable()
export class GoogleAnalyticsEventsService {

  public enableGoogleAnalyticsService = true;
  public analyticsConfigs;
  public trackingDetails;

  constructor(private backendService: BackendService) {
  }


  private emitEvent(eventCategory: string,
    eventLabel: string = null,
    eventAction: string,
    eventValue: number = null) {
    try {
      ga('send', 'event', { eventCategory, eventLabel, eventAction, eventValue });
    } catch (e) {}
  }

  public visitedPageEvent() {
    if (!this.enableGoogleAnalyticsService) {
      return;
    }
    const eventAction = this.analyticsConfigs?.pageVisitedAction || 'Page visited';
    const { eventCategory, eventLabel, eventValue } = this.frameEventDetails();

    // Emit visited event
    this.emitEvent(eventCategory, eventLabel, eventAction, eventValue);
  }

  public completedPageEvent() {
    if (!this.enableGoogleAnalyticsService) {
      return;
    }
    const eventAction = this.analyticsConfigs?.pageCompletedAction || 'Page completed';
    const { eventCategory, eventLabel, eventValue } = this.frameEventDetails();

    // Emit visited event
    this.emitEvent(eventCategory, eventLabel, eventAction, eventValue);
  }

  public completedApplicationEvent() {
    if (!this.enableGoogleAnalyticsService) {
      return;
    }
    const primaryModel = this.backendService.getPersonalDetailsObject();
    const eventCategory = primaryModel.product_code;
    const eventLabel = primaryModel.flowName;
    const eventAction = this.analyticsConfigs?.applnCompletedAction || 'Application completed';

    // Emit application completed event
    this.emitEvent(eventCategory, eventLabel, eventAction, 1);
  }

  public initiatedApplicationEvent() {
    if (!this.enableGoogleAnalyticsService) {
      return;
    }
    const primaryModel = this.backendService.getPersonalDetailsObject();
    const eventCategory = primaryModel.product_code;
    const eventLabel = primaryModel.flowName;
    const eventAction = this.analyticsConfigs?.applnInitiatedAction || 'Application initiated';

    // Emit application completed event
    this.emitEvent(eventCategory, eventLabel, eventAction, 1);
  }

  private frameEventDetails() {
    const primaryModel = this.backendService.getPersonalDetailsObject();
    const eventCategory = primaryModel.product_code;
    const eventLabel = this.formatPageName(primaryModel);
    let eventValue = 0;
    if (this.backendService.currentApplicant.applicantType === AppConstants.PRIMARY_APPLICANT) {
      eventValue = 1;
    }
    return { eventCategory, eventLabel, eventValue };
  }

  public formatPageName(primaryModel) {
    const routeConfig = primaryModel.routeConfig;
    const currentSectionId = primaryModel.section_id;
    const currentSubSectionId = primaryModel.sub_section_id;
    let pageName = primaryModel.routeConfig[currentSectionId].sub_sections[currentSubSectionId].display_name;

    if (!this.analyticsConfigs?.pagePrefix) {
      return pageName;
    }
    let pageNumber = 0;
    routeConfig.forEach((section, sectionId) => {
      section.sub_sections.forEach((subSection, subSectionId) => {
        pageNumber = pageNumber + 1;
        if ((sectionId === currentSectionId) && (subSectionId === currentSubSectionId)) {
          pageName = `${this.analyticsConfigs?.pagePrefix} ${pageNumber}-${pageName}`;
        }
      });
    });

    return pageName;
  }

  public initializeGoogleAnalyticsTracking() {
    if (!this.enableGoogleAnalyticsService) {
      return;
    }
    if (this.analyticsConfigs && this.analyticsConfigs.length > 0) {
      if (this.analyticsConfigs[0].enableAnalytics) {
        if (this.trackingDetails && this.trackingDetails.length > 0) {
          let createAction = '';
          let sendAction = '';
          this.trackingDetails.forEach((info) => {
            if (info.appName) {
              createAction = createAction.concat(`ga('create', '${info.trackingID}', 'auto', { 'name': '${info.appName}' });`);
              sendAction = sendAction.concat(`ga('${info.appName}.send', 'pageview');`);
            } else {
              createAction = createAction.concat(`ga('create', '${info.trackingID}', 'auto');`);
            }
          });
          sendAction = sendAction.concat(`ga('send', 'pageview');`);
          try {
            const script = document.createElement('script');
            script.innerHTML = `
            (function (i, s, o, g, r, a, m) {
              i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
              }, i[r].l = 1 * new Date(); a = s.createElement(o),
                m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
            ${createAction}
            ${sendAction}
            `;
            document.head.appendChild(script);
            this.enableGoogleAnalyticsService = true;
          } catch (ex) {
            console.error('Error appending google analytics', ex);
            this.enableGoogleAnalyticsService = false;
          }
        }
      }
    }
  }
}
